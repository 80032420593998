import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Tooltip from '@mui/material/Tooltip'
import ButtonSubmit from 'components/ButtonSubmit'
import { ErrorForm } from 'components/FormTalao/ErrorForm'
import ModalMessage from 'components/Modais/ModalMessage'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { FaFilePdf, FaFileCsv, FaFileExcel, FaFileArchive } from 'react-icons/fa'
import './styles.css'
import { MdOutlineLocalPostOffice, MdOutlineFilterList } from 'react-icons/md'
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'

import OverlayLoading from '../../../components/OverlayLoading'
import { LinesPerPages } from '../../../components/Pages/LinesPerPage'
import { Pagination } from '../../../components/Pages/Pagination'
import SideBar from '../../../components/SideBarLateral'
import { CondicionaisFormContext } from '../../../contexts/CondicionaisFormContext'
import { NotificacaoContext } from '../../../contexts/NotificacaoContext'
import { AtualizaStatusLote, DownloadLoteInfracao } from '../../../services/notificacaoService'

function ControleDeTalao() {
  const { classSideNav } = useContext(CondicionaisFormContext)
  const { lotesGerados, atualizaLotesGerados, tiposRelatorio } = useContext(NotificacaoContext)

  const [valueTipoNotificacao, setValueTipoNotificacao] = useState('Autuação')
  const [filterStatusNotificacao, setFilterStatusNotificacao] = useState('Status do lote')
  const [messageLotePostado, setMessageLotePostado] = useState('')
  const [dataPostagem, setDataPostagem] = useState('')
  const [codigoCorreios, setCodigoCorreios] = useState('')
  const [messageFalhaLotePostado, setMessageFalhaLotePostado] = useState('')

  const [valueTipoRelatorio, setValueTipoRelatorio] = useState(1)

  const [isIconOrdenaNumero, setIsIconOrdenaNumero] = useState(true)
  const [isIconOrdenaData, setIsIconOrdenaData] = useState(true)
  const [isIconOrdenaDataLimite, setIsIconOrdenaDataLimite] = useState(true)
  const [isSuccessLoteBaixado, setIsSuccessLoteBaixado] = useState(false)
  const [isSuccessLotePostado, setIsSuccessLotePostado] = useState(false)
  const [isFalhaLoteBaixado, setIsFalhaLoteBaixado] = useState(false)
  const [IsFalhaLotePostado, setIsFalhaLotePostado] = useState(false)
  const [isLoadingDownloadLote, setIsLoadingDownloadLote] = useState(false)

  const [showIcon, setShowIcon] = useState({})
  const [showDetailsInfracao, setShowDetailsInfracao] = useState({})
  const [filterInfracoes, setFilterInfracoes] = useState([])
  const [infosPostagemActive, setInfosPostagemActive] = useState({})

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentInfracoes = filterInfracoes.slice(startIndex, endIndex)

  const [requiredDataPostagem, setRequiredDataPostagem] = useState(false)
  const [requiredCodigoCorreios, setRequiredCodigoCorreios] = useState(false)

  useEffect(() => {
    setCurrentPage(1)
  }, [filterStatusNotificacao, setFilterInfracoes, valueTipoNotificacao])

  useEffect(() => {
    if (filterStatusNotificacao === 'Todos') {
      setFilterInfracoes(lotesGerados)
    } else if (filterStatusNotificacao === 'Postados') {
      const lotesFiltrados = lotesGerados.filter((value) => value.tipoStatusLote.descricao === 'Postado')
      setFilterInfracoes(lotesFiltrados)
    } else if (filterStatusNotificacao === 'Não postados') {
      const lotesFiltrados = lotesGerados.filter((value) => value.tipoStatusLote.descricao === 'Gerado')
      setFilterInfracoes(lotesFiltrados)
    }
  }, [filterStatusNotificacao, lotesGerados])

  useEffect(() => {
    if (valueTipoNotificacao === 'Autuação') {
      const lotesFiltrados = lotesGerados.filter((value) => value.tipoNotificacao.descricao === 'Autuação')
      setFilterInfracoes(lotesFiltrados)
    }
  }, [valueTipoNotificacao, lotesGerados])

  const mostraIcon = (valor: number) => () => {
    setShowIcon((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const mostraDetalhesInfracao = (valor: number) => () => {
    setShowDetailsInfracao((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const statusInfracoes = [
    {
      id: 1,
      status: 'Gerada'
    },
    {
      id: 2,
      status: 'Postada'
    }
  ]

  function ativaInfosPostagem(valor: number) {
    setInfosPostagemActive((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  function loteBaixado() {
    setIsLoadingDownloadLote(false)
    setIsSuccessLoteBaixado(true)
  }

  function downloadLote(idArquivo: number) {
    setIsLoadingDownloadLote(true)

    DownloadLoteInfracao(idArquivo, valueTipoRelatorio)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          valueTipoRelatorio === 0
            ? 'loteDeNotificacoes.csv'
            : valueTipoRelatorio === 1
            ? 'loteDeNotificacoes.pdf'
            : valueTipoRelatorio === 2
            ? 'loteDeNotificacoes.xls'
            : valueTipoRelatorio === 3
            ? 'loteDeNotificacoes.zip'
            : null
        )
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

        loteBaixado()
      })
      .catch(() => {
        setIsLoadingDownloadLote(false)
        setIsFalhaLoteBaixado(true)
      })
  }

  function validaInputsPostagem() {
    if (dataPostagem === '' && codigoCorreios === '') {
      setRequiredDataPostagem(true)
      setRequiredCodigoCorreios(true)
    }

    if (dataPostagem === '') {
      setRequiredDataPostagem(true)
    } else if (codigoCorreios === '') {
      setRequiredCodigoCorreios(true)
    } else {
      return null
    }
  }

  useEffect(() => {
    if (dataPostagem !== '') {
      setRequiredDataPostagem(false)
    }

    if (codigoCorreios !== '') {
      setRequiredCodigoCorreios(false)
    }
  }, [dataPostagem, codigoCorreios])

  function lotePostado(arquivoId: number) {
    const atualizacaoLote = {
      arquivoId,
      dataPostagem,
      codigoCorreio: codigoCorreios
    }

    AtualizaStatusLote(atualizacaoLote)
      .then((response) => {
        setInfosPostagemActive(false)
        setMessageLotePostado(response.data.message)
        setIsSuccessLotePostado(true)
        atualizaLotesGerados()
      })
      .catch((error) => {
        setInfosPostagemActive(false)
        setIsFalhaLotePostado(true)
        setMessageFalhaLotePostado(error.data.message)
      })
  }

  return (
    <>
      {isLoadingDownloadLote && <OverlayLoading />}

      <div className="content">
        <div className="sideNavLateral">
          <SideBar />
        </div>

        <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
          <div className="controleNotificacoes container mt-3">
            <h2>Controle de lotes</h2>

            <form className="form mb-4">
              <div className="form-talao">
                <div className="headerPanelsInfracoes container mt-3">
                  <div className="panelFilterInfracoes col-12">
                    <div className="tituloPanel mb-2">
                      <h6>Filtros</h6>
                      <MdOutlineFilterList size={20} color="white" />
                    </div>

                    <div className="filtrosInfracoes">
                      <div className="col-12 col-md-3 mb-1">
                        <select
                          className="col-12 mt-1"
                          value={filterStatusNotificacao}
                          onChange={(e) => setFilterStatusNotificacao(e.target.value)}
                        >
                          <option disabled>Status do lote</option>
                          <option>Todos</option>
                          <option>Postados</option>
                          <option>Não postados</option>
                        </select>
                      </div>

                      <div className="selectFilterInfracoes col-12 col-md-3">
                        <select
                          className="col-12"
                          value={valueTipoNotificacao}
                          onChange={(e) => setValueTipoNotificacao(e.target.value)}
                        >
                          <option disabled>Tipo de Notificação</option>
                          <option>Autuação</option>
                        </select>
                      </div>

                      <div className="col-12 col-md-3">
                        <LinesPerPages
                          option="Registros p/ página"
                          defaultValue="Registros p/ página"
                          onChange={(e) => {
                            setCurrentPage(1)
                            setLinhasPerPage(Number(e.target.value))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {filterInfracoes.length === 0 ? (
              <div className="fieldText">
                <h4 className="mt-3"> </h4>
              </div>
            ) : (
              <div className="tabelaContainer container">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th className="colunaIconLotePostado">Status</th>
                      <th className="headerTabela">ID do lote</th>
                      <th className="headerTabela">Tipo de notificacão</th>
                      <th className="headerTabela">
                        Data de emissão
                        {isIconOrdenaData ? (
                          <RiArrowDownSLine
                            className="iconOrdenacao"
                            size={20}
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.dataEmissao < b.dataEmissao) return -1
                                if (a.dataEmissao > b.dataEmissao) return 1
                                return 0
                              }),
                              setIsIconOrdenaData(false)
                            ]}
                          />
                        ) : (
                          <RiArrowUpSLine
                            size={20}
                            className="iconOrdenacao"
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.dataEmissao < b.dataEmissao) return 1
                                if (a.dataEmissao > b.dataEmissao) return -1
                                return 0
                              }),
                              setIsIconOrdenaData(true)
                            ]}
                          />
                        )}
                      </th>

                      <th className="headerTabela">
                        Data limite
                        {isIconOrdenaDataLimite ? (
                          <RiArrowDownSLine
                            size={20}
                            className="iconOrdenacao"
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.dataLimiteProcesso < b.dataLimiteProcesso) return -1
                                if (a.dataLimiteProcesso > b.dataLimiteProcesso) return 1
                                return 0
                              }),
                              setIsIconOrdenaDataLimite(false)
                            ]}
                          />
                        ) : (
                          <RiArrowUpSLine
                            size={20}
                            className="iconOrdenacao"
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.dataLimiteProcesso < b.dataLimiteProcesso) return 1
                                if (a.dataLimiteProcesso > b.dataLimiteProcesso) return -1
                                return 0
                              }),
                              setIsIconOrdenaDataLimite(true)
                            ]}
                          />
                        )}
                      </th>

                      <th className="headerTabela">
                        Qtd de infrações
                        {isIconOrdenaNumero ? (
                          <RiArrowDownSLine
                            size={20}
                            className="iconOrdenacao"
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.quantidadeRegistros < b.quantidadeRegistros) return -1
                                if (a.quantidadeRegistros > b.quantidadeRegistros) return 1
                                return 0
                              }),
                              setIsIconOrdenaNumero(false)
                            ]}
                          />
                        ) : (
                          <RiArrowUpSLine
                            size={20}
                            className="iconOrdenacao"
                            onClick={() => [
                              filterInfracoes.sort((a, b) => {
                                if (a.quantidadeRegistros < b.quantidadeRegistros) return 1
                                if (a.quantidadeRegistros > b.quantidadeRegistros) return -1
                                return 0
                              }),
                              setIsIconOrdenaNumero(true)
                            ]}
                          />
                        )}
                      </th>
                    </tr>
                  </thead>

                  {currentInfracoes.map((dado, valor) => {
                    const anoData = dado.dataEmissao.slice(0, 4)
                    const mesData = dado.dataEmissao.slice(5, 7)
                    const diaData = dado.dataEmissao.slice(8, 10)

                    const dataEmissao = `${diaData}/${mesData}/${anoData}`

                    const anoDataLimite = dado.dataLimiteProcesso.slice(0, 4)
                    const mesDataLimite = dado.dataLimiteProcesso.slice(5, 7)
                    const diaDataLimite = dado.dataLimiteProcesso.slice(8, 10)

                    const dataLimite = `${diaDataLimite}/${mesDataLimite}/${anoDataLimite}`

                    return (
                      <tbody key={dado.id}>
                        <tr className="linhaContent" onMouseEnter={mostraIcon(valor)} onMouseLeave={mostraIcon(valor)}>
                          <td className="colunaIconLotePostado">
                            {dado.tipoStatusLote.id === 2 && (
                              <Tooltip title="Lote postado" placement="bottom" arrow>
                                <div className="iconLotePostado">
                                  <MdOutlineLocalPostOffice size={20} />
                                </div>
                              </Tooltip>
                            )}
                          </td>

                          <td className="dadoTabela">
                            <span>{dado.id}</span>
                          </td>

                          <td className="dadoTabela">
                            <span>{dado.tipoNotificacao.descricao}</span>
                          </td>

                          <td className="dadoTabela">
                            <span>{dataEmissao}</span>
                          </td>

                          <td>{dataLimite}</td>

                          <td>
                            <div className="colunaExpandeInfracao">
                              <div>{dado.quantidadeRegistros}</div>

                              {showIcon[valor] && (
                                <div className="iconeExpandeInfracao">
                                  <RiArrowDownSLine size={20} onClick={mostraDetalhesInfracao(valor)} />
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>

                        {showDetailsInfracao[valor] && (
                          <tr className="linhaDetailsInfracao">
                            <td colSpan={6}>
                              <div className="detailsInfracao">
                                {dado.tipoStatusLote.id === 1 && (
                                  <div className="iconesExport container mb-4">
                                    <div className="col-12">
                                      <div className="envioLote container mb-3 mt-3 col-12">
                                        <div className="tipoArquivoDetailsInfracao col-5 col-md-4">
                                          <div>
                                            <span>Tipo de arquivo:</span>
                                          </div>
                                          <select
                                            className="col-12"
                                            value={valueTipoRelatorio}
                                            onChange={(e) => setValueTipoRelatorio(Number(e.target.value))}
                                          >
                                            <option disabled>Tipo de arquivo</option>
                                            {tiposRelatorio.map((tipoArquivo) => (
                                              <option key={tipoArquivo.id} value={tipoArquivo.id}>
                                                {tipoArquivo.descricao.toUpperCase()}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        <div className="botaoGerarLote col-12 col-md-6">
                                          <div
                                            className="labelGerarLote col-5 col-md-12 mt-4"
                                            onClick={() => downloadLote(dado.id)}
                                          >
                                            <span className="textGerarLote">Gerar lote novamente</span>
                                            {valueTipoRelatorio === 0 ? (
                                              <FaFileCsv size={18} />
                                            ) : valueTipoRelatorio === 1 ? (
                                              <FaFilePdf size={18} />
                                            ) : valueTipoRelatorio === 2 ? (
                                              <FaFileExcel size={18} />
                                            ) : (
                                              <FaFileArchive size={18} />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="dadosCompletosInfracao mt-2 mb-2">
                                  <div className="statusInfracao col-12">
                                    <Stepper activeStep={dado.tipoStatusLote.id} alternativeLabel>
                                      {statusInfracoes.map((label) => (
                                        <Step key={label.id}>
                                          <Tooltip
                                            title={`${
                                              label.id === 2 && dado.tipoStatusLote.id === 1
                                                ? 'Identificar lote como postado'
                                                : ''
                                            }`}
                                            placement="top"
                                            arrow
                                          >
                                            <StepLabel
                                              onClick={
                                                label.id === 2 && dado.tipoStatusLote.id === 1
                                                  ? () => ativaInfosPostagem(valor)
                                                  : null
                                              }
                                              className={`${
                                                label.id === 2 && dado.tipoStatusLote.id === 1
                                                  ? 'statusPostagem'
                                                  : 'statusInfracao'
                                              }`}
                                            >
                                              {label.status}
                                            </StepLabel>
                                          </Tooltip>
                                        </Step>
                                      ))}
                                    </Stepper>

                                    {infosPostagemActive[valor] && dado.tipoStatusLote.id === 1 && (
                                      <div className="infosInfracaoPostada mt-3">
                                        <label className="data col-5 col-md-3">
                                          Data de postagem
                                          <input
                                            type="date"
                                            name="dataInfracao mb-3"
                                            id="data"
                                            className="form-control"
                                            value={dataPostagem}
                                            max="9999-12-31"
                                            onChange={(e) => setDataPostagem(e.target.value)}
                                          />
                                          {requiredDataPostagem && (
                                            <span className="mensagemErro">Insira uma data válida</span>
                                          )}
                                        </label>

                                        <div className="labelForm col-5 col-md-3">
                                          <input
                                            type="text"
                                            name="codigoCorreios"
                                            id="codigoCorreios"
                                            className="form-control"
                                            placeholder="Código dos correios"
                                            value={codigoCorreios}
                                            onChange={(e) => setCodigoCorreios(e.target.value)}
                                            maxLength={13}
                                          />
                                          <label htmlFor="codigoCorreios"> Código dos correios </label>
                                          {requiredCodigoCorreios && <ErrorForm />}
                                        </div>

                                        <div className="mt-2">
                                          <ButtonSubmit
                                            text="Confirmar"
                                            onClick={
                                              dataPostagem.length === 0 || codigoCorreios.length === 0
                                                ? () => validaInputsPostagem()
                                                : () => lotePostado(dado.id)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <ModalMessage
                                  title={['Lote baixado com sucesso!']}
                                  className="modalSuccess"
                                  show={isSuccessLoteBaixado}
                                  onHide={() => setIsSuccessLoteBaixado(false)}
                                  textbutton="OK"
                                />

                                <ModalMessage
                                  title={['Ocorreu um erro no download do lote. Por favor, tente novamente']}
                                  className="modalFalha"
                                  show={isFalhaLoteBaixado}
                                  onHide={() => setIsFalhaLoteBaixado(false)}
                                  textbutton="OK"
                                />

                                <ModalMessage
                                  title={[messageLotePostado]}
                                  className="modalSuccess"
                                  show={isSuccessLotePostado}
                                  onHide={() => setIsSuccessLotePostado(false)}
                                  textbutton="OK"
                                />

                                <ModalMessage
                                  title={[messageFalhaLotePostado]}
                                  className="modalFalha"
                                  show={IsFalhaLotePostado}
                                  onHide={() => setIsFalhaLotePostado(false)}
                                  textbutton="OK"
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )
                  })}
                </Table>
              </div>
            )}

            <Pagination
              currentPage={currentPage}
              registersPerPage={linhasPerPage}
              onPageChange={setCurrentPage}
              totalCountOfRegisters={filterInfracoes.length}
              textNoContent="Nenhum lote encontrado"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ControleDeTalao
