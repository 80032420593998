import Tooltip from '@mui/material/Tooltip'
import { LinesPerPages } from 'components/Pages/LinesPerPage'
import { InputHTMLAttributes, ReactNode } from 'react'
import { BsSearch } from 'react-icons/bs'

import './styles.css'

interface SearchFormInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string
  id?: string
  onChangeBusca: React.ChangeEventHandler<HTMLInputElement>
  onChangeFilterGeral?: (e: any) => void
  filterMap?: any[]
  children?: ReactNode
  defaultValueRegisters?: string | number | readonly string[]
  onChangeRegisters?: React.ChangeEventHandler<HTMLSelectElement>
}

export function SearchForm({
  defaultValue,
  placeholder,
  id,
  value,
  name,
  filterMap,
  children,
  defaultValueRegisters,
  onChangeFilterGeral,
  onChangeBusca,
  onChangeRegisters
}: SearchFormInputProps) {
  return (
    <div className="menuNavegacao mb-2">
      <div className="iconsMenu">
        <div className="pesquisaTabela">
          <form>
            <div className="labelFiltro">
              {defaultValue ? (
                <select value={defaultValue} onChange={onChangeFilterGeral}>
                  <option>Todos</option>
                  {filterMap ? (
                    filterMap.map((dado) => (
                      <option key={dado.id} value={dado.descricao}>
                        {dado.descricao}
                      </option>
                    ))
                  ) : (
                    <>
                      <option>Ativos</option>
                      <option>Inativos</option>
                    </>
                  )}
                </select>
              ) : (
                <div className="iconBusca">
                  <Tooltip title="Pesquisar" placement="bottom" arrow>
                    <div>
                      <BsSearch size={25} color="white" />
                    </div>
                  </Tooltip>
                </div>
              )}

              <input
                type="text"
                name={name}
                placeholder={placeholder}
                className="formFiltro form-control"
                id={name}
                value={value}
                onChange={onChangeBusca}
              />

              {defaultValueRegisters ? (
                <LinesPerPages defaultValue={defaultValueRegisters} onChange={onChangeRegisters} />
              ) : (
                <div className="iconBusca">
                  <Tooltip title="Pesquisar" placement="bottom" arrow>
                    <div>
                      <BsSearch size={25} color="white" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </form>
        </div>
        {children || null}
      </div>
    </div>
  )
}
