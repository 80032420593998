import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'

interface SearchFilterProps {
  onClickDecrescente?: () => void
  onClickCrescente?: () => void
  ordenaDescrescente: boolean
}

export function SearchFilter({ onClickDecrescente, onClickCrescente, ordenaDescrescente }: SearchFilterProps) {
  return ordenaDescrescente ? (
    <RiArrowDownSLine size={20} className="iconOrdenacao" onClick={onClickDecrescente} />
  ) : (
    <RiArrowUpSLine size={20} className="iconOrdenacao" onClick={onClickCrescente} />
  )
}
