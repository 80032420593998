import Tooltip from '@mui/material/Tooltip'
import React, { useContext, useEffect, useState } from 'react'
import './styles.css'
import { BiArrowBack } from 'react-icons/bi'

import ModalMessage from '../../components/Modais/ModalMessage'
import { PasswordInput } from '../../components/PasswordInput'
import SideBar from '../../components/SideBarLateral'
import { AuthContext } from '../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { AtualizaSenha, DetalhesUsuario } from '../../services/accountService'

export default function PerfilUsuarios() {
  const { classSideNav } = useContext(CondicionaisFormContext)
  const { user } = useContext(AuthContext)

  const [isInformacoesPerfil, setIsInformacoesPerfil] = useState(true)
  const [isRedefinicaoSenha, setIsRedefinicaoSenha] = useState(false)

  const [nomeUsuario, setNomeUsuario] = useState('')
  const [emailUsuario, setEmailUsuario] = useState('')

  // #region Informações do usuário e redefinação de senha
  function ativaRedefinicaoSenha() {
    setIsRedefinicaoSenha(!isRedefinicaoSenha)
    setIsInformacoesPerfil(!isInformacoesPerfil)
  }
  // #endregion

  // #region Api para pegar e mostrar informações do usuário
  useEffect(() => {
    DetalhesUsuario(user).then(({ data }) => {
      setNomeUsuario(`${data.data.primeiroNome} ${data.data.ultimoNome}`)
      setEmailUsuario(data.data.email)
    })
  }, [user])
  // #endregion

  return (
    <div className="content">
      <div className="sideNavLateral">
        <SideBar />
      </div>

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="perfil container mt-3">
          <h2 className="mt-3">Meu perfil</h2>

          {isInformacoesPerfil ? (
            <IsInformacoesPerfil
              nomeUsuario={nomeUsuario}
              emailUsuario={emailUsuario}
              userName={user}
              ativaRedefinicaoSenha={ativaRedefinicaoSenha}
            />
          ) : null}

          {isRedefinicaoSenha ? (
            <IsRedefinicaoSenha
              userName={user}
              ativaRedefinicaoSenha={ativaRedefinicaoSenha}
              setIsInformacoesPerfil={setIsInformacoesPerfil}
              setIsRedefinicaoSenha={setIsRedefinicaoSenha}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

interface IsInformacoesPerfilProps {
  readonly nomeUsuario: string
  readonly emailUsuario: string
  readonly userName: string
  readonly ativaRedefinicaoSenha: () => void
}

function IsInformacoesPerfil({ nomeUsuario, emailUsuario, userName, ativaRedefinicaoSenha }: IsInformacoesPerfilProps) {
  return (
    <form className="formPerfilUsuario mb-4 mt-3" autoComplete="off">
      <div className="fieldPerfilUsuario">
        <div className="labelForm col-12 mt-2">
          <input
            type="text"
            className="form-control"
            name="primeiroNome"
            id="primeiroNome"
            placeholder="Nome"
            disabled
            defaultValue={nomeUsuario}
          />
          <label htmlFor="primeiroNome">Nome</label>
        </div>

        <div className="labelForm col-12 mt-3">
          <input
            type="text"
            className="form-control"
            name="userName"
            id="userName"
            placeholder="Usuário"
            disabled
            defaultValue={userName}
          />
          <label htmlFor="userName">Usuário</label>
        </div>

        <div className="labelForm col-12 mt-3">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="E-mail"
            disabled
            defaultValue={emailUsuario}
          />
          <label htmlFor="email">E-mail</label>
        </div>

        <div className="labelForm col-12 mt-3 mb-2">
          <div className="redefinirSenha" onClick={ativaRedefinicaoSenha}>
            <span>Redefinir senha</span>
          </div>
        </div>
      </div>
    </form>
  )
}

interface IsRedefinicaoSenhaProps {
  readonly userName: string
  readonly ativaRedefinicaoSenha: () => void
  readonly setIsInformacoesPerfil: (value: React.SetStateAction<boolean>) => void
  readonly setIsRedefinicaoSenha: (value: React.SetStateAction<boolean>) => void
}

function IsRedefinicaoSenha({
  userName,
  ativaRedefinicaoSenha,
  setIsInformacoesPerfil,
  setIsRedefinicaoSenha
}: IsRedefinicaoSenhaProps) {
  const [senhaAtual, setSenhaAtual] = useState('')
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState('')

  const [isSenhaAlterada, setIsSenhaAlterada] = useState(false)
  const [isSenhaAlteradaFailed, setIsSenhaAlteradaFailed] = useState(false)
  const [isModalSenhaAlterada, setIsModalSenhaAlterada] = useState(false)
  const [isModalSenhaAlteradaFailed, setIsModalSenhaAlteradaFailed] = useState(false)
  const [messageModalAlteracaoSenha, setMessageModalAlteracaoSenha] = useState('')

  function confirmaAlteracaoSenha() {
    AtualizaSenha(userName, senhaAtual, novaSenha, confirmacaoNovaSenha)
      .then(({ data }) => {
        setMessageModalAlteracaoSenha(data.message)
        setIsSenhaAlterada(true)
        setIsModalSenhaAlterada(true)
      })
      .catch((error) => {
        const { errors } = error.response.data
        if (errors !== undefined) {
          Object.keys(errors).forEach((campo) => {
            setMessageModalAlteracaoSenha(errors[campo])
          })
        } else {
          const erro = error.response.data.message
          setMessageModalAlteracaoSenha(erro)
        }
        setIsSenhaAlteradaFailed(true)
        setIsModalSenhaAlteradaFailed(true)
      })
  }

  return (
    <>
      <form className="formPerfilUsuario mb-4 mt-3" autoComplete="off">
        <div className="fieldPerfilUsuario">
          <PasswordInput
            className="labelForm col-12 mt-2"
            name="senhaAtual"
            id="senhaAtual"
            value={senhaAtual}
            onChange={(e) => setSenhaAtual(e.target.value)}
            placeholder="Senha atual"
            label="Senha atual"
          />

          <PasswordInput
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
            className="labelForm col-12 mt-2"
            name="novaSenha"
            id="novaSenha"
            placeholder="Nova senha"
            label="Nova senha"
          />

          <PasswordInput
            className="labelForm col-12 mt-2"
            name="confirmacaoSenha"
            id="confirmacaoSenha"
            label="Confirmar nova senha"
            value={confirmacaoNovaSenha}
            onChange={(e) => setConfirmacaoNovaSenha(e.target.value)}
            placeholder="Confirmar nova senha"
          />

          <div className="confirmaAlteracao col-12 mt-2">
            <Tooltip title="Voltar ao perfil" placement="bottom" arrow className="voltarPerfil">
              <div>
                <BiArrowBack size={25} onClick={ativaRedefinicaoSenha} className="iconNavegacao" />
              </div>
            </Tooltip>
            <div className="confirmarSenha col-8" onClick={confirmaAlteracaoSenha}>
              <span>Confirmar alteração de senha</span>
            </div>
          </div>
        </div>
      </form>

      {isSenhaAlterada ? (
        <ModalMessage
          title={[messageModalAlteracaoSenha]}
          className="modalSuccessCadastro"
          textbutton="Voltar ao perfil"
          textbutton2="Ir para o início"
          show={isModalSenhaAlterada}
          onHide={() => [
            setIsModalSenhaAlterada(false),
            setIsInformacoesPerfil(true),
            setIsRedefinicaoSenha(false),
            setSenhaAtual(''),
            setNovaSenha(''),
            setConfirmacaoNovaSenha('')
          ]}
        />
      ) : null}

      {isSenhaAlteradaFailed ? (
        <ModalMessage
          title={[messageModalAlteracaoSenha]}
          className="modalFalha"
          textbutton="Tentar novamente"
          show={isModalSenhaAlteradaFailed}
          onHide={() => [
            setIsModalSenhaAlteradaFailed(false),
            setSenhaAtual(''),
            setNovaSenha(''),
            setConfirmacaoNovaSenha('')
          ]}
        />
      ) : null}
    </>
  )
}
