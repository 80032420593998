import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ReativarAgente } from 'services/agenteService'

import ModalMessage from '../../ModalMessage'

interface ModalReativacaoAgenteProps {
  readonly nome: string
  readonly id: number
  readonly show: boolean
  readonly className?: string
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalReativacaoAgente({
  nome,
  id,
  className,
  show,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalReativacaoAgenteProps) {
  const { atualizaAgentes } = useContext(DadosContext)

  const [successReativaAgente, setSuccessReativaAgente] = useState(false)
  const [falhaReativaAgente, setFalhaReativaAgente] = useState(false)
  const [messageModalReativaAgente, setMessageModalReativaAgente] = useState([])

  async function reativaAgente() {
    ReativarAgente(id)
      .then((response) => {
        setMessageModalReativaAgente([response.data.message])
        setSuccessReativaAgente(true)
      })
      .catch((error) => {
        setMessageModalReativaAgente([error.response.data.message])
        setFalhaReativaAgente(true)
      })
  }

  return (
    <>
      {!successReativaAgente && !falhaReativaAgente && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja reativar o agente {nome}?</h4>
            </div>

            <div className="buttonsModalEdit">
              <Button onClick={reativaAgente} variant="none" className="buttonSave">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalReativaAgente}
        className="modalSuccess"
        show={successReativaAgente}
        onHide={() => [setSuccessReativaAgente(false), onClick(), atualizaAgentes()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalReativaAgente}
        className="modalFalha"
        show={falhaReativaAgente}
        onHide={() => [setFalhaReativaAgente(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalReativacaoAgente
