import { createContext, ReactNode, useEffect, useState, useMemo, useContext } from 'react'
import { GetAllPermissionarios } from 'services/permissionariosService'

import { AuthContext } from './AuthContext'

interface LoadDataContextProps {
  allRegistersPermissionarios: any[]
  setAllRegistersPermissionarios: React.Dispatch<React.SetStateAction<any[]>>
  loadAllPermissionarios: boolean
  setLoadAllPermissionarios: React.Dispatch<React.SetStateAction<boolean>>
}

interface LoadDataContextProviderProps {
  children: ReactNode
}

export const LoadDataContext = createContext({} as LoadDataContextProps)

export function LoadDataContextProvider({ children }: LoadDataContextProviderProps) {
  const { user } = useContext(AuthContext)

  const [allRegistersPermissionarios, setAllRegistersPermissionarios] = useState([])
  const [loadAllPermissionarios, setLoadAllPermissionarios] = useState(true)

  useEffect(() => {
    if (user) {
      GetAllPermissionarios().then((response) => {
        setAllRegistersPermissionarios(response.data.data.permissionarios)
        setLoadAllPermissionarios(false)
      })
    }
  }, [user])

  const contextValues = useMemo(
    () => ({
      allRegistersPermissionarios,
      setAllRegistersPermissionarios,
      loadAllPermissionarios,
      setLoadAllPermissionarios
    }),
    [allRegistersPermissionarios, loadAllPermissionarios]
  )

  return <LoadDataContext.Provider value={contextValues}>{children}</LoadDataContext.Provider>
}
