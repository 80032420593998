import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { EnquadramentoCliente, EnquadramentoServico } from '../services/enquadramentoService'
import { AuthContext } from './AuthContext'

interface EnquadramentoContextProviderProps {
  children: ReactNode
}

interface EnquadramentoContextProps {
  descricaoEnquadramento: string
  numeroEnquadramento: string
  nomeUnidadeFinanceira: string
  idEnquadramento: number
  valorUnidadeFinanceira: number
  rowsEnquadramento: number
  isEnquadramentoRequired: boolean
  setDescricaoEnquadramento: React.Dispatch<React.SetStateAction<string>>
  setIsEnquadramentoRequired: React.Dispatch<React.SetStateAction<boolean>>
  setnumeroEnquadramento: React.Dispatch<React.SetStateAction<string>>
  onChangeEnquadramento: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  selecionaEnquadramento: (dado: any) => void
  Enquadramento: any[]
  filterEnquadramento: any[]
  loadEnquadramentos: boolean
  setLoadEnquadramentos: React.Dispatch<React.SetStateAction<boolean>>
  fetchEnquadramentos: () => void
}

export const EnquadramentoContext = createContext({} as EnquadramentoContextProps)

export function EnquadramentoContextProvider({ children }: EnquadramentoContextProviderProps) {
  const { clienteCode, serviceValue } = useContext(AuthContext)

  const [descricaoEnquadramento, setDescricaoEnquadramento] = useState('')
  const [nomeUnidadeFinanceira, setNomeUnidadeFinanceira] = useState('')
  const [numeroEnquadramento, setnumeroEnquadramento] = useState('')

  const [valorUnidadeFinanceira, setValorUnidadeFinanceira] = useState(0)
  const [idEnquadramento, setIdEnquadramento] = useState(0)
  const [rowsEnquadramento, setRowsEnquadramento] = useState(1)

  const [isEnquadramentoRequired, setIsEnquadramentoRequired] = useState(true)

  const [Enquadramento, setEnquadramento] = useState([])
  const [filterEnquadramento, setFilterEnquadramento] = useState([])

  const [loadEnquadramentos, setLoadEnquadramentos] = useState(true)

  const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => {
      setTimeout(resolve, ms)
    })

  const MAX_RETRIES = 3
  const fetchDataWithRetry = useCallback(async (fetchFunction, params, retries = MAX_RETRIES) => {
    try {
      const response = await fetchFunction(params)
      return response.data
    } catch (error) {
      if (retries > 0) {
        console.log(`Tentanto novamente... ${retries} tentativas restantes`)
        await delay(1000)
        return fetchDataWithRetry(fetchFunction, params, retries - 1)
      } else {
        console.error('Tentativas esgotadas:', error)
        throw error
      }
    }
  }, [])

  const fetchEnquadramentos = useCallback(() => {
    setLoadEnquadramentos(true)

    const fetchFunction = EnquadramentoCliente
    const fetchParams = clienteCode

    fetchDataWithRetry(fetchFunction, fetchParams)
      .then((response) => {
        const { data } = response
        setEnquadramento(data.enquadramentos)
        setValorUnidadeFinanceira(data.configuracao?.valorUnidadeFinanceira)
        setNomeUnidadeFinanceira(data.configuracao?.nomeUnidadeFinanceira)
        setLoadEnquadramentos(false)
      })
      .catch((error: any) => {
        console.error('Falha ao buscar os enquadramentos:', error)
        setLoadEnquadramentos(false)
      })
  }, [serviceValue, clienteCode, fetchDataWithRetry])

  useEffect(() => {
    fetchEnquadramentos()
  }, [fetchEnquadramentos])

  const onChangeEnquadramento = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setnumeroEnquadramento(e.target.value)

      const FiltroEnquadramento = Enquadramento.filter(
        (value) =>
          value.codigo.toString().includes(e.target.value) ||
          value.descricao.toLowerCase().includes(e.target.value.toLowerCase())
      )

      setFilterEnquadramento(FiltroEnquadramento)
    },
    [Enquadramento]
  )

  function selecionaEnquadramento(dado: any) {
    setIdEnquadramento(dado.id)
    setnumeroEnquadramento(`${dado.codigo} - ${dado.grupo} - ${dado.descricao}`)
    setDescricaoEnquadramento(dado.descricao)
    setFilterEnquadramento([])
  }

  useEffect(() => {
    if (!numeroEnquadramento) {
      setFilterEnquadramento([])
      setIdEnquadramento(0)
      setDescricaoEnquadramento('')
      setRowsEnquadramento(1)
    }

    if (numeroEnquadramento.length > 55 && numeroEnquadramento.length < 100) {
      setRowsEnquadramento(2)
    } else if (numeroEnquadramento.length > 100) {
      setRowsEnquadramento(3)
    }
  }, [numeroEnquadramento])

  const contextValues = useMemo(
    () => ({
      Enquadramento,
      serviceValue,
      selecionaEnquadramento,
      onChangeEnquadramento,
      filterEnquadramento,
      descricaoEnquadramento,
      idEnquadramento,
      numeroEnquadramento,
      setDescricaoEnquadramento,
      nomeUnidadeFinanceira,
      valorUnidadeFinanceira,
      rowsEnquadramento,
      setnumeroEnquadramento,
      isEnquadramentoRequired,
      setIsEnquadramentoRequired,
      loadEnquadramentos,
      setLoadEnquadramentos,
      fetchEnquadramentos
    }),
    [
      Enquadramento,
      descricaoEnquadramento,
      isEnquadramentoRequired,
      filterEnquadramento,
      idEnquadramento,
      nomeUnidadeFinanceira,
      numeroEnquadramento,
      onChangeEnquadramento,
      rowsEnquadramento,
      serviceValue,
      valorUnidadeFinanceira,
      loadEnquadramentos,
      fetchEnquadramentos
    ]
  )
  return <EnquadramentoContext.Provider value={contextValues}>{children}</EnquadramentoContext.Provider>
}
