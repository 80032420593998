import './styles.css'
import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@mui/material/Tooltip'
import IconsModify from 'components/IconsModify'
import LoadComponent from 'components/loadComponent'
import ModalReativacaoAgente from 'components/Modais/ModaisAgentes/ModalReativaAgente'
import { Pagination } from 'components/Pages/Pagination'
import { SearchFilter } from 'components/SearchFilter'
import { SearchForm } from 'components/SearchForm'
import { AuthContext } from 'contexts/AuthContext'
import { ListsContext } from 'contexts/ListsContext'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { novoAgente } from 'services/agenteService'
import * as yup from 'yup'

import ButtonSubmit from '../../components/ButtonSubmit'
import { ErrorForm } from '../../components/FormTalao/ErrorForm'
import ModalEdicaoAgente from '../../components/Modais/ModaisAgentes/ModalEdicaoAgente'
import ModalInativacaoAgente from '../../components/Modais/ModaisAgentes/ModalInativaAgente'
import ModalMessage from '../../components/Modais/ModalMessage'
import SideBar from '../../components/SideBarLateral'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'

export default function ConfiguraAgente() {
  const { classSideNav } = useContext(CondicionaisFormContext)

  const { Permissoes } = useContext(AuthContext)
  const { Agentes } = useContext(DadosContext)

  const [tabelaAgentes, setTabelaAgentes] = useState(true)
  const [cadastraAgente, setCadastraAgente] = useState(false)

  const [filterGeral, setFilterGeral] = useState('Todos')
  const [searchAgente, setSearchAgente] = useState('')
  const [filterSearchAgente, setFilterSearchAgente] = useState([])
  const [filterAtivos, setFilterAtivos] = useState([])
  const [filterInativos, setFilterInativos] = useState([])

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const [currentAgentes, setCurrentAgentes] = useState([])

  function ativaCadastroAgente() {
    setCadastraAgente(true)
    setTabelaAgentes(false)
  }

  function ativaPesquisa() {
    setTabelaAgentes(true)
    setCadastraAgente(false)
    setCurrentPage(1)
  }

  const onChangeBuscaAgente = (e) => {
    e.preventDefault()
    setSearchAgente(e.target.value)

    if (filterGeral === 'Todos') {
      const Filtro = Agentes.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.numero?.toString().includes(e.target.value)
      )

      setFilterSearchAgente(Filtro)
    } else if (filterGeral === 'Inativos') {
      const Filtro = filterInativos.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.numero?.toString().includes(e.target.value)
      )

      setFilterSearchAgente(Filtro)
    } else if (filterGeral === 'Ativos') {
      const Filtro = filterAtivos.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.numero?.toString().includes(e.target.value)
      )

      setFilterSearchAgente(Filtro)
    }
  }

  useEffect(() => {
    setFilterSearchAgente(Agentes)
  }, [Agentes])

  useEffect(() => {
    setCurrentAgentes(filterSearchAgente.slice(startIndex, endIndex))
  }, [startIndex, endIndex, filterSearchAgente])

  useEffect(() => {
    if (filterGeral === 'Todos') {
      const FiltroGeral = Agentes
      setFilterSearchAgente(FiltroGeral)
    } else if (filterGeral === 'Ativos') {
      const FiltroGeral = Agentes.filter((value) => !value.dataInativo)
      setFilterAtivos(FiltroGeral)
      setFilterSearchAgente(FiltroGeral)
    } else if (filterGeral === 'Inativos') {
      const FiltroGeral = Agentes.filter((value) => value.dataInativo)
      setFilterInativos(FiltroGeral)
      setFilterSearchAgente(FiltroGeral)
    }
  }, [filterGeral, Agentes])

  useEffect(() => {
    if (searchAgente.length > 0) {
      ativaPesquisa()
    }
  }, [searchAgente])

  useEffect(() => {
    setCurrentPage(1)
  }, [linhasPerPage, filterGeral])

  return (
    <div className="content">
      {Permissoes.toString() === 'Administrador' && (
        <div className="sideNavLateral">
          <SideBar />
        </div>
      )}

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2>Configurações de Agente</h2>

          <SearchForm
            defaultValue={filterGeral}
            value={searchAgente}
            onChangeFilterGeral={(e) => setFilterGeral(e.target.value)}
            onChangeBusca={onChangeBuscaAgente}
            defaultValueRegisters={linhasPerPage}
            onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
            placeholder="Pesquisar agente"
            name="buscaAgente"
          >
            <div className="itemMenuNavegacao">
              {tabelaAgentes ? (
                <Tooltip title="Novo agente" placement="bottom" arrow>
                  <div>
                    <BsFillPersonPlusFill size={25} onClick={ativaCadastroAgente} className="iconNavegacao" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                  <div>
                    <BiArrowBack
                      size={25}
                      onClick={() => [setTabelaAgentes(true), setCadastraAgente(false)]}
                      className="iconNavegacao"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </SearchForm>

          {tabelaAgentes && (
            <ListagemAgentes
              filtro={filterSearchAgente}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              linhasPerPage={linhasPerPage}
              agentesAtuais={currentAgentes}
              setAgentesAtuais={setCurrentAgentes}
              startIndex={startIndex}
              endIndex={endIndex}
            />
          )}

          {cadastraAgente && <FormNovoAgente />}
        </div>
      </div>
    </div>
  )
}

function FormNovoAgente() {
  const { cpfAgenteValue, setCpfAgenteValue, inputCpfAgenteMask, Corporacao, atualizaAgentes } =
    useContext(DadosContext)

  const [successRegisterAgente, setSuccessRegisterAgente] = useState(false)
  const [falhaRegisterAgente, setFalhaRegisterAgente] = useState(false)
  const [messageModalAgente, setMessageModalAgente] = useState([])

  const validaCriacaoAgente = yup.object({
    nome: yup.string().required(),
    cpf: yup.string().required(),
    corporacaoId: yup.number().required(),
    numero: yup.string().required()
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaCriacaoAgente)
  })

  function submitAgente() {
    setSuccessRegisterAgente(true)
    setCpfAgenteValue('')
  }

  const formCriaAgente = (data) => {
    novoAgente(data)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalAgente([response.data.message])
          submitAgente()
          atualizaAgentes()
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalAgente([error.response.data.message])
            setFalhaRegisterAgente(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalAgente(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaRegisterAgente(true)
          }
        }
      })
  }

  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4" onSubmit={handleSubmit(formCriaAgente)} autoComplete="off">
        <div className="subtitulo mb-2">
          <h6 className="mt-1">Cadastrar novo agente</h6>
        </div>

        <div className="formCriaRegistro">
          <div className="labelForm col-12 col-md-5">
            <input
              type="text"
              className="form-control"
              name="nomeAgente"
              id="nomeAgente"
              placeholder="Nome"
              {...register('nome')}
            />
            <label htmlFor="nomeAgente">Nome</label>
            {errors?.nome?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-5">
            <input
              type="text"
              name="cpfAgente"
              id="cpfAgente"
              className="form-control"
              placeholder="CPF"
              {...register('cpf')}
              value={cpfAgenteValue}
              onChange={inputCpfAgenteMask}
            />
            <label htmlFor="cpfAgente"> CPF </label>
            {errors?.cpf?.type && cpfAgenteValue.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-3">
            <input
              type="text"
              name="numeroAgente"
              id="numeroAgente"
              className="form-control"
              placeholder="Número"
              {...register('numero')}
            />
            <label htmlFor="numeroAgente"> Número </label>
            {errors?.numero?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-7 mb-3">
            <select
              name="corporacao"
              className="col-12"
              defaultValue="SELECIONE A CORPORAÇÃO"
              {...register('corporacaoId')}
            >
              <option disabled>SELECIONE A CORPORAÇÃO</option>
              {Corporacao.map((dado) => (
                <option key={dado.id} value={dado.id}>
                  {dado.descricao}
                </option>
              ))}
            </select>
            {errors?.corporacaoId?.type && <ErrorForm />}
          </div>

          <div className="buttonCriaRegistro col-12">
            <ButtonSubmit text="Cadastrar" />
          </div>

          <ModalMessage
            title={messageModalAgente}
            className="modalSuccess"
            show={successRegisterAgente}
            onHide={() => [setSuccessRegisterAgente(false), reset()]}
            textbutton="Voltar ao registro de Agente"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAgente}
            className="modalFalha"
            show={falhaRegisterAgente}
            onHide={() => setFalhaRegisterAgente(false)}
            textbutton="Tentar novamente"
            textbutton2="Ir para o ínicio"
          />
        </div>
      </form>
    </div>
  )
}

interface ListagemAgentesProps {
  readonly filtro: any[]
  readonly currentPage: number
  readonly setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  readonly linhasPerPage: number
  readonly agentesAtuais: any[]
  readonly setAgentesAtuais: React.Dispatch<React.SetStateAction<any[]>>
  readonly startIndex: number
  readonly endIndex: number
}

function ListagemAgentes({
  filtro,
  currentPage,
  setCurrentPage,
  linhasPerPage,
  agentesAtuais,
  setAgentesAtuais,
  startIndex,
  endIndex
}: ListagemAgentesProps) {
  const {
    showIcon,
    setShowIcon,
    messageDataInativo,
    showModalEdit,
    setShowModalEdit,
    showModalInvalid,
    setShowModalInvalid,
    showModalReative,
    setShowModalReative,
    mostraIcon,
    mostraEdicao,
    mostraInativacao,
    mostraReativacao,
    ativaMessageDataInativo
  } = useContext(ListsContext)

  const { loadAgentes } = useContext(DadosContext)

  const [iconOrdenaNumero, setIconOrdenaNumero] = useState(true)
  const [iconOrdenaNome, setIconOrdenaNome] = useState(true)

  return (
    <>
      <div className="tableDefault container">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela">
                Número
                {iconOrdenaNumero ? (
                  <SearchFilter
                    ordenaDescrescente
                    onClickDecrescente={() => [
                      filtro.sort((a, b) => (a.numero > b.numero ? 1 : -1)),
                      setAgentesAtuais(filtro.slice(startIndex, endIndex)),
                      setIconOrdenaNumero(false)
                    ]}
                  />
                ) : (
                  <SearchFilter
                    ordenaDescrescente={false}
                    onClickCrescente={() => [
                      filtro.sort((a, b) => (a.numero < b.numero ? 1 : -1)),
                      setAgentesAtuais(filtro.slice(startIndex, endIndex)),
                      setIconOrdenaNumero(true)
                    ]}
                  />
                )}
              </th>
              <th className="headerTabela">
                <span>
                  Nome
                  {iconOrdenaNome ? (
                    <SearchFilter
                      ordenaDescrescente
                      onClickDecrescente={() => [
                        filtro.sort((a, b) => (a.nome > b.nome ? 1 : -1)),
                        setAgentesAtuais(filtro.slice(startIndex, endIndex)),
                        setIconOrdenaNome(false)
                      ]}
                    />
                  ) : (
                    <SearchFilter
                      ordenaDescrescente={false}
                      onClickCrescente={() => [
                        filtro.sort((a, b) => (a.nome < b.nome ? 1 : -1)),
                        setAgentesAtuais(filtro.slice(startIndex, endIndex)),
                        setIconOrdenaNome(true)
                      ]}
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {agentesAtuais.map((dado, valor) => {
              const anoData = dado.dataInativo?.slice(0, 4)
              const mesData = dado.dataInativo?.slice(5, 7)
              const diaData = dado.dataInativo?.slice(8, 10)

              const data = `${diaData}/${mesData}/${anoData}`
              const dataFinal = data === 'undefined/undefined/undefined' ? null : data

              return (
                <tr key={dado.id} className="linhaDefault">
                  <td className="dadoTabela col-1">
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.numero}
                    </div>
                  </td>
                  <td
                    className="nomeAgentes"
                    onMouseEnter={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onMouseLeave={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onClick={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                  >
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>{dado.nome}</div>

                    {showIcon[valor] && (
                      <IconsModify
                        isInativo={false}
                        onClickEdit={mostraEdicao(valor)}
                        onClickInative={mostraInativacao(valor)}
                      />
                    )}

                    {messageDataInativo[valor] && (
                      <IconsModify isInativo onClickReative={mostraReativacao(valor)} dataFinal={dataFinal} />
                    )}
                  </td>

                  {showModalEdit[valor] && (
                    <td className="modalEdit">
                      <ModalEdicaoAgente
                        show={!!showModalEdit}
                        onHide={() => [setShowModalEdit({}), setShowIcon({})]}
                        onClick={() => setShowModalEdit({})}
                        textbutton="Salvar"
                        textbutton2="Cancelar"
                        numero={dado.numero}
                        nome={dado.nome}
                        cpf={dado.cpf}
                        corporacao={dado.corporacao.id}
                        id={dado.id}
                      />
                    </td>
                  )}

                  {showModalInvalid[valor] && (
                    <td className="modalInvalid">
                      <ModalInativacaoAgente
                        show={!!showModalInvalid}
                        onHide={() => [setShowModalInvalid({}), setShowIcon({})]}
                        onClick={() => setShowModalInvalid({})}
                        textbutton="Inativar"
                        textbutton2="Cancelar"
                        nome={dado.nome}
                        id={dado.id}
                      />
                    </td>
                  )}

                  {showModalReative[valor] && (
                    <td className="modalInvalid">
                      <ModalReativacaoAgente
                        show={!!showModalReative}
                        onHide={() => [setShowModalReative({}), setShowIcon({})]}
                        onClick={() => setShowModalReative({})}
                        textbutton="Reativar"
                        textbutton2="Cancelar"
                        nome={dado.nome}
                        id={dado.id}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {loadAgentes ? (
        <LoadComponent />
      ) : (
        <Pagination
          currentPage={currentPage}
          registersPerPage={linhasPerPage}
          textNoContent="Nenhum agente encontrado"
          onPageChange={setCurrentPage}
          totalCountOfRegisters={filtro.length}
        />
      )}
    </>
  )
}
