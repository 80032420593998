import Tooltip from '@mui/material/Tooltip'

interface LinesPerPagesProps {
  option?: string
  defaultValue: string | number | readonly string[]
  onChange: React.ChangeEventHandler<HTMLSelectElement>
}

export function LinesPerPages({ option, defaultValue, onChange }: LinesPerPagesProps) {
  return (
    <Tooltip title="Registros por página" placement="bottom" arrow>
      <div>
        <select name="numero col-12" defaultValue={defaultValue} onChange={onChange}>
          <option disabled>{option}</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
      </div>
    </Tooltip>
  )
}
