import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { BiImageAdd } from 'react-icons/bi'

interface InsertImageProps {
  readonly className: string
  readonly titleTooltip: string
  readonly classNameTooltip: string
  readonly onClickTooltip?: any
  readonly onClickLabelUpload?: any
  readonly typeInputUpload: string
  readonly onChangeInputUpload: any
  readonly sizeIcon: number
}

export default function InsertImage({
  className,
  titleTooltip,
  classNameTooltip,
  onClickTooltip,
  onClickLabelUpload,
  typeInputUpload,
  onChangeInputUpload,
  sizeIcon
}: InsertImageProps) {
  return (
    <div className={`opcaoImagem ${className}`}>
      <Tooltip title={titleTooltip} placement="bottom" arrow className={classNameTooltip} onClick={onClickTooltip}>
        <div>
          <label className="uparImagem" onClick={onClickLabelUpload}>
            <input
              className="selecionaImagem"
              type={typeInputUpload}
              name="images"
              onChange={onChangeInputUpload}
              accept="image/*"
            />
            <BiImageAdd size={sizeIcon} color="white" />
          </label>
        </div>
      </Tooltip>
    </div>
  )
}
