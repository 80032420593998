import api from './api'

// #region Controle de lote
export const DownloadLoteInfracao = async (idArquivo: number, valueTipoRelatorio: number) =>
  api.post(`Notificacao/download-lote-infracao?arquivoId=${idArquivo}&tipoRelatorio=${valueTipoRelatorio}`, '', {
    responseType: 'arraybuffer'
  })

export const AtualizaStatusLote = async (atualizacaoLote: {
  arquivoId: number
  dataPostagem: string
  codigoCorreio: string
}) => api.put('Postagem/atualiza-status-lote', atualizacaoLote)
// #endregion

// #region Gerar lote
export const LoteInfracao = async (dadosLote: {
  clienteId: number
  tipoNotificacaoId: number
  dataLimite: string
  infracoesId: any[]
  tipoRelatorio: number
}) =>
  api.post('Notificacao/lote-infracao', dadosLote, {
    responseType: 'arraybuffer'
  })
// #endregion

// #region NotificacaoContext
export const TiposRelatorios = async () => api.get('Enum/TiposRelatorio')
export const LotesGerados = async () => api.get('/Notificacao/lotes-gerados')
export const Infracao = async () => api.get(`/Notificacao/get-infracoes`)
export const TipoNotificacao = async (valueTipoNotificacao: number) =>
  api.get(`/Notificacao/get-infracoes?tipoNotificacao=${valueTipoNotificacao}`)
// #endregion
