import { createContext, ReactNode, useEffect, useState, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoginUser } from '../services/accountService'
import api from '../services/api'

interface AuthContextProps {
  Permissoes: any
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>
  permissions: string
  user: string
  clienteCode: number
  authenticated: boolean
  loading: boolean
  errorLoad: boolean
  modalShow: boolean
  serviceValue: number
  setServiceValue: React.Dispatch<React.SetStateAction<number>>
  loginUsuario: (userName: string, senha: string) => Promise<string>
  logout: () => void
}

interface AuthContextProviderProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextProps)

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [errorLoad, setErrorLoad] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [clienteCode, setClienteCode] = useState(0)
  const [Permissoes, setPermissoes] = useState([])
  const [permissions, setPermissions] = useState('')

  const [serviceValue, setServiceValue] = useState(0)

  useEffect(() => {
    const userRecovered = localStorage.getItem('user')
    const token = localStorage.getItem('token')
    const codigoCliente = Number(localStorage.getItem('codigoCliente'))

    if (userRecovered && token && codigoCliente) {
      setUser(userRecovered)
      setClienteCode(codigoCliente)
      api.defaults.headers['Authorization'] = `Bearer ${token}`
    }

    setLoading(false)
  }, [])

  const loginUsuario = useCallback(async (userName: string, senha: string) => {
    try {
      const response = await LoginUser(userName, senha)

      if (response.data.success === true) {
        const userLogged = userName
        const { token } = response.data.data.token
        const { codigoCliente } = response.data.data

        localStorage.setItem('user', userLogged)
        localStorage.setItem('token', token)
        localStorage.setItem('codigoCliente', codigoCliente)

        api.defaults.headers['Authorization'] = `Bearer ${token}`

        api
          .get('/Account/Permissoes')
          .then((response2) => {
            setPermissoes(response2.data.data)
            setPermissions(response2.data.data.toString())
          })
          .catch((error) => {
            console.log(error)
          })
        setUser(userLogged)
        setClienteCode(Number(codigoCliente))
        return 'sucesso'
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response.data.success === false) {
          return error.response.data.message
        }
        if (error.response.data.errors) {
          return error.response.data.errors.Senha
        }
      } else {
        setErrorLoad(true)
        setModalShow(true)
      }
    }
  }, [])

  useEffect(() => {
    api
      .get('/Account/Permissoes')
      .then((response) => {
        setPermissoes(response.data.data)
        setPermissions(response.data.data.toString())
      })
      .catch((error) => {
        console.log('error')
      })
  }, [user])

  const logout = useCallback(() => {
    localStorage.removeItem(user)
    localStorage.removeItem('token')
    localStorage.removeItem('clienteCode')

    api.defaults.headers['Authorization'] = null

    setUser(null)
    setClienteCode(0)
    navigate('/')
  }, [navigate, user])

  const contextValues = useMemo(
    () => ({
      authenticated: !!user,
      user,
      loading,
      loginUsuario,
      logout,
      errorLoad,
      modalShow,
      setModalShow,
      clienteCode,
      Permissoes,
      permissions,
      setServiceValue,
      serviceValue
    }),
    [Permissoes, clienteCode, errorLoad, loading, loginUsuario, logout, modalShow, permissions, user, serviceValue]
  )

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>
}
