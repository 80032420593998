import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { BsFillGearFill } from 'react-icons/bs'

interface TypeImageProps {
  readonly onClickTooltip?: any
  readonly optionTipoImagemPrincipal?: boolean
  readonly optionTipoImagensSecundarias?: any
  readonly onBlurOptions?: any
  readonly nameSelect: string
  readonly onChangeSelect: any
  readonly defaultValueSelect: any
  readonly messageRequiredTipoImagem?: boolean
  readonly arrayTiposImagem?: any[]
}

export default function TypeImage({
  onClickTooltip,
  optionTipoImagemPrincipal,
  optionTipoImagensSecundarias,
  onBlurOptions,
  nameSelect,
  onChangeSelect,
  defaultValueSelect,
  messageRequiredTipoImagem,
  arrayTiposImagem
}: TypeImageProps) {
  return (
    <>
      <div className="divTipoImagem">
        <div className="camposTipoImagem">
          <Tooltip
            title="Selecionar tipo de imagem"
            placement="top"
            onClick={onClickTooltip}
            arrow
            className="selectTipoImagem mt-1"
          >
            <div>
              <BsFillGearFill color="white" size={24} />
            </div>
          </Tooltip>

          {optionTipoImagemPrincipal || optionTipoImagensSecundarias ? (
            <div className="optionsTipoImagem mt-1" onBlur={onBlurOptions}>
              <div className="divSelecionaTipoImagem">
                <select
                  className="col-12"
                  name={nameSelect}
                  onChange={onChangeSelect}
                  defaultValue={defaultValueSelect}
                >
                  <option disabled>TIPO DE IMAGEM</option>
                  {arrayTiposImagem.map((tipoImagem) => (
                    <option key={tipoImagem.id} value={tipoImagem.id}>
                      {tipoImagem.descricao}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {messageRequiredTipoImagem ? <span className="messageTipoImagem">O tipo de imagem é obrigatório!</span> : null}
    </>
  )
}
