import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { InativarCorporacao } from 'services/corporacoesService'

import ModalMessage from '../../ModalMessage'

interface ModalInativacaoCorporacaoProps {
  readonly nome: string
  readonly show: boolean
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalInativacaoCorporacao({
  nome,
  show,
  id,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalInativacaoCorporacaoProps) {
  const { atualizaCorporacao } = useContext(DadosContext)

  const [successInativaCorporacao, setSuccessInativaCorporacao] = useState(false)
  const [falhaInativaCorporacao, setFalhaInativaCorporacao] = useState(false)
  const [messageModalInativaCorporacao, setMessageModalInativaCorporacao] = useState([])

  async function inativaCorporacao() {
    InativarCorporacao(id)
      .then((response) => {
        setMessageModalInativaCorporacao([response.data.message])
        setSuccessInativaCorporacao(true)
      })
      .catch((error) => {
        setMessageModalInativaCorporacao([error.response.data.message])
        setFalhaInativaCorporacao(true)
      })
  }

  return (
    <>
      {!successInativaCorporacao && !falhaInativaCorporacao && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja inativar a corporação {nome}?</h4>
            </div>

            <div className="buttonsModalInative">
              <Button onClick={inativaCorporacao} variant="none" className="buttonInative">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalInativaCorporacao}
        className="modalSuccess"
        show={successInativaCorporacao}
        onHide={() => [setSuccessInativaCorporacao(false), onClick(), atualizaCorporacao()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalInativaCorporacao}
        className="modalFalha"
        show={falhaInativaCorporacao}
        onHide={() => [setFalhaInativaCorporacao(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalInativacaoCorporacao
