import './ConfiguracoesCorporacao.css'
import Tooltip from '@mui/material/Tooltip'
import LoadComponent from 'components/loadComponent'
import { SearchForm } from 'components/SearchForm'
import { ListsContext } from 'contexts/ListsContext'
import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { MdAddBusiness, MdContentPasteOff, MdBusiness } from 'react-icons/md'
import { novaCorporacao } from 'services/corporacoesService'

import ButtonSubmit from '../../components/ButtonSubmit'
import ModalInativacaoCorporacao from '../../components/Modais/ModaisCorporacoes/ModalInativaCorporacao'
import ModalReativacaoCorporacao from '../../components/Modais/ModaisCorporacoes/ModalReativaCorporacao'
import ModalMessage from '../../components/Modais/ModalMessage'
import SideBar from '../../components/SideBarLateral'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'

export default function ConfiguracoesCorporacao() {
  const { classSideNav } = useContext(CondicionaisFormContext)

  const { Corporacao } = useContext(DadosContext)

  const [cadastraCorporacao, setCadastraCorporacao] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [tabelaCorporacoes, setTabelaCorporacoes] = useState(true)

  const [filterGeralCorporacao, setFilterGeralCorporacao] = useState('Todos')
  const [searchCorporacao, setSearchCorporacao] = useState('')
  const [filterSearchCorporacao, setFilterSearchCorporacao] = useState([])
  const [filterAtivas, setFilterAtivas] = useState([])
  const [filterInativas, setFilterInativas] = useState([])

  function ativaCadastroCorporacao() {
    setCadastraCorporacao(true)
    setTabelaCorporacoes(false)
    setSearchActive(false)
  }

  const ativaPesquisa = useCallback(() => {
    setSearchActive(!searchActive)
    setTabelaCorporacoes(true)
    setCadastraCorporacao(false)
  }, [searchActive])

  useEffect(() => {
    setFilterSearchCorporacao(Corporacao)
  }, [Corporacao])

  useEffect(() => {
    if (filterGeralCorporacao === 'Todos') {
      const FiltroGeral = Corporacao
      setFilterSearchCorporacao(FiltroGeral)
    } else if (filterGeralCorporacao === 'Ativos') {
      const FiltroGeral = Corporacao.filter((value) => !value.dataInativo)
      setFilterAtivas(FiltroGeral)
      setFilterSearchCorporacao(FiltroGeral)
    } else if (filterGeralCorporacao === 'Inativos') {
      const FiltroGeral = Corporacao.filter((value) => value.dataInativo)
      setFilterInativas(FiltroGeral)
      setFilterSearchCorporacao(FiltroGeral)
    }
  }, [filterGeralCorporacao, Corporacao])

  const onChangeBuscaCorporacao = (e) => {
    e.preventDefault()
    setSearchCorporacao(e.target.value)

    if (filterGeralCorporacao === 'Todos') {
      const Filtro = Corporacao.filter((value) => value.descricao.toLowerCase().includes(e.target.value.toLowerCase()))

      setFilterSearchCorporacao(Filtro)
    } else if (filterGeralCorporacao === 'Ativos') {
      const Filtro = filterAtivas.filter((value) =>
        value.descricao.toLowerCase().includes(e.target.value.toLowerCase())
      )

      setFilterSearchCorporacao(Filtro)
    } else if (filterGeralCorporacao === 'Inativos') {
      const Filtro = filterInativas.filter((value) =>
        value.descricao.toLowerCase().includes(e.target.value.toLowerCase())
      )

      setFilterSearchCorporacao(Filtro)
    }
  }

  useEffect(() => {
    if (searchCorporacao.length > 0) {
      ativaPesquisa()
    }
  }, [ativaPesquisa, searchCorporacao])

  return (
    <div className="content">
      <div className="sideNavLateral">
        <SideBar />
      </div>

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2>Configurações de Corporação</h2>

          <SearchForm
            defaultValue={filterGeralCorporacao}
            value={searchCorporacao}
            onChangeFilterGeral={(e) => setFilterGeralCorporacao(e.target.value)}
            onChangeBusca={onChangeBuscaCorporacao}
            placeholder="Pesquisar corporação"
            name="buscaCorporacao"
          >
            <div className="itemMenuNavegacao">
              {tabelaCorporacoes ? (
                <Tooltip title="Nova corporação" placement="bottom" arrow>
                  <div>
                    <MdAddBusiness size={25} onClick={ativaCadastroCorporacao} className="iconNavegacao" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                  <div>
                    <BiArrowBack
                      size={25}
                      onClick={() => [setTabelaCorporacoes(true), setCadastraCorporacao(false)]}
                      className="iconNavegacao"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </SearchForm>

          {tabelaCorporacoes && filterSearchCorporacao.length > 0 ? (
            <ListagemCorporacoes filtro={filterSearchCorporacao} />
          ) : tabelaCorporacoes ? (
            <div className="textNoContent">
              <h4 className="mt-3">Nenhuma corporação encontrada</h4>
            </div>
          ) : null}

          {cadastraCorporacao && <FormNovaCorporacao />}
        </div>
      </div>
    </div>
  )
}

function FormNovaCorporacao() {
  const { atualizaCorporacao } = useContext(DadosContext)

  const [successRegisterCorporacao, setSuccessRegisterCorporacao] = useState(false)
  const [falhaRegisterCorporacao, setFalhaRegisterCorporacao] = useState(false)
  const [messageModalRegisterCorporacao, setMessageModalRegisterCorporacao] = useState([])

  const { register, handleSubmit, reset } = useForm()

  const formCriaCorporacao = (data) => {
    novaCorporacao(data.nomeCorporacao)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalRegisterCorporacao([response.data.message])
          setSuccessRegisterCorporacao(true)
          atualizaCorporacao()
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalRegisterCorporacao([error.response.data.message])
            setFalhaRegisterCorporacao(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalRegisterCorporacao(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaRegisterCorporacao(true)
          }
        }
      })
  }
  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4" onSubmit={handleSubmit(formCriaCorporacao)} autoComplete="off">
        <div className="subtitulo mb-2">
          <h6 className="mt-1">Cadastrar nova corporação</h6>
        </div>

        <div className="formCriaRegistro">
          <div className="labelForm col-12">
            <input
              type="text"
              className="form-control"
              name="nomeCorporacao"
              id="nomeCorporacao"
              placeholder="Nome"
              {...register('nomeCorporacao')}
            />
            <label htmlFor="nomeCorporacao">Nome</label>
          </div>

          <div className="buttonCriaRegistro col-12">
            <ButtonSubmit type="submit" text="Cadastrar" />
          </div>

          <ModalMessage
            title={messageModalRegisterCorporacao}
            className="modalSuccess"
            show={successRegisterCorporacao}
            onHide={() => [setSuccessRegisterCorporacao(false), reset()]}
            textbutton="Voltar ao registro de Corporação"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalRegisterCorporacao}
            className="modalFalha"
            show={falhaRegisterCorporacao}
            onHide={() => setFalhaRegisterCorporacao(false)}
            textbutton="Tentar novamente"
            textbutton2="Ir para o ínicio"
          />
        </div>
      </form>
    </div>
  )
}
interface ListagemCorporacoesProps {
  readonly filtro: any[]
}

function ListagemCorporacoes({ filtro }: ListagemCorporacoesProps) {
  const {
    showIcon,
    setShowIcon,
    messageDataInativo,
    showModalInvalid,
    setShowModalInvalid,
    showModalReative,
    setShowModalReative,
    mostraIcon,
    mostraInativacao,
    mostraReativacao,
    ativaMessageDataInativo
  } = useContext(ListsContext)

  const { loadCorporacoes } = useContext(DadosContext)

  return (
    <>
      <div className="tableDefault container">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela">
                <span>Nome da corporação</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filtro.map((dado, valor) => {
              const anoData = dado.dataInativo?.slice(0, 4)
              const mesData = dado.dataInativo?.slice(5, 7)
              const diaData = dado.dataInativo?.slice(8, 10)

              const data = `${diaData}/${mesData}/${anoData}`
              const dataFinal = data === 'undefined/undefined/undefined' ? null : data

              return (
                <tr key={dado.id} className="linhaDefault">
                  <td
                    className="nomeCorporacao"
                    onMouseEnter={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onMouseLeave={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onClick={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                  >
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.descricao}
                    </div>

                    {showIcon[valor] && dado.dataInativo === null && (
                      <IconsModifyCorporacao isInativo={false} onClickInative={mostraInativacao(valor)} />
                    )}

                    {messageDataInativo[valor] && (
                      <IconsModifyCorporacao isInativo onClickReative={mostraReativacao(valor)} dataFinal={dataFinal} />
                    )}
                  </td>

                  {showModalInvalid[valor] && (
                    <td className="modalInvalid">
                      <ModalInativacaoCorporacao
                        show={!!showModalInvalid}
                        onHide={() => [setShowModalInvalid({}), setShowIcon({})]}
                        onClick={() => setShowModalInvalid({})}
                        textbutton="Inativar"
                        textbutton2="Cancelar"
                        nome={dado.descricao}
                        id={dado.id}
                      />
                    </td>
                  )}

                  {showModalReative[valor] && (
                    <td className="modalInvalid">
                      <ModalReativacaoCorporacao
                        show={!!showModalReative}
                        onHide={() => [setShowModalReative({}), setShowIcon({})]}
                        onClick={() => setShowModalReative({})}
                        textbutton="Reativar"
                        textbutton2="Cancelar"
                        nome={dado.descricao}
                        id={dado.id}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {loadCorporacoes && <LoadComponent />}
    </>
  )
}

interface IconsModifyCorporacaoProps {
  isInativo: boolean
  onClickInative?: MouseEventHandler<any>
  onClickReative?: MouseEventHandler<any>
  dataFinal?: string
}

function IconsModifyCorporacao({ isInativo, onClickInative, onClickReative, dataFinal }: IconsModifyCorporacaoProps) {
  return !isInativo ? (
    <div className="iconsModificacao">
      <Tooltip title="Inativar" placement="top" arrow>
        <div>
          <MdContentPasteOff size={32} className="iconInative" onClick={onClickInative} />
        </div>
      </Tooltip>
    </div>
  ) : (
    <div className="divOptionReativacao">
      <span className="messageInativo">Inativado em: {dataFinal}</span>
      <Tooltip title="Reativar" placement="top" arrow>
        <div>
          <MdBusiness size={32} className="iconReativar" onClick={onClickReative} />
        </div>
      </Tooltip>
    </div>
  )
}
