import { Pagination as BsPagination } from 'react-bootstrap'

import './styles.css'

interface PaginationProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  textNoContent: string
  onPageChange: (page: number) => void
}

const siblingsCount = 2

function generatePagesArray(from: number, to: number) {
  return [...new Array(to - from)].map((_, index) => from + index + 1).filter((page) => page > 0)
}

export function Pagination({
  totalCountOfRegisters,
  registersPerPage = 25,
  currentPage = 1,
  textNoContent,
  onPageChange
}: PaginationProps) {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)

  const previousPages = currentPage > 1 ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1) : []

  const nextPages =
    currentPage < lastPage ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage)) : []

  const registrosIniciais = registersPerPage * currentPage - registersPerPage + 1

  const registrosTotais = registersPerPage * currentPage

  return (
    <div className="box">
      {totalCountOfRegisters === 0 ? (
        <div className="fieldText">
          <h4 className="mt-3">{textNoContent}</h4>
        </div>
      ) : null}

      {totalCountOfRegisters > registersPerPage ? (
        <>
          <div className="contentTotalRegistros">
            <strong>{registrosIniciais}</strong> -{' '}
            <strong>{registrosTotais < totalCountOfRegisters ? registrosTotais : totalCountOfRegisters}</strong> de{' '}
            <strong>{totalCountOfRegisters}</strong>
          </div>
          <BsPagination>
            <BsPagination.First disabled={!(currentPage > 1)} onClick={() => onPageChange(1)} />
            <BsPagination.Prev disabled={!(currentPage > 1)} onClick={() => onPageChange(currentPage - 1)} />

            {currentPage > 1 + siblingsCount ? (
              <>
                <BsPagination.Item onClick={() => onPageChange(1)}>{1}</BsPagination.Item>

                {currentPage > 1 + siblingsCount ? <BsPagination.Ellipsis /> : null}
              </>
            ) : null}

            {previousPages.length > 0
              ? previousPages.map((page) => (
                  <BsPagination.Item key={page} onClick={() => onPageChange(page)}>
                    {page}
                  </BsPagination.Item>
                ))
              : null}

            <BsPagination.Item disabled={!(currentPage < 1)} onClick={() => onPageChange(currentPage - 1)} active>
              {currentPage}
            </BsPagination.Item>

            {nextPages.length > 0
              ? nextPages.map((page) => (
                  <BsPagination.Item key={page} onClick={() => onPageChange(page)}>
                    {page}
                  </BsPagination.Item>
                ))
              : null}

            {currentPage + siblingsCount < lastPage ? (
              <>
                {currentPage + 1 + siblingsCount < lastPage ? <BsPagination.Ellipsis /> : null}

                <BsPagination.Item onClick={() => onPageChange(lastPage)}>{lastPage}</BsPagination.Item>
              </>
            ) : null}

            <BsPagination.Next disabled={!(currentPage < lastPage)} onClick={() => onPageChange(currentPage + 1)} />
            <BsPagination.Last disabled={!(currentPage < lastPage)} onClick={() => onPageChange(lastPage)} />
          </BsPagination>
        </>
      ) : null}
    </div>
  )
}
