import './styles.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import FormTabelaEdicaoCondutor from 'components/FormTabelaEdicaoCondutor'
import FormTabelaEdicaoProprietarios from 'components/FormTabelaEdicaoProprietarios'
import ModalAdicionaCondutor from 'components/Modais/ModaisCondutores/ModalAdicionaCondutor'
import ModalAdicionaProprietario from 'components/Modais/ModaisProprietarios/ModalAdicionaProprietario'
import ModalMessage from 'components/Modais/ModalMessage'
import Timeline from 'components/Timeline'
import { CondicionaisFormContext } from 'contexts/CondicionaisFormContext'
import { DadosContext } from 'contexts/DadosContext'
import { PermissionariosContext } from 'contexts/PermissionariosContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { CadastroPermissionario, EdicaoPermissionario, PesquisaVeiculoPorPlaca } from 'services/permissionariosService'

function NovoPermissionario() {
  const {
    proprietariosSelecionados,
    idProprietarioAtual,
    condutoresSelecionados,
    setProprietariosSelecionados,
    setCondutoresSelecionados,
    atualizaPermissionarios
  } = useContext(DadosContext)

  const { ehAtualizacao, setEhAtualizacao, idVeiculoSelecionado, setIdVeiculoSelecionado, condutoresInativos } =
    useContext(PermissionariosContext)

  const { voltaTabela } = useContext(CondicionaisFormContext)

  const { activeStep, handleComplete, setActiveStep } = useContext(PermissionariosContext)

  const { register } = useForm()

  const [placaNovoPermissionario, setPlacaNovoPermissionario] = useState('')
  const [renavamNovoPermissionario, setRenavamNovoPermissionario] = useState('')
  const [prefixoNovoPermissionario, setPrefixoNovoPermissionario] = useState('')
  const [anoLicenciamentoNovoPermissionario, setAnoLicenciamentoNovoPermissionario] = useState('')
  const [anoModeloNovoPermissionario, setAnoModeloNovoPermissionario] = useState('')
  const [marcaModeloNovoPermissionario, setMarcaModeloNovoPermissionario] = useState('')
  const [tipoServicoNovoPermissionario, setTipoServicoNovoPermissionario] = useState('')
  const [chassiNovoPermissionario, setChassiNovoPermissionario] = useState('')
  const [corNovoPermissionario, setCorNovoPermissionario] = useState('')

  const [modalAdicionaProprietario, setModalAdicionaProprietario] = useState(false)
  const [modalAdicionaCondutor, setModalAdicionaCondutor] = useState(false)

  const [successCadastroPermissionario, setSuccessCadastroPermissionario] = useState(false)
  const [falhaCadastroPermissionario, setFalhaCadastroPermissionario] = useState(false)
  const [messageModalCadastroPermissionario, setMessageModalCadastroPermissionario] = useState([])

  const [successAtualizaPermissionario, setSuccessAtualizaPermissionario] = useState(false)
  const [falhaAtualizaPermissionario, setFalhaAtualizaPermissionario] = useState(false)
  const [messageModalAtualizaPermissionario, setMessageModalAtualizaPermissionario] = useState([])

  useEffect(() => {
    if (!placaNovoPermissionario) {
      setIdVeiculoSelecionado(0)
      setRenavamNovoPermissionario('')
      setPrefixoNovoPermissionario('')
      setAnoLicenciamentoNovoPermissionario('')
      setAnoModeloNovoPermissionario('')
      setMarcaModeloNovoPermissionario('')
      setTipoServicoNovoPermissionario('')
      setChassiNovoPermissionario('')
      setCorNovoPermissionario('')
    }
  }, [placaNovoPermissionario, setIdVeiculoSelecionado])

  async function getPlacaNovoPermissionario() {
    if (placaNovoPermissionario.length > 0) {
      const response = await PesquisaVeiculoPorPlaca(placaNovoPermissionario)

      const veiculo = response.data.data

      if (veiculo !== null) {
        setIdVeiculoSelecionado(veiculo.id)
        setRenavamNovoPermissionario(veiculo.renavam)
        setPrefixoNovoPermissionario(veiculo.prefixo)
        setAnoLicenciamentoNovoPermissionario(veiculo.anoLicenciamento)
        setAnoModeloNovoPermissionario(veiculo.anoModelo)
        setMarcaModeloNovoPermissionario(veiculo.marcaModelo.descricao)
        setTipoServicoNovoPermissionario(veiculo.tipoServico)
        setChassiNovoPermissionario(veiculo.numeroChassi)
        setCorNovoPermissionario(veiculo.cor.descricao)
      } else {
        setIdVeiculoSelecionado(0)
        setRenavamNovoPermissionario('')
        setPrefixoNovoPermissionario('')
        setAnoLicenciamentoNovoPermissionario('')
        setAnoModeloNovoPermissionario('')
        setMarcaModeloNovoPermissionario('')
        setTipoServicoNovoPermissionario('')
        setChassiNovoPermissionario('')
        setCorNovoPermissionario('')
      }
    }
  }

  function enviaCadastroPermissionario() {
    CadastroPermissionario(idVeiculoSelecionado, condutoresSelecionados, Number(proprietariosSelecionados))
      .then((response) => {
        if (response.status === 200) {
          setSuccessCadastroPermissionario(true)
          setMessageModalCadastroPermissionario([response.data.message])
          atualizaPermissionarios()
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          setFalhaCadastroPermissionario(true)
          setMessageModalCadastroPermissionario([error.data.message])
        } else {
          const mensagensErro = Object.values(error.response.data.errors)
          setMessageModalCadastroPermissionario(mensagensErro.map((mensagens) => mensagens.toString()))
          setFalhaCadastroPermissionario(true)
        }
      })
  }

  function atualizaCadastroPermissionario() {
    EdicaoPermissionario(
      idVeiculoSelecionado,
      condutoresSelecionados,
      proprietariosSelecionados,
      idProprietarioAtual,
      condutoresInativos.length > 0 ? condutoresInativos : []
    )
      .then((response) => {
        if (response.status === 200) {
          setSuccessAtualizaPermissionario(true)
          setMessageModalAtualizaPermissionario([response.data.message])
          atualizaPermissionarios()
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          setFalhaAtualizaPermissionario(true)
          setMessageModalAtualizaPermissionario([error.response.data.message])
        } else {
          const mensagensErro = Object.values(error.response.data.errors)
          setMessageModalAtualizaPermissionario(mensagensErro.map((mensagens) => mensagens.toString()))
          setFalhaAtualizaPermissionario(true)
        }
      })
  }

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto'
      }}
    >
      <Timeline
        className={idVeiculoSelecionado !== 0 ? 'timeLine mt-5' : ''}
        onClickStepOne={() => (idVeiculoSelecionado !== 0 ? handleComplete(0) : null)}
        onClickStepTwo={() => (idVeiculoSelecionado !== 0 ? handleComplete(1) : null)}
        onClickStepThree={() =>
          idVeiculoSelecionado !== 0 && proprietariosSelecionados.length > 0 ? handleComplete(2) : null
        }
        classNameLastStep={proprietariosSelecionados.length > 0 ? '' : 'etapaNaoClicavel'}
      />

      <hr className="divisor" />

      <div className="configuraVeiculo">
        <Box sx={{ mt: 2, mb: 1, py: 1, width: `${activeStep === 0 ? '100%' : '85%'}`, margin: '0 auto' }}>
          {activeStep === 0 && (
            <div className="form-talao">
              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="placaPermissionario"
                  id="placaPermissionario"
                  className="form-control"
                  placeholder="Placa"
                  value={placaNovoPermissionario}
                  onChange={(e) => setPlacaNovoPermissionario(e.target.value)}
                  onBlur={getPlacaNovoPermissionario}
                />
                <label htmlFor="placaPermissionario"> Placa </label>
              </div>

              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="renavamPermissionario"
                  id="renavamPermissionario"
                  className="form-control"
                  placeholder="Renavam"
                  {...register('renavamPermissionario')}
                  value={renavamNovoPermissionario}
                  readOnly
                  disabled
                />
                <label htmlFor="renavamPermissionario"> Renavam </label>
              </div>

              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="prefixoNovoPermissionario"
                  id="prefixoNovoPermissionario"
                  className="form-control"
                  placeholder="Prefixo"
                  {...register('prefixoNovoPermissionario')}
                  value={prefixoNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="prefixoNovoPermissionario"> Prefixo </label>
              </div>

              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="anoLicenciamentoPermissionario"
                  id="anoLicenciamentoPermissionario"
                  className="form-control"
                  placeholder="Ano de licenciamento"
                  {...register('anoLicenciamentoPermissionario')}
                  value={anoLicenciamentoNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="anoLicenciamentoPermissionario"> Ano de licenciamento </label>
              </div>

              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="anoModeloPermissionario"
                  id="anoModeloPermissionario"
                  className="form-control"
                  placeholder="Ano de modelo"
                  {...register('anoModeloPermissionario')}
                  value={anoModeloNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="anoModeloPermissionario"> Ano de modelo </label>
              </div>

              <div className="labelForm col-6 col-md-4">
                <input
                  type="text"
                  name="marcaModeloPermissionario"
                  id="marcaModeloPermissionario"
                  className="form-control"
                  placeholder="Marca/Modelo"
                  {...register('marcaModeloPermissionario')}
                  value={marcaModeloNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="marcaModeloPermissionario"> Marca/Modelo </label>
              </div>

              <div className="labelForm col-6 col-md-3">
                <input
                  type="text"
                  name="especiePermissionario"
                  id="especiePermissionario"
                  className="form-control"
                  placeholder="Espécie"
                  {...register('especiePermissionario')}
                  readOnly
                  disabled
                />
                <label htmlFor="especiePermissionario"> Espécie </label>
              </div>

              <div className="labelForm col-6 col-md-3">
                <input
                  type="text"
                  name="tipoServicoPermissionario"
                  id="tipoServicoPermissionario"
                  className="form-control"
                  placeholder="Tipo de serviço"
                  {...register('tipoServicoPermissionario')}
                  value={tipoServicoNovoPermissionario || ''}
                  disabled
                  readOnly
                />
                <label htmlFor="tipoServicoPermissionario"> Tipo de serviço </label>
              </div>

              <div className="labelForm col-6 col-md-3">
                <input
                  type="text"
                  name="chassiPermissionario"
                  id="chassiPermissionario"
                  className="form-control"
                  placeholder="Chassi"
                  maxLength={8}
                  {...register('chassiPermissionario')}
                  value={chassiNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="chassiPermissionario"> Chassi </label>
              </div>

              <div className="labelForm col-6 col-md-3">
                <input
                  type="text"
                  name="corPermissionario"
                  id="corPermissionario"
                  className="form-control"
                  placeholder="Cor"
                  {...register('corPermissionario')}
                  value={corNovoPermissionario || ''}
                  readOnly
                  disabled
                />
                <label htmlFor="corPermissionario"> Cor </label>
              </div>
            </div>
          )}

          {activeStep === 1 && proprietariosSelecionados.length > 0 && <FormTabelaEdicaoProprietarios />}

          {activeStep === 2 && condutoresSelecionados.length > 0 && <FormTabelaEdicaoCondutor />}

          <ModalAdicionaProprietario
            show={modalAdicionaProprietario}
            textbutton="Adicionar"
            textbutton2="Cancelar"
            onHide={() => setModalAdicionaProprietario(false)}
          />

          <ModalAdicionaCondutor
            show={modalAdicionaCondutor}
            textbutton="Adicionar"
            textbutton2="Cancelar"
            onHide={() => setModalAdicionaCondutor(false)}
          />

          <ModalMessage
            title={messageModalCadastroPermissionario}
            className="modalSuccess"
            show={successCadastroPermissionario}
            onHide={() => [setSuccessCadastroPermissionario(false), voltaTabela(), setActiveStep(0)]}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalCadastroPermissionario}
            className="modalFalha"
            show={falhaCadastroPermissionario}
            onHide={() => setFalhaCadastroPermissionario(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAtualizaPermissionario}
            className="modalSuccess"
            show={successAtualizaPermissionario}
            onHide={() => [setSuccessAtualizaPermissionario(false), voltaTabela(), setActiveStep(0)]}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAtualizaPermissionario}
            className="modalFalha"
            show={falhaAtualizaPermissionario}
            onHide={() => setFalhaAtualizaPermissionario(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 2,
            width: '77%',
            margin: '0 auto'
          }}
        >
          <div className="iconVoltar mb-5">
            <Tooltip title="Voltar a consulta" placement="bottom" arrow>
              <div>
                <BiArrowBack
                  size={25}
                  onClick={() => [
                    voltaTabela(),
                    setActiveStep(0),
                    setProprietariosSelecionados([]),
                    setCondutoresSelecionados([]),
                    setEhAtualizacao(false)
                  ]}
                  className="iconNavegacao"
                />
              </div>
            </Tooltip>
          </div>

          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === 1 && proprietariosSelecionados.length === 0 ? (
            <Button onClick={() => setModalAdicionaProprietario(true)} className="buttonChangeProprietario">
              Adicionar proprietário
            </Button>
          ) : activeStep === 1 && ehAtualizacao ? (
            <>
              <Button onClick={() => setModalAdicionaProprietario(true)} className="buttonChangeProprietario">
                Adicionar proprietário
              </Button>
              <Button onClick={() => handleComplete(activeStep + 1)} className="buttonChangeProprietario">
                Próximo
              </Button>
            </>
          ) : activeStep === 2 ? (
            <Button onClick={() => setModalAdicionaCondutor(true)} className="buttonChangeProprietario">
              Adicionar condutor
            </Button>
          ) : activeStep !== 2 ? (
            <Button
              onClick={() => handleComplete(activeStep + 1)}
              className="buttonChangeProprietario"
              disabled={idVeiculoSelecionado === 0}
            >
              Próximo
            </Button>
          ) : null}
          {activeStep === 2 && (
            <Button
              onClick={ehAtualizacao ? atualizaCadastroPermissionario : enviaCadastroPermissionario}
              className="buttonChangeProprietario"
            >
              {ehAtualizacao ? 'Atualizar' : 'Salvar'}
            </Button>
          )}
        </Box>
      </div>
    </Box>
  )
}

export default NovoPermissionario
