import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ReativarUsuario } from 'services/accountService'

import ModalMessage from '../../ModalMessage'

interface ModalReativacaoUsuarioProps {
  readonly username: string
  readonly className?: string
  readonly show: boolean
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalReativacaoUsuario({
  username,
  id,
  show,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalReativacaoUsuarioProps) {
  const { atualizaUsuarios } = useContext(DadosContext)

  const [successReativaUsuario, setSuccessReativaUsuario] = useState(false)
  const [falhaReativaUsuario, setFalhaReativaUsuario] = useState(false)
  const [messageModalReativaUsuario, setMessageModalReativaUsuario] = useState([])

  async function reativaUsuario() {
    ReativarUsuario(id)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalReativaUsuario([response.data.message])
          setSuccessReativaUsuario(true)
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalReativaUsuario([error.response.data.message])
            setFalhaReativaUsuario(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalReativaUsuario(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaReativaUsuario(true)
          }
        }
      })
  }

  return (
    <>
      {!successReativaUsuario && !falhaReativaUsuario && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja reativar o usuário {username}?</h4>
            </div>

            <div className="buttonsModalEdit">
              <Button onClick={reativaUsuario} variant="none" className="buttonSave">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={[messageModalReativaUsuario]}
        className="modalSuccess"
        show={successReativaUsuario}
        onHide={() => [setSuccessReativaUsuario(false), onClick(), atualizaUsuarios()]}
        textbutton="OK"
      />

      <ModalMessage
        title={[messageModalReativaUsuario]}
        className="modalFalha"
        show={falhaReativaUsuario}
        onHide={() => [setFalhaReativaUsuario(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalReativacaoUsuario
