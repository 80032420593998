import { createContext, MouseEventHandler, ReactNode, useMemo, useState } from 'react'

interface ListsContextProps {
  showIcon?: object
  messageDataInativo?: object
  showModalEdit?: object
  showModalInvalid?: object
  showModalReative?: object
  setShowIcon?: React.Dispatch<React.SetStateAction<object>>
  setMessageDataInativo?: React.Dispatch<React.SetStateAction<object>>
  setShowModalEdit?: React.Dispatch<React.SetStateAction<object>>
  setShowModalInvalid?: React.Dispatch<React.SetStateAction<object>>
  setShowModalReative?: React.Dispatch<React.SetStateAction<object>>
  mostraIcon?: (valor: number) => MouseEventHandler<any>
  ativaMessageDataInativo?: (valor: number) => MouseEventHandler<any>
  mostraEdicao?: (valor: number) => MouseEventHandler<any>
  mostraInativacao?: (valor: number) => MouseEventHandler<any>
  mostraReativacao?: (valor: number) => MouseEventHandler<any>
}

interface ListsContextProviderProps {
  children: ReactNode
}

export const ListsContext = createContext({} as ListsContextProps)

export function ListsContextProvider({ children }: ListsContextProviderProps) {
  const [showIcon, setShowIcon] = useState({})

  const [messageDataInativo, setMessageDataInativo] = useState({})

  const [showModalEdit, setShowModalEdit] = useState({})
  const [showModalInvalid, setShowModalInvalid] = useState({})
  const [showModalReative, setShowModalReative] = useState({})

  const mostraIcon = (valor) => () => {
    setShowIcon((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const ativaMessageDataInativo = (valor) => () => {
    setMessageDataInativo((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const mostraEdicao = (valor) => () => {
    setShowModalEdit((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const mostraInativacao = (valor) => () => {
    setShowModalInvalid((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const mostraReativacao = (valor) => () => {
    setShowModalReative((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  const contextValues = useMemo(
    () => ({
      showIcon,
      setShowIcon,
      messageDataInativo,
      setMessageDataInativo,
      showModalEdit,
      setShowModalEdit,
      showModalInvalid,
      setShowModalInvalid,
      showModalReative,
      setShowModalReative,
      mostraIcon,
      mostraEdicao,
      ativaMessageDataInativo,
      mostraInativacao,
      mostraReativacao
    }),
    [messageDataInativo, showIcon, showModalEdit, showModalInvalid, showModalReative]
  )

  return <ListsContext.Provider value={contextValues}>{children}</ListsContext.Provider>
}
