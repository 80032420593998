import React, { InputHTMLAttributes, ReactNode, useState } from 'react'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  children?: ReactNode
  className?: string
  sizeIcon?: number
}

export function PasswordInput({ value, onChange, label, children, className, sizeIcon, ...rest }: PasswordInputProps) {
  const [typeSenha, setTypeSenha] = useState('password')
  const [ocultaSenha, setOcultaSenha] = useState(true)

  function showSenha() {
    setTypeSenha('text')
    setOcultaSenha(false)
  }

  function hideSenha() {
    setTypeSenha('password')
    setOcultaSenha(true)
  }

  return (
    <div className={className}>
      <input
        type={typeSenha}
        id={rest.id}
        name={rest.name}
        value={value}
        className="form-control"
        onChange={onChange}
        placeholder={label}
      />
      <label htmlFor={rest.id}>{label}</label>
      {children || null}
      <div className="buttonHideSenha col-1">
        {ocultaSenha ? (
          <BsFillEyeFill size={sizeIcon || 20} onClick={showSenha} />
        ) : (
          <BsFillEyeSlashFill size={sizeIcon || 20} onClick={hideSenha} />
        )}
      </div>
    </div>
  )
}
