import { ErrorForm } from 'components/FormTalao/ErrorForm'
import ModalMessage from 'components/Modais/ModalMessage'
import { DadosContext } from 'contexts/DadosContext'
import { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import { edicaoProprietario } from 'services/proprietarioService'

interface ModalEdicaoProprietarioProps {
  readonly id: number
  readonly municipioId: number
  readonly municipio: string
  readonly nome: string
  readonly show: boolean
  readonly cpf: string
  readonly endereco: string
  readonly numeroEndereco: string
  readonly complemento: string
  readonly cep: string
  readonly bairro: string
  readonly estado: string
  readonly email: string
  readonly celular: string
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalEdicaoProprietario({
  id,
  nome,
  endereco,
  numeroEndereco,
  complemento,
  cep,
  bairro,
  estado,
  municipio,
  municipioId,
  email,
  celular,
  cpf,
  show,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalEdicaoProprietarioProps) {
  const [nomeEditadoProprietario, setNomeEditadoProprietario] = useState(nome)
  const [enderecoEditadoProprietario, setEnderecoEditadoProprietario] = useState(endereco)
  const [numeroEnderecoEditadoProprietario, setNumeroEnderecoEditadoProprietario] = useState(numeroEndereco)
  const [cepEditadoProprietario, setCepEditadoProprietario] = useState(cep)
  const [complementoEditadoProprietario, setComplementoEditadoProprietario] = useState(complemento)
  const [bairroEditadoProprietario, setBairroEditadoProprietario] = useState(bairro)
  const [estadoEditadoProprietario] = useState(estado)
  const [idMunicipioEditadoProprietario] = useState(municipioId)
  const [municipioEditadoProprietario] = useState(municipio)
  const [emailEditadoProprietario, setEmailEditadoProprietario] = useState(email)
  const [celularEditadoProprietario, setCelularEditadoProprietario] = useState(celular)

  const [successEditProprietario, setSuccessEditProprietario] = useState(false)
  const [falhaEditProprietario, setFalhaEditProprietario] = useState(false)
  const [messageModalEditProprietario, setMessageModalEditProprietario] = useState([])

  const {
    atualizaProprietarios,
    telefoneEdicaoProprietarioValue,
    setTelefoneEdicaoProprietarioValue,
    inputTelefoneEdicaoProprietarioMask,
    cepEdicaoProprietarioValue,
    setCepEdicaoProprietarioValue,
    inputCepEdicaoProprietarioMask,
    textMunicipio,
    setTextMunicipio,
    onChangeMunicipio,
    filterMunicipio,
    selecionaMunicipio,
    idMunicipio,
    setIdMunicipio,
    uf,
    setUf
  } = useContext(DadosContext)

  useEffect(() => {
    if (celularEditadoProprietario !== '') {
      setTelefoneEdicaoProprietarioValue(celularEditadoProprietario)
    }
    if (cepEditadoProprietario !== '') {
      setCepEdicaoProprietarioValue(cepEditadoProprietario)
    }
    if (municipioEditadoProprietario !== '') {
      setTextMunicipio(municipioEditadoProprietario)
    }
    if (idMunicipioEditadoProprietario !== 0) {
      setIdMunicipio(idMunicipioEditadoProprietario)
    }
    if (estadoEditadoProprietario !== '') {
      setUf(estadoEditadoProprietario)
    }
  }, [
    celularEditadoProprietario,
    cepEditadoProprietario,
    estadoEditadoProprietario,
    municipioEditadoProprietario,
    idMunicipioEditadoProprietario,
    setTelefoneEdicaoProprietarioValue,
    setCepEdicaoProprietarioValue,
    setTextMunicipio,
    setIdMunicipio,
    setUf
  ])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({})

  const formAtualizaProprietario = () => {
    edicaoProprietario(
      id,
      nomeEditadoProprietario,
      enderecoEditadoProprietario,
      numeroEnderecoEditadoProprietario,
      complementoEditadoProprietario,
      cepEditadoProprietario.length > 0 ? cepEditadoProprietario : cepEdicaoProprietarioValue,
      bairroEditadoProprietario,
      estadoEditadoProprietario.length > 0 ? estadoEditadoProprietario : uf,
      idMunicipio,
      emailEditadoProprietario,
      celularEditadoProprietario.length > 0 ? celularEditadoProprietario : telefoneEdicaoProprietarioValue
    )
      .then((response) => {
        if (response.status === 200) {
          setMessageModalEditProprietario([response.data.message])
          setSuccessEditProprietario(true)
          atualizaProprietarios()
          clearInputsControlados()
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalEditProprietario([error.response.data.message])
            setFalhaEditProprietario(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalEditProprietario(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaEditProprietario(true)
          }
        }
      })
  }

  async function clearInputsControlados() {
    setTextMunicipio('')
    setUf('')
    setCepEdicaoProprietarioValue('')
    setTelefoneEdicaoProprietarioValue('')
  }

  return (
    <>
      {!successEditProprietario && !falhaEditProprietario && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="headerModalEdit">
              <h4>Editar Proprietário</h4>
            </div>

            <div className="bodyModalEdit">
              <form className="formEdicao" onSubmit={handleSubmit(formAtualizaProprietario)}>
                <div className="editaProprietario">
                  <div className="labelForm col-11">
                    <input
                      type="text"
                      name="nomeEdicaoProprietario"
                      placeholder="Nome"
                      className="form-control"
                      id="nomeEdicaoProprietario"
                      {...register('nomeEdicaoProprietario')}
                      value={nomeEditadoProprietario}
                      onChange={(e) => setNomeEditadoProprietario(e.target.value)}
                      required
                    />
                    <label htmlFor="nomeEdicaoProprietario">Nome</label>
                  </div>

                  <div className="labelForm col-11 col-md-6">
                    <input
                      type="text"
                      name="cpfEdicaoProprietario"
                      placeholder="CPF/CNPJ"
                      className="form-control"
                      id="cpfEdicaoProprietario"
                      defaultValue={cpf}
                      readOnly
                      disabled
                    />
                    <label htmlFor="cpfEdicaoProprietario">CPF/CNPJ</label>
                  </div>

                  <div className="labelForm col-11 col-md-5">
                    <input
                      type="text"
                      name="emailEdicaoProprietario"
                      placeholder="E-mail"
                      className="form-control"
                      id="emailEdicaoProprietario"
                      value={emailEditadoProprietario}
                      onChange={(e) => setEmailEditadoProprietario(e.target.value)}
                    />
                    <label htmlFor="emailEdicaoProprietario">E-mail</label>
                  </div>

                  <div className="labelForm col-11 col-md-6">
                    <input
                      type="text"
                      name="celularEdicaoProprietario"
                      placeholder="Celular"
                      className="form-control"
                      id="celularEdicaoProprietario"
                      {...register('celularEdicaoProprietario')}
                      value={telefoneEdicaoProprietarioValue}
                      onChange={inputTelefoneEdicaoProprietarioMask}
                      onBlur={() => setCelularEditadoProprietario(telefoneEdicaoProprietarioValue)}
                    />
                    <label htmlFor="celularEdicaoProprietario">Celular</label>
                  </div>

                  <div className="labelForm col-11 col-md-5">
                    <input
                      type="text"
                      name="cepEdicaoProprietario"
                      placeholder="Endereço"
                      className="form-control"
                      id="cepEdicaoProprietario"
                      value={cepEdicaoProprietarioValue}
                      onChange={inputCepEdicaoProprietarioMask}
                      onBlur={() => setCepEditadoProprietario(cepEdicaoProprietarioValue)}
                      required
                    />
                    <label htmlFor="cepEdicaoProprietario">CEP</label>
                  </div>

                  <div className="labelForm col-11 col-md-8">
                    <input
                      type="text"
                      name="enderecoEdicaoProprietario"
                      placeholder="Endereço"
                      className="form-control"
                      id="enderecoEdicaoProprietario"
                      value={enderecoEditadoProprietario}
                      onChange={(e) => setEnderecoEditadoProprietario(e.target.value)}
                      required
                    />
                    <label htmlFor="enderecoEdicaoProprietario">Endereço</label>
                  </div>

                  <div className="labelForm col-11 col-md-3">
                    <input
                      type="text"
                      name="numeroEnderecoEdicaoProprietario"
                      placeholder="Nº"
                      className="form-control"
                      id="numeroEnderecoEdicaoProprietario"
                      value={numeroEnderecoEditadoProprietario}
                      onChange={(e) => setNumeroEnderecoEditadoProprietario(e.target.value)}
                    />
                    <label htmlFor="numeroEnderecoEdicaoProprietario">Nº</label>
                  </div>

                  <div className="labelForm col-6">
                    <input
                      type="text"
                      className="municipios form-control"
                      name="municipioEdicaoProprietario"
                      id="municipioEdicaoProprietario"
                      placeholder="Município"
                      {...register('municipioEdicaoProprietario')}
                      value={textMunicipio}
                      onChange={onChangeMunicipio}
                      required
                    />
                    <label htmlFor="municipioEdicaoProprietario"> Município </label>

                    {filterMunicipio.length > 0 && (
                      <div className="optionsMunicipios col-12">
                        {filterMunicipio.map((dado) => (
                          <div className="listaMunicipios" key={dado.id} onClick={() => selecionaMunicipio(dado)}>
                            {dado.nome}
                          </div>
                        ))}
                      </div>
                    )}

                    {errors?.municipioEdicaoProprietario?.type && textMunicipio.length === 0 && <ErrorForm />}
                  </div>

                  <div className="labelForm col-5">
                    <input
                      type="text"
                      className="form-control"
                      name="estadoEnderecoNovoProprietario"
                      id="estadoEnderecoNovoProprietario"
                      placeholder="Estado"
                      {...register('estadoEnderecoNovoProprietario')}
                      value={uf}
                      readOnly
                      disabled
                    />
                    <label htmlFor="estadoEnderecoNovoProprietario">Estado</label>
                  </div>

                  <div className="labelForm col-11 col-md-5">
                    <input
                      type="text"
                      name="complementoEdicaoProprietario"
                      placeholder="Complemento"
                      className="form-control"
                      id="complementoEdicaoProprietario"
                      value={complementoEditadoProprietario}
                      onChange={(e) => setComplementoEditadoProprietario(e.target.value)}
                    />
                    <label htmlFor="complementoEdicaoProprietario">Complemento</label>
                  </div>

                  <div className="labelForm col-11 col-md-6">
                    <input
                      type="text"
                      name="bairroEdicaoProprietario"
                      placeholder="Bairro"
                      className="form-control"
                      id="bairroEdicaoProprietario"
                      value={bairroEditadoProprietario}
                      onChange={(e) => setBairroEditadoProprietario(e.target.value)}
                      required
                    />
                    <label htmlFor="bairroEdicaoProprietario">Bairro</label>
                  </div>

                  <div className="buttonsModalEdit mt-3">
                    <Button type="submit" variant="none" className="buttonSave">
                      {textbutton}
                    </Button>
                    <Button
                      onClick={() => [onHide(), clearInputsControlados()]}
                      variant="none"
                      className="buttonCancel"
                    >
                      {textbutton2}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalEditProprietario}
        className="modalSuccess"
        show={successEditProprietario}
        onHide={() => [setSuccessEditProprietario(false), onClick()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalEditProprietario}
        className="modalFalha"
        show={falhaEditProprietario}
        onHide={() => [setFalhaEditProprietario(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalEdicaoProprietario
