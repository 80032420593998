import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import './styles.css'

function LoadComponent() {
  return (
    <div className="loadComponent">
      <div className="iconLoad mb-2">
        <Spinner animation="border" />
      </div>
      <span>Carregando dados...</span>
    </div>
  )
}

export default LoadComponent
