import ModalMessage from 'components/Modais/ModalMessage'
import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { InativarCondutor } from 'services/condutorService'

interface ModalInativacaoCondutorProps {
  readonly nome: string
  readonly show: boolean
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalInativacaoCondutor({
  nome,
  show,
  id,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalInativacaoCondutorProps) {
  const { atualizaCondutores } = useContext(DadosContext)

  const [successInativaCondutor, setSuccessInativaCondutor] = useState(false)
  const [falhaInativaCondutor, setFalhaInativaCondutor] = useState(false)
  const [messageModalInativaCondutor, setMessageModalInativaCondutor] = useState([])

  async function inativaCondutor() {
    InativarCondutor(id)
      .then((response) => {
        setMessageModalInativaCondutor([response.data.message])
        setSuccessInativaCondutor(true)
      })
      .catch((error) => {
        setMessageModalInativaCondutor([error.response.data.message])
        setFalhaInativaCondutor(true)
      })
  }
  return (
    <>
      {!successInativaCondutor && !falhaInativaCondutor && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja inativar o condutor {nome}?</h4>
            </div>

            <div className="buttonsModalInative">
              <Button onClick={inativaCondutor} variant="none" className="buttonInative">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalInativaCondutor}
        className="modalSuccess"
        show={successInativaCondutor}
        onHide={() => [setSuccessInativaCondutor(false), onClick(), atualizaCondutores()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalInativaCondutor}
        className="modalFalha"
        show={falhaInativaCondutor}
        onHide={() => [setFalhaInativaCondutor(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalInativacaoCondutor
