import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GetAllPermissionarios, PesquisaVeiculo } from 'services/permissionariosService'

import { DadosContext } from './DadosContext'

interface PermissionariosContextProps {
  readonly ehAtualizacao: boolean
  readonly idVeiculoSelecionado: number
  readonly condutoresInativos: any[]
  readonly steps: any[]
  readonly activeStep: number
  readonly completed: { [k: number]: boolean }
  readonly handleComplete: (step: any) => void
  readonly setActiveStep: React.Dispatch<React.SetStateAction<number>>
  readonly setIdVeiculoSelecionado: React.Dispatch<React.SetStateAction<number>>
  readonly setEhAtualizacao: React.Dispatch<React.SetStateAction<boolean>>
  readonly setCondutoresInativos: any
}

interface PermissionariosContextProviderProps {
  children: ReactNode
}

export const PermissionariosContext = createContext({} as PermissionariosContextProps)

export function PermissionariosContextProvider({ children }: PermissionariosContextProviderProps) {
  const { setIdProprietarioAtual, setProprietariosSelecionados, setCondutoresSelecionados } = useContext(DadosContext)

  const [idVeiculoSelecionado, setIdVeiculoSelecionado] = useState(0)

  const [ehAtualizacao, setEhAtualizacao] = useState(false)

  const [condutoresInativos, setCondutoresInativos] = useState([])

  useEffect(() => {
    PesquisaVeiculo(idVeiculoSelecionado).then((response) => {
      if (response.data.data !== null) {
        setEhAtualizacao(true)
        setIdProprietarioAtual(response.data.data.proprietarioAtualId)
        setProprietariosSelecionados(response.data.data.proprietariosId)
        setCondutoresSelecionados(response.data.data.condutoresId)
        setCondutoresInativos(response.data.data.condutoresInativosId)
      } else {
        setEhAtualizacao(false)
        setIdProprietarioAtual(0)
        setProprietariosSelecionados([])
        setCondutoresSelecionados([])
        setCondutoresInativos([])
      }
    })
  }, [idVeiculoSelecionado, setCondutoresSelecionados, setIdProprietarioAtual, setProprietariosSelecionados])

  const steps = useMemo(() => ['Veículo', 'Proprietário', 'Condutor'], [])

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean
  }>({})

  const totalSteps = useCallback(() => steps.length, [steps.length])

  const completedSteps = useCallback(() => Object.keys(completed).length, [completed])

  const isLastStep = useCallback(() => activeStep === totalSteps() - 1, [activeStep, totalSteps])

  const allStepsCompleted = useCallback(() => completedSteps() === totalSteps(), [completedSteps, totalSteps])

  const handleNext = useCallback(
    (step) => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((i) => !(i in completed))
          : step === 0
          ? 0
          : step > 0 && step <= 2
          ? step
          : 0
      setActiveStep(newActiveStep)
    },
    [allStepsCompleted, completed, isLastStep, steps]
  )

  const handleComplete = useCallback(
    (step) => {
      const newCompleted = completed
      newCompleted[activeStep] = true
      setCompleted(newCompleted)
      handleNext(step)
    },
    [activeStep, completed, handleNext]
  )

  const contextValues = useMemo(
    () => ({
      ehAtualizacao,
      setEhAtualizacao,
      idVeiculoSelecionado,
      setIdVeiculoSelecionado,
      condutoresInativos,
      setCondutoresInativos,
      steps,
      activeStep,
      completed,
      handleComplete,
      setActiveStep
    }),
    [activeStep, completed, condutoresInativos, ehAtualizacao, handleComplete, idVeiculoSelecionado, steps]
  )

  return <PermissionariosContext.Provider value={contextValues}>{children}</PermissionariosContext.Provider>
}
