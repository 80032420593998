import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ReativarCorporacao } from 'services/corporacoesService'

import ModalMessage from '../../ModalMessage'

interface ModalReativacaoCorporacaoProps {
  readonly nome: string
  readonly show: boolean
  readonly className?: string
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalReativacaoCorporacao({
  nome,
  id,
  className,
  textbutton,
  show,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalReativacaoCorporacaoProps) {
  const { atualizaCorporacao } = useContext(DadosContext)

  const [successReativaCorporacao, setSuccessReativaCorporacao] = useState(false)
  const [falhaReativaCorporacao, setFalhaReativaCorporacao] = useState(false)
  const [messageModalReativaCorporacao, setMessageModalReativaCorporacao] = useState([])

  async function reativaCorporacao() {
    ReativarCorporacao(id)
      .then((response) => {
        setMessageModalReativaCorporacao([response.data.message])
        setSuccessReativaCorporacao(true)
      })
      .catch((error) => {
        setMessageModalReativaCorporacao([error.response.data.message])
        setFalhaReativaCorporacao(true)
      })
  }

  return (
    <>
      {!successReativaCorporacao && !falhaReativaCorporacao && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyReativacao">
              <h4>Deseja reativar a corporação {nome}?</h4>
            </div>

            <div className="buttonsModalEdit">
              <Button onClick={reativaCorporacao} variant="none" className="buttonSave">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalReativaCorporacao}
        className="modalSuccess"
        show={successReativaCorporacao}
        onHide={() => [setSuccessReativaCorporacao(false), onClick(), atualizaCorporacao()]}
        textbutton="OK"
      />

      <ModalMessage
        title={[messageModalReativaCorporacao]}
        className="modalFalha"
        show={falhaReativaCorporacao}
        onHide={() => [setFalhaReativaCorporacao(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalReativacaoCorporacao
