import api from './api'

// #region cadastrar Permissionarios
export const CadastroPermissionario = async (veiculoId: number, condutoresId: any[], proprietarioAtualId: number) =>
  api.post('Permissionario/cadastrar-permissionario', { veiculoId, condutoresId, proprietarioAtualId })

export const CadastroVeiculo = async (
  { placa, prefixo, numeroChassi, corId, renavam, tipoServicoId },
  marcaModeloId,
  anoLicenciamento,
  anoModelo,
  municipioId
) =>
  api.post('Veiculo/cadastrar-veiculo', {
    placa: placa.toUpperCase().trim(),
    prefixo: prefixo.trim(),
    numeroChassi: numeroChassi.trim(),
    marcaModeloId,
    corId: Number(corId),
    anoLicenciamento,
    anoModelo,
    renavam: renavam.trim(),
    especie: Number(tipoServicoId),
    clandestino: Number(tipoServicoId) === 50,
    tipoServicoId: Number(tipoServicoId),
    municipioId
  })
// #endregion

// #region atualizacao Permissionarios
export const EdicaoPermissionario = async (
  veiculoId: number,
  condutoresId: any[],
  proprietariosId: any[],
  proprietarioAtualId: number,
  condutoresInativosId: any[]
) =>
  api.put('Permissionario/atualizar-permissionario', {
    veiculoId,
    condutoresId,
    proprietariosId,
    proprietarioAtualId,
    condutoresInativosId
  })

export const EdicaoVeiculo = async (id: number, placa: string, anoLicenciamento: number, corId: number) =>
  api.put('Veiculo/atualizar-veiculo', { id, placa: placa.toUpperCase(), anoLicenciamento, corId })
// #endregion

// #region PermissionariosContext
export const GetPermissionarios = async (inicio: number, total: number) =>
  api.get('Permissionario', {
    params: {
      inicio,
      total
    }
  })

export const GetAllPermissionarios = async () => api.get('Permissionario')

export const PesquisaVeiculo = async (idVeiculo: number) =>
  api.get(`Permissionario/pesquisa-veiculo?veiculoId=${idVeiculo}`)

export const PesquisaVeiculoPorPlaca = async (placa: string) =>
  api.get('Veiculo/pesquisa-por-placa', {
    params: {
      placa
    }
  })
// #endregion

// #region GetVeiculos

export const GetVeiculos = async () => api.get('/Veiculo/veiculos-cadastrados')

// #endregion
