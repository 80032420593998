import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { IoMdRemoveCircle } from 'react-icons/io'

interface ImageViewProps {
  readonly src: string
  readonly alt: string
  readonly className: string
  readonly onClickImage?: (dado: any) => void
  readonly removeImage?: () => void
  readonly titleTooltip?: string
  readonly placementTooltip?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  readonly classNameTooltip?: string
  readonly colorIcon?: string
  readonly classNameIcon?: string
  readonly sizeIcon?: string
}

export default function ImageView({
  src,
  alt,
  className,
  onClickImage,
  removeImage,
  titleTooltip,
  placementTooltip,
  classNameTooltip,
  colorIcon,
  classNameIcon,
  sizeIcon
}: ImageViewProps) {
  return (
    <div className={className}>
      <img src={src} alt={alt} onClick={onClickImage} />

      <Tooltip title={titleTooltip} arrow placement={placementTooltip} className={classNameTooltip}>
        <div>
          <IoMdRemoveCircle onClick={removeImage} color={colorIcon} className={classNameIcon} size={sizeIcon} />
        </div>
      </Tooltip>
    </div>
  )
}
