import './DigitacaoAuto.css'
import Tooltip from '@mui/material/Tooltip'
import ImageView from 'components/UploadImagesTalao/ImageView'
import InsertImage from 'components/UploadImagesTalao/InsertImage'
import TypeImage from 'components/UploadImagesTalao/TypeImage'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { VscTriangleDown, VscTriangleRight } from 'react-icons/vsc'
import Select from 'react-select'
import { GetVeiculos } from 'services/permissionariosService'

import ButtonSubmit from '../../components/ButtonSubmit'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ErrorForm } from '../../components/FormTalao/ErrorForm'
import ModalMedidasAdministrativas from '../../components/Modais/ModalMedidasAdministrativas'
import ModalMessage from '../../components/Modais/ModalMessage'
import SideBar from '../../components/SideBarLateral'
import { AuthContext } from '../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'
import { DigitacaoContext } from '../../contexts/DigitacaoContext'
import { EnquadramentoContext } from '../../contexts/EnquadramentoContext'

export default function DigitacaoAuto() {
  // #region DadosContext
  const {
    ModalidadeServico,
    Sentidos,
    TiposImagem,
    optionsTipoInfracao,
    Locais,
    erroForm,
    textMunicipio,
    onChangeMunicipio,
    filterMunicipio,
    selecionaMunicipio,
    uf,
    onChangePlaca,
    Placa,
    erroVeiculo,
    prefixoVeiculo,
    setPrefixoVeiculo,
    textMarcaModelo,
    textCor,
    filterCor,
    onChangeCor,
    selecionaCor,
    setPlaca,
    setIdVeiculo,
    setTextCor,
    setTextMarcaModelo,
    setIdMarcaModelo,
    onChangeMarcaModelo,
    filterMarcaModelo,
    selecionaMarcaModelo,
    dataInfracao,
    setDataInfracao,
    hora,
    setHora,
    errorLoad,
    modalShow,
    setModalShow,
    cpfCondutorValue,
    inputCpfCondutorMask,
    cnhCondutorValue,
    inputCnhCondutorMask,
    condutoresSelecionados,
    setIdCondutorSelecionado,
    falha,
    modalFalhaShow,
    setModalFalhaShow,
    messageSuccess,
    messageFalha,
    modalMedidasAdministrativas,
    setModalMedidasAdministrativas,
    idCondutorSelecionado,
    idVeiculo,
    cepNovoCondutorValue,
    inputCepNovoCondutorMask,
    telefoneNovoCondutorValue,
    inputTelefoneNovoCondutorMask
  } = useContext(DadosContext)

  // #endregion
  // ------------------------------------------------

  // #region CondicionaisFormContext
  const {
    srcImageDefault,
    trocaImagem,
    selectedImages,
    hideOption,
    hideOptionAndDelete,
    srcImages,
    mostraOpcaoSecundaria,
    uploadSecundarioActive,
    hideOptionSecundaria,
    mostraOpcao,
    onSelectFilePrincipal,
    onSelectFilesSecundarios,
    removeImage,
    srcSecondImages,
    ocultaDadosAutuado,
    ocultaDadosVeiculo,
    collapseDadosAutuadoActive,
    mostraDadosAutuado,
    collapseDadosVeiculoActive,
    mostraDadosVeiculo,
    ocultaDadosInfracao,
    collapseDadosInfracaoActive,
    mostraDadosInfracao,
    ocultaDadosAgente,
    collapseDadosAgenteActive,
    mostraDadosAgente,
    ocultaDadosCondutor,
    collapseDadosCondutorActive,
    mostraDadosCondutor,
    classSideNav,
    tipoImagemImageDefault,
    setTipoImagemImageDefault,
    insereTipoImagemImagensSecundarias,
    messageRequiredTipoImagem,
    setMessageRequiredTipoImagem,
    textTipoImagemDefault,
    setTextTipoImagemDefault
  } = useContext(CondicionaisFormContext)

  // #endregion
  // ------------------------------------------------

  // #region EnquadramentoContext
  const {
    rowsEnquadramento,
    numeroEnquadramento,
    filterEnquadramento,
    idEnquadramento,
    onChangeEnquadramento,
    selecionaEnquadramento,
    isEnquadramentoRequired
  } = useContext(EnquadramentoContext)

  // #endregion
  // ------------------------------------------------

  // #region DigitacaoContext
  const {
    messageRequiredTipoImagens,
    setSelectedOptionsInfracao,
    sendValuesOptionsInfracao,
    showSelectLocal,
    selectedOptionsInfracao,
    typeInput,
    agenteInfrator,
    proprietarioAutuado,
    enderecoProprietarioAutuado,
    modalSuccessShow,
    filterAgente,
    filterProprietario,
    success,
    register,
    enviaForm,
    handleSubmit,
    getVeiculos,
    errors,
    setModalSuccessShow,
    reset,
    onChangeAgente,
    selecionaAgente,
    onChangeProprietario,
    selecionaProprietario,
    nomeCondutorAuto,
    onChangeNomeCondutorAuto,
    selecionaCondutor,
    filterCondutorAuto,
    cpfCondutorAuto,
    cnhCondutorAuto,
    categoriaCnhCondutorAuto
  } = useContext(DigitacaoContext)

  // #endregion
  // ------------------------------------------------

  // #region AuthContext
  const { serviceValue, setServiceValue } = useContext(AuthContext)
  // #endregion
  // ------------------------------------------------

  const [shouldResetFields, setShouldResetFields] = useState(false)

  const [optionTipoImagemPrincipal, setOptionTipoImagemPrincipal] = useState(false)
  const [optionTipoImagensSecundarias, setOptionTipoImagensSecundarias] = useState({})

  const [typeInputUpload, setTypeInputUpload] = useState('')

  const [Veiculos, setVeiculos] = useState([])
  const [filterVeiculos, setFilterVeiculos] = useState([])

  const [selectCondutorVazio, setSelectCondutorVazio] = useState(false)

  const [focusEnquadramento, setFocusEnquadramento] = useState(false)

  useEffect(() => {
    setShouldResetFields(true)
  }, [selectedOptionsInfracao])

  useEffect(() => {
    if (shouldResetFields && !selectedOptionsInfracao.some((option) => option.value === 20)) {
      reset({
        selectedRemovido: ''
      })
      setShouldResetFields(false)
    } else if (shouldResetFields && !selectedOptionsInfracao.some((option) => option.value === 40)) {
      reset({
        selectVistoria: '',
        selectPrazo: ''
      })
      setShouldResetFields(false)
    }
  }, [reset, selectedOptionsInfracao, shouldResetFields])

  useEffect(() => {
    if (!modalSuccessShow) {
      GetVeiculos().then((response) => {
        setVeiculos(response.data.data)
      })
    }
  }, [modalSuccessShow])

  useEffect(() => {
    if (!prefixoVeiculo) {
      setFilterVeiculos([])
    }
    if (idVeiculo !== 0) {
      setFilterVeiculos([])
    }
  }, [prefixoVeiculo, idVeiculo])

  useEffect(() => {
    if (tipoImagemImageDefault === 0) {
      setTypeInputUpload('text')
    } else {
      setMessageRequiredTipoImagem(false)
      setTypeInputUpload('file')
    }
  }, [tipoImagemImageDefault, setMessageRequiredTipoImagem])

  useEffect(() => {
    if (idVeiculo !== 0 && condutoresSelecionados.length === 0) {
      setIdCondutorSelecionado(null)
      setSelectCondutorVazio(false)
    } else {
      setIdCondutorSelecionado(0)
    }
  }, [condutoresSelecionados.length, idVeiculo, setIdCondutorSelecionado])

  function ativaOptionTipoImagemPrincipal() {
    setOptionTipoImagemPrincipal(!optionTipoImagemPrincipal)
  }

  const ativaOptionTipoImagensSecundarias = (valor) => () => {
    setOptionTipoImagensSecundarias((state) => ({
      ...state,
      [valor]: !state[valor]
    }))
  }

  function selecionaVeiculo(dado) {
    setPrefixoVeiculo(dado.prefixo)
    setPlaca(dado.placa)
    setIdVeiculo(dado.id)
    setTextCor(dado.cor.descricao)
    setTextMarcaModelo(dado.marcaModelo.descricao)
    setIdMarcaModelo(dado.marcaModeloId)
    setFilterVeiculos([])
  }

  const onChangePrefixo = useCallback(
    (e) => {
      e.preventDefault()
      setPrefixoVeiculo(e.target.value)

      const Filtro = Veiculos.filter((value) =>
        Object.values(value).some(() => {
          const searchValue = e.target.value
          const prefixo = value.prefixo?.replace(/[/]/g, '')
          return prefixo?.includes(searchValue.replace(/[/]/g, ''))
        })
      )

      setFilterVeiculos(Filtro)
    },
    [Veiculos, setPrefixoVeiculo]
  )

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      const fields = document.querySelectorAll('input, textarea, select')
      const currentIndex = Array.from(fields).findIndex((field) => field === e.target)

      const isComponentSelectInput = (e.target as HTMLInputElement).id === 'react-select-2-input'

      const selectedOptionsCount = selectedOptionsInfracao.length
      const countOptions = selectedOptionsCount > 0 ? selectedOptionsCount + 1 : 2

      const nextIndex = isComponentSelectInput ? currentIndex + countOptions : currentIndex + 1

      const nextField = fields[nextIndex] as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

      if (nextField) {
        nextField.focus()
      }
    }
  }

  return (
    <div className="content">
      <div className="sideNavLateral">
        <SideBar />
      </div>

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="title mt-3">
          <h2>Digitação de Auto</h2>
        </div>

        <div className="tela container">
          {selectedImages && (
            <div className="mostraImagem" onLoad={hideOption}>
              {uploadSecundarioActive && srcSecondImages.length > 0 && (
                <div className="arrayImagensPreview">
                  <ImageView
                    className={srcImages.readImage === srcImageDefault ? 'previewImageDefault' : 'uploadSecundario'}
                    src={srcImages[0].readImage}
                    alt="previewImagemDefault"
                    onClickImage={() => trocaImagem(srcImages[0])}
                  />

                  {srcSecondImages.map((dado) => (
                    <ImageView
                      key={dado.id.toFixed(2)}
                      className={dado.readImage === srcImageDefault ? 'previewImageDefault' : 'uploadSecundario'}
                      src={dado.readImage}
                      alt="uploadSecondImage"
                      onClickImage={() => trocaImagem(dado)}
                      removeImage={() => removeImage(dado.id, dado.readImage.split(',')[1])}
                      titleTooltip="Remover imagem"
                      placementTooltip="top"
                      classNameTooltip="removeSecondImage"
                      colorIcon="rgba(253, 47, 47, 0.932)"
                      classNameIcon="buttonDeleteSecondImage"
                      sizeIcon="17px"
                    />
                  ))}
                </div>
              )}

              <div className="image">
                <div className="iconsDivImage">
                  {mostraOpcaoSecundaria && srcImages[0].readImage === srcImageDefault ? (
                    <TypeImage
                      onClickTooltip={ativaOptionTipoImagemPrincipal}
                      optionTipoImagemPrincipal={optionTipoImagemPrincipal}
                      onBlurOptions={ativaOptionTipoImagemPrincipal}
                      nameSelect="tipoImagem"
                      onChangeSelect={(e) => [
                        setTipoImagemImageDefault(Number(e.target.value)),
                        setTextTipoImagemDefault(e.target.value)
                      ]}
                      defaultValueSelect={textTipoImagemDefault}
                      messageRequiredTipoImagem={messageRequiredTipoImagem}
                      arrayTiposImagem={TiposImagem}
                    />
                  ) : (
                    srcSecondImages.map((dado, valor) =>
                      dado.readImage === srcImageDefault ? (
                        <TypeImage
                          key={dado.id}
                          onClickTooltip={ativaOptionTipoImagensSecundarias(valor)}
                          optionTipoImagensSecundarias={optionTipoImagensSecundarias[valor]}
                          onBlurOptions={ativaOptionTipoImagensSecundarias(valor)}
                          nameSelect={`${dado.id}`}
                          onChangeSelect={(e) => insereTipoImagemImagensSecundarias(e, dado)}
                          defaultValueSelect="TIPO DE IMAGEM"
                          arrayTiposImagem={TiposImagem}
                        />
                      ) : null
                    )
                  )}

                  {mostraOpcaoSecundaria && srcSecondImages.length < 3 && (
                    <InsertImage
                      className="mt-1"
                      titleTooltip="Adicionar nova imagem"
                      classNameTooltip=""
                      onClickTooltip={
                        typeInputUpload === 'text' || typeInputUpload === ''
                          ? () => [setMessageRequiredTipoImagem(true), setOptionTipoImagemPrincipal(true)]
                          : null
                      }
                      onClickLabelUpload={hideOptionSecundaria}
                      typeInputUpload={typeInputUpload}
                      onChangeInputUpload={onSelectFilesSecundarios}
                      sizeIcon={28}
                    />
                  )}
                </div>

                <ImageView
                  className="uploadPrincipal"
                  src={srcImageDefault}
                  alt="upload"
                  removeImage={() => hideOptionAndDelete()}
                  titleTooltip={`Remover ${srcSecondImages.length > 0 ? 'imagens' : 'imagem'}`}
                  placementTooltip="bottom"
                  classNameTooltip="buttonDeleteImage"
                  colorIcon="black"
                  sizeIcon="35px"
                />
              </div>
            </div>
          )}

          {mostraOpcao && srcImages.length === 0 && (
            <InsertImage
              className="mt-3"
              titleTooltip="Fazer upload de imagens do auto"
              classNameTooltip="tooltipTalao"
              typeInputUpload="file"
              onChangeInputUpload={onSelectFilePrincipal}
              sizeIcon={35}
            />
          )}

          <form className="form mt-3" onSubmit={handleSubmit(enviaForm, erroForm)} autoComplete="off">
            <div className="form-talao mb-4">
              <div className="topo-talao">
                <div className="labelForm col-11 col-md-4 mt-3">
                  <input
                    className="form-control"
                    type="text"
                    name="numeroAuto"
                    id="numeroAuto"
                    placeholder="N° do Auto"
                    maxLength={10}
                    {...register('numeroAuto')}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <label htmlFor="numeroAuto">N° do auto</label>
                </div>

                <div className="labelForm col-11 col-md-7">
                  <select
                    className="col-12"
                    name="servicoId"
                    defaultValue="MODALIDADE DE SERVIÇO *"
                    {...register('servicoId')}
                    onChange={(e) => [setServiceValue(Number(e.target.value)), setSelectedOptionsInfracao([])]}
                    onKeyDown={handleEnterKeyPress}
                  >
                    <option disabled>MODALIDADE DE SERVIÇO *</option>
                    {ModalidadeServico.map((dado) => (
                      <option key={dado.id} value={Number(dado.id)}>
                        {dado.descricao}
                      </option>
                    ))}
                  </select>
                  {errors?.servicoId?.type && <ErrorForm />}
                </div>
              </div>
              <div className="labelForm col-11">
                <Select
                  className="selectTipoInfracao col-12"
                  name="tipoInfracao"
                  id="tipoInfracao"
                  value={selectedOptionsInfracao}
                  placeholder="Tipo de penalidade aplicada *"
                  options={optionsTipoInfracao}
                  onChange={(item) => setSelectedOptionsInfracao(item)}
                  onBlur={(dado) => {
                    sendValuesOptionsInfracao(dado)
                    if (!selectedOptionsInfracao.some((option) => option.value === 20)) {
                      // Desmarcar a opção 20
                      setSelectedOptionsInfracao((prevOptions) => prevOptions.filter((option) => option.value !== 20))
                    }
                    if (!selectedOptionsInfracao.some((option) => option.value === 40)) {
                      // Desmarcar a opção 40
                      setSelectedOptionsInfracao((prevOptions) => prevOptions.filter((option) => option.value !== 40))
                    }
                  }}
                  isMulti
                  required
                  onKeyDown={handleEnterKeyPress}
                />
              </div>
              <div className="hiddenOpts col-11" style={{ display: 'flex', alignItems: 'flex-start' }}>
                {(selectedOptionsInfracao.some((option) => option.value === 20) ||
                  selectedOptionsInfracao.some((option) => option.value === 40)) && (
                  <div className="selects20e40 flex-wrap">
                    {selectedOptionsInfracao.some((option) => option.value === 20) && (
                      <div className="labelForm selectRemovido">
                        <select
                          name="selectedRemovido"
                          {...register('selectedRemovido')}
                          required={selectedOptionsInfracao.some((option) => option.value === 20)}
                          defaultValue=""
                          onKeyDown={handleEnterKeyPress}
                        >
                          <option disabled value="">
                            Removido por: *
                          </option>
                          <option value="Condutor">Condutor</option>
                          <option value="Guincho">Guincho</option>
                        </select>
                      </div>
                    )}
                    {selectedOptionsInfracao.some((option) => option.value === 40) && (
                      <div className="labelForm selectsVistoriaEPrazo">
                        <select
                          name="selectedVistoria"
                          {...register('selectedVistoria')}
                          required={selectedOptionsInfracao.some((option) => option.value === 40)}
                          defaultValue=""
                          onKeyDown={handleEnterKeyPress}
                        >
                          <option disabled value="">
                            Vistoria: *
                          </option>
                          <option value="Agente">Agente</option>
                          <option value="Facil">Fácil</option>
                          <option value="Ctecnico">C. Técnico</option>
                        </select>
                      </div>
                    )}
                    {selectedOptionsInfracao.some((option) => option.value === 40) && (
                      <div className="labelForm selectsVistoriaEPrazo">
                        <select
                          name="selectedPrazo"
                          {...register('selectedPrazo')}
                          required={selectedOptionsInfracao.some((option) => option.value === 40)}
                          defaultValue=""
                          onKeyDown={handleEnterKeyPress}
                        >
                          <option disabled value="">
                            Prazo: *
                          </option>
                          <option value="12h">12h</option>
                          <option value="24h">24h</option>
                          <option value="36h">36h</option>
                          <option value="48h">48h</option>
                        </select>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="subtitulo mt-3 mb-3" onClick={ocultaDadosVeiculo}>
                <h6 className="col-11 mt-1">Dados do Veículo</h6>
                {collapseDadosVeiculoActive ? (
                  <VscTriangleRight color="#fff" className="buttonCollapse" />
                ) : (
                  <VscTriangleDown color="#fff" className="buttonCollapse" />
                )}
              </div>

              {mostraDadosVeiculo && (
                <div className="dadosVeiculo">
                  <div className="labelForm col-5 col-md-2">
                    <input
                      type="text"
                      name="prefixoVeiculo"
                      id="prefixoVeiculo"
                      className="form-control"
                      placeholder="Prefixo"
                      value={prefixoVeiculo || ''}
                      maxLength={10}
                      onChange={onChangePrefixo}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="prefixoVeiculo"> Prefixo *</label>

                    {filterVeiculos.length > 0 && idVeiculo === 0 && (
                      <div className="optionsDiv col-12" id="optionsPrefixo">
                        {filterVeiculos.map((dado) => (
                          <div className="listaOpcoes" key={dado.id} onClick={() => selecionaVeiculo(dado)}>
                            {dado.prefixo} - {dado.placa}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="labelForm col-6 col-md-2">
                    <input
                      type="text"
                      name="placa"
                      id="placa"
                      className="form-control"
                      placeholder="Placa"
                      maxLength={7}
                      onChange={onChangePlaca}
                      onBlur={getVeiculos}
                      value={Placa}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="placa"> Placa *</label>
                    {errors?.placa?.type && Placa.length === 0 && <ErrorForm />}
                  </div>

                  <div className="labelForm col-5 col-md-2">
                    <input
                      type="text"
                      name="cor"
                      id="cor"
                      className="form-control"
                      placeholder="Cor"
                      readOnly={typeInput === 'ReadOnly'}
                      value={textCor}
                      onChange={onChangeCor}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="cor"> Cor </label>

                    {filterCor.length > 0 && (
                      <div className="optionsDiv col-12">
                        {filterCor.map((dado) => (
                          <div className="listaOpcoes" key={dado.id} onClick={() => selecionaCor(dado)}>
                            {dado.descricao}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="labelForm col-6 col-md-5">
                    <input
                      type="text"
                      name="marcaModelo"
                      id="marcaModelo"
                      className="form-control"
                      placeholder="Marca/ Modelo"
                      value={textMarcaModelo}
                      onChange={onChangeMarcaModelo}
                      readOnly={typeInput === 'ReadOnly'}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="marcaModelo"> Marca/ Modelo </label>

                    {filterMarcaModelo.length > 0 && (
                      <div className="optionsDiv col-12">
                        {filterMarcaModelo.map((dado) => (
                          <div className="listaOpcoes" key={dado.id} onClick={() => selecionaMarcaModelo(dado)}>
                            {dado.descricao}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {serviceValue === 50 && (
                    <>
                      <div className="labelForm col-8 mb-3">
                        <input
                          type="text"
                          className="municipios form-control"
                          name="municipio"
                          id="municipio"
                          placeholder="Município"
                          {...register('municipio')}
                          value={textMunicipio}
                          onChange={onChangeMunicipio}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="municipio"> Município </label>

                        {filterMunicipio.length > 0 && (
                          <div className="optionsDiv col-12">
                            {filterMunicipio.map((dado) => (
                              <div className="listaOpcoes" key={dado.id} onClick={() => selecionaMunicipio(dado)}>
                                {dado.nome}
                              </div>
                            ))}
                          </div>
                        )}

                        {errors?.municipio?.type && textMunicipio.length === 0 && <ErrorForm />}
                      </div>

                      <div className="labelForm col-3">
                        <input
                          type="text"
                          className="form-control"
                          name="uf"
                          id="uf"
                          placeholder="UF"
                          readOnly
                          value={uf}
                          {...register('uf')}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="uf">UF</label>
                        {errors?.uf?.type && <ErrorForm />}
                      </div>
                    </>
                  )}

                  {erroVeiculo && getVeiculos && <ErrorMessage className="errorMessageVeiculo" text={erroVeiculo} />}
                  {erroVeiculo && (
                    <button id="buttonCadastrarVeiculo" className="btn btn-primary">
                      <a href="/controlePermissionarios" id="linkCadastrarVeiculo">
                        Cadastrar Veículo
                      </a>
                    </button>
                  )}
                </div>
              )}

              <div className="subtitulo mt-3 mb-3" onClick={ocultaDadosAutuado}>
                <h6 className="col-11 mt-1">Dados do Autuado</h6>
                {collapseDadosAutuadoActive ? (
                  <VscTriangleRight color="#fff" className="buttonCollapse" />
                ) : (
                  <VscTriangleDown color="#fff" className="buttonCollapse" />
                )}
              </div>

              {mostraDadosAutuado && (
                <div className="dadosAutuado">
                  <div className="labelForm col-11 col-md-11">
                    <input
                      type="text"
                      name="nomeOuRazaoSocial"
                      id="nomeOuRazaoSocial"
                      className="form-control"
                      placeholder="Nome ou Razão social"
                      {...register('proprietarioAutuado')}
                      value={proprietarioAutuado}
                      onChange={onChangeProprietario}
                      // value={prefixoVeiculo || ''}
                      maxLength={100}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="nomeOuRazaoSocial"> Nome ou Razão Social </label>
                    {filterProprietario.length > 0 && (
                      <div className="optionsDiv col-12">
                        {filterProprietario.map((dado) => (
                          <div className="listaOpcoes" key={dado.id} onClick={() => selecionaProprietario(dado)}>
                            {dado.nome} - {dado.cpF_CNPJ}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="labelForm col-11 col-md-11">
                    <input
                      type="text"
                      name="enderecoAutuado"
                      id="enderecoAutuado"
                      className="form-control"
                      placeholder="Endereço"
                      value={enderecoProprietarioAutuado}
                      maxLength={100}
                      onKeyDown={handleEnterKeyPress}
                      disabled
                    />
                    <label htmlFor="enderecoAutuado"> Endereço </label>
                  </div>
                </div>

                // <input
                // type="text"
                // name="codigoAgente"
                // placeholder="Código do agente"
                // className="form-control"
                // id="codigoAgente"
                // {...register('agenteInfrator')}
                // value={agenteInfrator}
                // onChange={onChangeAgente}
                // />
                // <label htmlFor="codigoAgente">Código *</label>
              )}

              <div className="subtitulo mt-3 mb-3" onClick={ocultaDadosInfracao}>
                <h6 className="col-11 mt-1">Dados da Infração</h6>
                {collapseDadosInfracaoActive ? (
                  <VscTriangleRight color="#fff" className="buttonCollapse" />
                ) : (
                  <VscTriangleDown color="#fff" className="buttonCollapse" />
                )}
              </div>

              {mostraDadosInfracao && (
                <div className="dadosInfracao">
                  <label className="data col-6 col-md-4">
                    Data
                    <input
                      type="date"
                      name="dataInfracao"
                      id="data"
                      className="form-control"
                      onChange={(e) => setDataInfracao(e.target.value)}
                      value={dataInfracao}
                      onKeyDown={handleEnterKeyPress}
                    />
                  </label>

                  <label className="data col-5 col-md-3">
                    Horário *
                    <input
                      type="time"
                      name="hora"
                      required
                      id="hora"
                      className="form-control"
                      {...register('hora')}
                      onChange={(e) => setHora(e.target.value)}
                      value={hora}
                      onKeyDown={handleEnterKeyPress}
                    />
                  </label>

                  <div className="labelForm col-11 col-md-4">
                    <select
                      name="sentido"
                      className="col-12"
                      defaultValue="SENTIDO"
                      {...register('sentido')}
                      onKeyDown={handleEnterKeyPress}
                    >
                      <option disabled>SENTIDO</option>
                      {Sentidos.map((dado) => (
                        <option key={dado.id} value={Number(dado.id)}>
                          {dado.descricao}
                        </option>
                      ))}
                    </select>
                  </div>

                  {showSelectLocal && (
                    <div className="labelForm col-11 col-md-4">
                      <select name="local" className="col-12" defaultValue="LOCAL" {...register('local')}>
                        <option disabled>LOCAL</option>
                        {Locais.map((dado) => (
                          <option key={dado.id} value={dado.id}>
                            {dado.descricao}
                          </option>
                        ))}
                      </select>
                      {errors?.local?.type && <ErrorForm />}
                    </div>
                  )}

                  <div className={`labelForm ${showSelectLocal ? 'col-9 col-md-5' : 'col-9'}`}>
                    <input
                      type="text"
                      name="endereco"
                      id="endereco"
                      className="form-control"
                      placeholder="Endereço *"
                      maxLength={150}
                      {...register('endereco')}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="endereco"> Endereço * </label>
                    {errors?.endereco?.type && <ErrorForm />}
                  </div>

                  <div className="labelForm col-2">
                    <input
                      type="text"
                      name="numeroEndereco"
                      id="numeroEndereco"
                      className="form-control"
                      placeholder="N°"
                      maxLength={5}
                      {...register('numeroEndereco')}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="numeroEndereco"> N° </label>
                  </div>

                  <div className="labelForm col-11">
                    <textarea
                      type="text"
                      rows={rowsEnquadramento}
                      name="enquadramentoId"
                      placeholder="Código de enquadramento e descrição"
                      className="form-control"
                      id="enquadramento"
                      {...register('enquadramentoId')}
                      value={numeroEnquadramento}
                      onChange={onChangeEnquadramento}
                      required={isEnquadramentoRequired}
                      onKeyDown={handleEnterKeyPress}
                      onFocus={() => setFocusEnquadramento(true)}
                      onBlur={() => setFocusEnquadramento(false)}
                    />
                    <label htmlFor="enquadramento">
                      Código de enquadramento e descrição {isEnquadramentoRequired && <span>*</span>}
                    </label>
                    {filterEnquadramento.length > 0 ? (
                      <div className="optionsDiv col-12">
                        {filterEnquadramento.map((dado) => (
                          <li
                            className="listaOpcoes"
                            key={dado.id}
                            onClick={() => selecionaEnquadramento(dado)}
                            value={idEnquadramento}
                          >
                            {dado.codigo} - {dado.grupo} - {dado.descricao}
                          </li>
                        ))}
                      </div>
                    ) : focusEnquadramento && filterEnquadramento.length === 0 && numeroEnquadramento.length > 0 ? (
                      <div className="optionsDiv col-12">Nenhum enquadramento encontrado para este tipo de serviço</div>
                    ) : null}
                    {errors?.enquadramentoId?.type && numeroEnquadramento.length === 0 && <ErrorForm />}
                  </div>

                  <div className="labelForm col-11">
                    <textarea
                      type="text"
                      name="observacao"
                      id="observacao"
                      placeholder="Observação"
                      className="form-control"
                      rows="3"
                      style={{ overflow: 'hidden' }}
                      maxLength={500}
                      {...register('observacao')}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="observacao"> Observação </label>
                  </div>
                </div>
              )}

              <div className="subtitulo mt-3 mb-3" onClick={ocultaDadosCondutor}>
                <h6 className="col-11 mt-1">Dados do condutor</h6>
                {collapseDadosCondutorActive ? (
                  <VscTriangleRight color="#fff" className="buttonCollapse" />
                ) : (
                  <VscTriangleDown color="#fff" className="buttonCollapse" />
                )}
              </div>

              {mostraDadosCondutor && (
                <div className="dadosCondutor">
                  {idVeiculo !== 0 && idCondutorSelecionado === null && condutoresSelecionados.length === 0 ? (
                    <div className="labelForm col-11">
                      <span>
                        O veículo selecionado não possui condutores cadastrados. Preencha os campos abaixo para
                        registrar o condutor da infração.
                      </span>
                    </div>
                  ) : (
                    idVeiculo !== 0 &&
                    nomeCondutorAuto.length === 0 &&
                    idCondutorSelecionado !== null &&
                    condutoresSelecionados.length !== 0 && (
                      <div className="labelForm col-11">
                        <span>
                          {condutoresSelecionados.length} condutores encontrados. Selecione o nome abaixo, ou{' '}
                          <span
                            className="linkNovoCondutor"
                            onClick={() => [selecionaCondutor(null), setSelectCondutorVazio(false)]}
                          >
                            clique aqui
                          </span>{' '}
                          para registrar um novo condutor
                        </span>
                      </div>
                    )
                  )}
                  <div className="labelForm col-11 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="nomeCondutor"
                      id="nomeCondutor"
                      placeholder="Nome do condutor"
                      {...register('nomeCondutor')}
                      value={nomeCondutorAuto}
                      onChange={onChangeNomeCondutorAuto}
                      disabled={idVeiculo === 0 && serviceValue !== 50}
                      onFocus={idCondutorSelecionado !== null ? () => setSelectCondutorVazio(true) : null}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="nomeCondutor"> Nome</label>

                    {filterCondutorAuto.length > 0 && idCondutorSelecionado !== null ? (
                      <div className="optionsDiv col-12">
                        {filterCondutorAuto.map((dado) => (
                          <div
                            className="listaOpcoes"
                            key={dado.id}
                            onClick={() => [selecionaCondutor(dado), setSelectCondutorVazio(false)]}
                          >
                            {dado.codigo} - {dado.nome}
                          </div>
                        ))}
                      </div>
                    ) : (
                      nomeCondutorAuto.length === 0 &&
                      selectCondutorVazio &&
                      idCondutorSelecionado !== null && (
                        <div className="optionsDiv col-12">
                          {condutoresSelecionados.map((dado) => (
                            <div
                              className="listaOpcoes"
                              key={dado.id}
                              onClick={() => [selecionaCondutor(dado), setSelectCondutorVazio(false)]}
                            >
                              {dado.codigo} - {dado.nome}
                            </div>
                          ))}
                        </div>
                      )
                    )}
                    {errors?.nomeCondutor?.type && nomeCondutorAuto.length === 0 && <ErrorForm />}
                    <div>
                      {idCondutorSelecionado !== null
                        ? null
                        : condutoresSelecionados.length !== 0 && (
                            <Tooltip
                              title="Condutor identificado"
                              arrow
                              placement="bottom"
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '45%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer'
                              }}
                            >
                              <div>
                                <BsFillCheckSquareFill onClick={() => selecionaCondutor(0)} size={18} />
                              </div>
                            </Tooltip>
                          )}
                    </div>
                  </div>

                  <div className="labelForm col-6 col-md-5">
                    <input
                      type="text"
                      name="cpfCondutor"
                      id="cpfCondutor"
                      className="form-control"
                      placeholder="CPF/CNPJ"
                      {...register('cpfCondutor')}
                      value={cpfCondutorAuto.length > 0 ? cpfCondutorAuto : cpfCondutorValue}
                      disabled={
                        (cpfCondutorAuto.length >= 0 &&
                          idCondutorSelecionado !== 0 &&
                          idCondutorSelecionado !== null &&
                          serviceValue !== 50) ||
                        (idVeiculo === 0 && serviceValue !== 50)
                      }
                      onChange={inputCpfCondutorMask}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="cpfCondutor">
                      {' '}
                      CPF/CNPJ {idCondutorSelecionado === null && cpfCondutorValue && <span>*</span>}
                    </label>
                    {errors?.cpfCondutor?.type && cpfCondutorValue.length === 0 && cpfCondutorAuto.length === 0 && (
                      <ErrorForm />
                    )}
                  </div>

                  <div className="labelForm col-5 col-md-4">
                    {idCondutorSelecionado === null ? (
                      <>
                        <select
                          name="categoriaCNH"
                          className="col-12"
                          defaultValue={
                            categoriaCnhCondutorAuto === '1' || categoriaCnhCondutorAuto === 'A'
                              ? 1
                              : categoriaCnhCondutorAuto === '2' || categoriaCnhCondutorAuto === 'B'
                              ? 2
                              : 'CATEGORIA CNH'
                          }
                          {...register('categoriaCNH')}
                          onKeyDown={handleEnterKeyPress}
                        >
                          <option disabled>CATEGORIA CNH</option>
                          <option value={1}>A</option>
                          <option value={2}>B</option>
                          <option value={3}>C</option>
                          <option value={4}>D</option>
                          <option value={5}>E</option>
                        </select>
                        {errors?.categoriaCNH?.type && !categoriaCnhCondutorAuto && <ErrorForm />}
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          name="categoriaCnhCondutor"
                          id="categoriaCnhCondutor"
                          className="form-control"
                          placeholder="Categoria CNH"
                          {...register('categoriaCnhCondutor')}
                          value={categoriaCnhCondutorAuto}
                          disabled={
                            (categoriaCnhCondutorAuto.length > 0 &&
                              idCondutorSelecionado !== 0 &&
                              serviceValue !== 50) ||
                            (idVeiculo === 0 && serviceValue !== 50)
                          }
                          onChange={inputCnhCondutorMask}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="categoriaCnhCondutor"> Categoria CNH</label>
                      </>
                    )}
                  </div>

                  <div className={`labelForm ${idCondutorSelecionado === null ? 'col-6' : 'col-11'} col-md-7`}>
                    <input
                      type="text"
                      name="cnhCondutor"
                      id="cnhCondutor"
                      className="form-control"
                      placeholder="CNH do Condutor"
                      {...register('cnhCondutor')}
                      value={cnhCondutorAuto.length > 0 ? cnhCondutorAuto : cnhCondutorValue}
                      disabled={
                        (cnhCondutorAuto.length > 0 && idCondutorSelecionado !== 0 && serviceValue !== 50) ||
                        (idVeiculo === 0 && serviceValue !== 50)
                      }
                      onChange={inputCnhCondutorMask}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <label htmlFor="cnhCondutor"> CNH</label>
                    {errors?.cnhCondutor?.type && cnhCondutorValue.length === 0 && cnhCondutorAuto.length === 0 && (
                      <ErrorForm />
                    )}
                  </div>

                  {idCondutorSelecionado === null && (
                    <>
                      <div className="labelForm col-5 col-md-4">
                        <input
                          type="text"
                          name="cepCondutor"
                          id="cepCondutor"
                          className="form-control"
                          placeholder="CEP"
                          {...register('cepNovoCondutor')}
                          value={cepNovoCondutorValue}
                          onChange={inputCepNovoCondutorMask}
                          required={idCondutorSelecionado === null && cpfCondutorValue}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="cepCondutor">
                          {' '}
                          CEP {idCondutorSelecionado === null && cpfCondutorValue && <span>*</span>}
                        </label>
                      </div>

                      <div className="labelForm col-9 col-md-7">
                        <input
                          type="text"
                          name="enderecoCondutor"
                          id="enderecoCondutor"
                          className="form-control"
                          placeholder="Endereço"
                          {...register('enderecoCondutor')}
                          required={idCondutorSelecionado === null && cpfCondutorValue}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="enderecoCondutor">
                          Endereço {idCondutorSelecionado === null && cpfCondutorValue && <span>*</span>}
                        </label>
                      </div>

                      <div className="labelForm col-2">
                        <input
                          type="text"
                          name="numeroEnderecoCondutor"
                          id="numeroEnderecoCondutor"
                          className="form-control"
                          placeholder="N°"
                          {...register('numeroEnderecoCondutor')}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="numeroEnderecoCondutor"> N° </label>
                      </div>

                      <div className="labelForm col-9 col-md-7 mb-3">
                        <input
                          type="text"
                          className="municipios form-control"
                          name="municipio"
                          id="municipio"
                          placeholder="Município"
                          {...register('municipio')}
                          value={textMunicipio}
                          onChange={onChangeMunicipio}
                          required={idCondutorSelecionado === null && cpfCondutorValue}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="municipio">
                          {' '}
                          Município {idCondutorSelecionado === null && cpfCondutorValue && <span>*</span>}
                        </label>

                        {filterMunicipio.length > 0 && (
                          <div className="optionsDiv col-12">
                            {filterMunicipio.map((dado) => (
                              <div className="listaOpcoes" key={dado.id} onClick={() => selecionaMunicipio(dado)}>
                                {dado.nome}
                              </div>
                            ))}
                          </div>
                        )}

                        {errors?.municipio?.type && textMunicipio.length === 0 && <ErrorForm />}
                      </div>

                      <div className="labelForm col-2">
                        <input
                          type="text"
                          className="form-control"
                          name="estadoCondutor"
                          id="estadoCondutor"
                          placeholder="UF"
                          readOnly
                          value={uf}
                          {...register('estadoCondutor')}
                          disabled
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="estadoCondutor">UF</label>
                        {errors?.uf?.type && <ErrorForm />}
                      </div>

                      <div className="labelForm col-11 col-md-6">
                        <input
                          type="text"
                          name="bairroCondutor"
                          id="bairroCondutor"
                          className="form-control"
                          placeholder="Bairro"
                          {...register('bairroCondutor')}
                          required={idCondutorSelecionado === null && cpfCondutorValue}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="bairroCondutor">
                          Bairro {idCondutorSelecionado === null && cpfCondutorValue && <span>*</span>}
                        </label>
                      </div>

                      <div className="labelForm col-11 col-md-5">
                        <input
                          type="text"
                          name="complementoCondutor"
                          id="complementoCondutor"
                          className="form-control"
                          placeholder="Complemento"
                          {...register('complementoCondutor')}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="complementoCondutor"> Complemento </label>
                      </div>

                      <div className="labelForm col-11 col-md-6">
                        <input
                          type="text"
                          name="emailCondutor"
                          id="emailCondutor"
                          className="form-control"
                          placeholder="E-mail"
                          {...register('emailCondutor')}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="emailCondutor"> E-mail </label>
                      </div>

                      <div className="labelForm col-11 col-md-5">
                        <input
                          type="text"
                          name="celularCondutor"
                          id="celularCondutor"
                          className="form-control"
                          placeholder="Celular"
                          {...register('celularNovoCondutor')}
                          value={telefoneNovoCondutorValue}
                          onChange={inputTelefoneNovoCondutorMask}
                          onKeyDown={handleEnterKeyPress}
                        />
                        <label htmlFor="celularCondutor"> Celular </label>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="subtitulo mb-3 mt-3" onClick={ocultaDadosAgente}>
                <h6 className="col-11 mt-1">Dados do agente</h6>
                {collapseDadosAgenteActive ? (
                  <VscTriangleRight color="#fff" className="buttonCollapse" />
                ) : (
                  <VscTriangleDown color="#fff" className="buttonCollapse" />
                )}
              </div>

              {mostraDadosAgente && (
                <div className="dadosAgente mb-2">
                  <div className="labelForm col-11">
                    <input
                      type="text"
                      name="codigoAgente"
                      placeholder="Código do agente"
                      className="form-control"
                      id="codigoAgente"
                      {...register('agenteInfrator')}
                      value={agenteInfrator}
                      onChange={onChangeAgente}
                    />
                    <label htmlFor="codigoAgente">Código *</label>

                    {filterAgente.length > 0 && (
                      <div className="optionsDiv col-12">
                        {filterAgente.map((dado) => (
                          <div className="listaOpcoes" key={dado.id} onClick={() => selecionaAgente(dado)}>
                            {dado.numero} - {dado.nome}
                          </div>
                        ))}
                      </div>
                    )}
                    {errors?.agenteInfrator?.type && agenteInfrator.length === 0 && <ErrorForm />}
                  </div>
                </div>
              )}

              {messageRequiredTipoImagens && (
                <div className="messageTipoImagensObrigatorio col-11 mb-4">
                  {srcSecondImages.length === 0 ? (
                    <span> Selecione o tipo de imagem da imagem selecionada </span>
                  ) : (
                    <span> Selecione o tipo de imagem de todas as imagens selecionadas </span>
                  )}
                </div>
              )}

              <div
                className="container"
                style={{ textAlign: 'left', marginTop: -15, paddingLeft: 35, fontSize: 14, fontWeight: 'bold' }}
              >
                <span>* Campos obrigatórios</span>
              </div>

              <div className="mt-3">
                <ButtonSubmit type="submit" disabled={messageRequiredTipoImagens} text="Inserir" />
              </div>

              {modalMedidasAdministrativas && (
                <ModalMedidasAdministrativas
                  show={modalMedidasAdministrativas}
                  onHide={() => setModalMedidasAdministrativas(false)}
                  textbutton="Confirmar"
                />
              )}

              {errorLoad && (
                <ModalMessage
                  title={['Erro na conexão com o servidor']}
                  className="modalFalha"
                  text="Houve um erro na conexão com o servidor. Por favor, tente novamente mais tarde."
                  textbutton="Fechar"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              )}

              {success && (
                <ModalMessage
                  title={[messageSuccess]}
                  className="modalSuccess"
                  show={modalSuccessShow}
                  onHide={() => [setModalSuccessShow(false), reset()]}
                  textbutton="Voltar ao registro de auto"
                />
              )}

              {falha && (
                <ModalMessage
                  title={[messageFalha]}
                  className="modalFalha"
                  show={modalFalhaShow}
                  onHide={() => [setModalFalhaShow(false)]}
                  textbutton="Tentar novamente"
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
