import Tooltip from '@mui/material/Tooltip'
import { DatePicker, Space } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import locale from 'antd/es/date-picker/locale/pt_BR'
import ModalMessage from 'components/Modais/ModalMessage'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { FaFilePdf, FaFileCsv, FaFileExcel, FaFileArchive } from 'react-icons/fa'
import './styles.css'
import 'dayjs/locale/pt-br'
import { MdOutlineFilterList } from 'react-icons/md'
import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'
import LoadComponent from 'components/loadComponent'

import OverlayLoading from '../../../components/OverlayLoading'
import { LinesPerPages } from '../../../components/Pages/LinesPerPage'
import { Pagination } from '../../../components/Pages/Pagination'
import SideBar from '../../../components/SideBarLateral'
import { AuthContext } from '../../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../../contexts/DadosContext'
import { NotificacaoContext } from '../../../contexts/NotificacaoContext'
import { LoteInfracao } from '../../../services/notificacaoService'

function GerarLote() {
  const { classSideNav } = useContext(CondicionaisFormContext)
  const { ModalidadeServico, atualizaInfracoes } = useContext(DadosContext)
  const {
    infracoesRegistradas,
    valueTipoNotificacao,
    setValueTipoNotificacao,
    atualizaLotesGerados,
    diasDefesa,
    diasVencimento,
    diasJarit,
    tiposRelatorio,
    feriadosNacionais,
    feriadosMunicipais,
    loadNotificacoes
  } = useContext(NotificacaoContext)
  const { clienteCode, Permissoes } = useContext(AuthContext)

  const [dataLimite, setDataLimite] = useState('')
  const [filterTipoServico, setFilterTipoServico] = useState('Tipo de serviço')

  const [valorTotalLote, setValorTotalLote] = useState(0)
  const [valueTipoRelatorio, setValueTipoRelatorio] = useState(1)

  const [isIconOrdenaNumero, setIsIconOrdenaNumero] = useState(true)
  const [isIconOrdenaData, setIsIconOrdenaData] = useState(true)
  const [isIconOrdenaNumeroInfracao, setIsIconOrdenaNumeroInfracao] = useState(true)
  const [isSelectAllInfracoes, setIsSelectAllInfracoes] = useState(false)
  const [isMessageError, setIsMessageError] = useState(false)
  const [isRequiredTipoNotificacao, setIsRequiredTipoNotificacao] = useState(false)
  const [isSuccessLoteGerado, setIsSuccessLoteGerado] = useState(false)
  const [isModalSuccessLoteGerado, setIsModalSuccessLoteGerado] = useState(false)
  const [isFalhaLoteGerado, setIsFalhaLoteGerado] = useState(false)
  const [isLoadingLote, setIsLoadingLote] = useState(false)
  const [isMessageDataLimite, setIsMessageDataLimite] = useState(false)

  const [checkInfracoesSelecionadas, setCheckInfracoesSelecionadas] = useState([])
  const [messageFalhaLoteGerado, setMessageFalhaLoteGerado] = useState([])
  const [feriadosDefinidos, setFeriadosDefinidos] = useState([])
  const [filterInfracoes, setFilterInfracoes] = useState([])

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentInfracoes = filterInfracoes.slice(startIndex, endIndex)
  const [feriadosTotais, setFeriadosTotais] = useState([])

  useEffect(() => {
    setCurrentPage(1)
  }, [filterTipoServico, setFilterInfracoes])

  useEffect(() => {
    setFilterInfracoes(
      infracoesRegistradas.sort((a, b) => {
        if (a.dataInfracao < b.dataInfracao) return -1
        if (a.dataInfracao > b.dataInfracao) return 1
        return 0
      })
    )
  }, [infracoesRegistradas])

  useEffect(() => {
    if (filterTipoServico === 'Todos' || filterTipoServico === 'Tipo de serviço') {
      setFilterInfracoes(infracoesRegistradas)
    } else {
      const infracoesFiltradas = infracoesRegistradas.filter(
        (value) => value.tipoServico.descricao === filterTipoServico
      )
      setFilterInfracoes(infracoesFiltradas)
    }
  }, [filterTipoServico, infracoesRegistradas])

  const handleSelectAllInfracoes = () => {
    setIsSelectAllInfracoes(!isSelectAllInfracoes)
    setCheckInfracoesSelecionadas(infracoesRegistradas.map((li) => li.id))
    setValorTotalLote(infracoesRegistradas.reduce((a, v) => a + v.valorMulta, 0))
    if (isSelectAllInfracoes) {
      setCheckInfracoesSelecionadas([])
      setValorTotalLote(0)
    }
  }

  const handleCheckInfracoes = (e: React.ChangeEvent<HTMLInputElement>, valor: number) => {
    const { id, checked } = e.target
    setCheckInfracoesSelecionadas([...checkInfracoesSelecionadas, Number(id)])
    setValorTotalLote(valorTotalLote + valor)

    if (!checked) {
      setCheckInfracoesSelecionadas(checkInfracoesSelecionadas.filter((item) => item !== Number(id)))
      setValorTotalLote(valorTotalLote - valor)
    }
  }

  useEffect(() => {
    if (checkInfracoesSelecionadas.length > 0) {
      setIsMessageError(false)
    }
  }, [checkInfracoesSelecionadas])

  useEffect(() => {
    if (valueTipoNotificacao !== 0) {
      setIsRequiredTipoNotificacao(false)
    }
  }, [valueTipoNotificacao])

  useEffect(() => {
    setFeriadosTotais(feriadosNacionais.concat(feriadosMunicipais))
  }, [feriadosNacionais, feriadosMunicipais])

  useEffect(() => {
    setFeriadosDefinidos(
      feriadosTotais.map((data) => {
        const stringData = new Date(data)
        stringData.setDate(stringData.getDate() + 1)

        return stringData.toDateString()
      })
    )
  }, [feriadosTotais])

  useEffect(() => {
    if (dataLimite !== '') {
      setIsMessageDataLimite(false)
    }
  }, [dataLimite])

  useEffect(() => {
    const current = new Date()

    if (valueTipoNotificacao === 1) {
      current.setDate(current.getDate() + diasDefesa)
    } else if (valueTipoNotificacao === 2) {
      current.setDate(current.getDate() + diasVencimento)
    } else if (valueTipoNotificacao === 3) {
      current.setDate(current.getDate() + diasJarit)
    }

    while (
      current.toDateString().match(/Sat/) ||
      current.toDateString().match(/Sun/) ||
      feriadosDefinidos.includes(current.toDateString())
    ) {
      current.setDate(current.getDate() + 1)
    }

    const dateLimit = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current
      .getDate()
      .toString()
      .padStart(2, '0')}`

    setDataLimite(dateLimit)
  }, [valueTipoNotificacao, diasDefesa, diasVencimento, diasJarit, feriadosDefinidos])

  const disabledDate: RangePickerProps['disabledDate'] = (datas) => {
    const date = new Date()

    if (valueTipoNotificacao === 1) {
      date.setDate(date.getDate() + (diasDefesa - 1))
    } else if (valueTipoNotificacao === 2) {
      date.setDate(date.getDate() + (diasVencimento - 1))
    } else if (valueTipoNotificacao === 3) {
      date.setDate(date.getDate() + (diasJarit - 1))
    }

    const verificaFeriado = `${datas.year()}-${(datas.month() + 1).toString().padStart(2, '0')}-${datas
      .date()
      .toString()
      .padStart(2, '0')}`

    const feriadosDesabilitados = feriadosTotais.find((feriado) => feriado === verificaFeriado)

    return datas.toDate() < date || datas.day() === 0 || datas.day() === 6 || feriadosDesabilitados !== undefined
  }

  function loteGerado() {
    setIsLoadingLote(false)
    setValueTipoNotificacao(1)
    setFilterTipoServico('Tipo de serviço')
    setIsSelectAllInfracoes(false)
    setIsSuccessLoteGerado(true)
    setIsModalSuccessLoteGerado(true)
    setCheckInfracoesSelecionadas([])
    setValorTotalLote(0)
    atualizaLotesGerados()
    atualizaInfracoes()
  }

  function geraLote() {
    setIsLoadingLote(true)

    const dadosLote = {
      clienteId: Number(clienteCode),
      tipoNotificacaoId: valueTipoNotificacao,
      dataLimite,
      infracoesId: checkInfracoesSelecionadas,
      tipoRelatorio: valueTipoRelatorio
    }

    LoteInfracao(dadosLote)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          valueTipoRelatorio === 0
            ? 'loteDeNotificacoes.csv'
            : valueTipoRelatorio === 1
            ? 'loteDeNotificacoes.pdf'
            : valueTipoRelatorio === 2
            ? 'loteDeNotificacoes.xls'
            : valueTipoRelatorio === 3
            ? 'loteDeNotificacoes.zip'
            : null
        )
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

        loteGerado()
      })
      .catch((error) => {
        setIsLoadingLote(false)
        setIsFalhaLoteGerado(true)
        const decoder = new TextDecoder()
        const stringsDecoded = decoder.decode(error.response.data)
        const errorMessage = JSON.parse(stringsDecoded).message
        setMessageFalhaLoteGerado([errorMessage])
      })
  }

  const onChangeDataLimite = (datas) => {
    if (datas !== null) {
      setDataLimite(`${datas.$y}-${(datas.$M + 1).toString().padStart(2, '0')}-${datas.$D.toString().padStart(2, '0')}`)
    } else {
      setDataLimite('')
    }
  }

  return (
    <>
      {isLoadingLote && <OverlayLoading />}

      <div className="content">
        {Permissoes.toString() === 'Administrador' && (
          <div className="sideNavLateral">
            <SideBar />
          </div>
        )}

        <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
          <div className="controleNotificacoes container mt-3">
            <div>
              <h2>Gerar lote de notificações</h2>
            </div>
            <form className="form mb-4">
              <div className="form-talao">
                <div className="headerPanelsInfracoes container mt-3">
                  <div className="panelFilterInfracoes col-12">
                    <div className="tituloPanel mb-2">
                      <h6>Filtros</h6>
                      <MdOutlineFilterList size={20} color="white" />
                    </div>

                    <div className="filtrosInfracoes">
                      <div className="selectFilterInfracoes col-12 col-md-3">
                        <select
                          className="col-12"
                          value={valueTipoNotificacao}
                          onChange={(e) => setValueTipoNotificacao(Number(e.target.value))}
                        >
                          <option value={0} disabled>
                            Tipo de Notificação
                          </option>
                          <option value={1}>Autuação</option>
                        </select>

                        {isRequiredTipoNotificacao && (
                          <div>
                            <span className="messageError mb-3">O tipo de notificacão é obrigatório!</span>
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-md-3 mb-1">
                        <select
                          className="col-12 mt-1"
                          value={filterTipoServico}
                          onChange={(e) => setFilterTipoServico(e.target.value)}
                        >
                          <option disabled>Tipo de serviço</option>
                          <option>Todos</option>
                          {ModalidadeServico.map((dado) => (
                            <option key={dado.id} value={dado.descricao}>
                              {dado.descricao}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-12 col-md-3">
                        <LinesPerPages
                          option="Registros p/ página"
                          defaultValue="Registros p/ página"
                          onChange={(e) => {
                            setCurrentPage(1)
                            setLinhasPerPage(Number(e.target.value))
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {filterInfracoes.length === 0 ? (
              <div className="fieldText">
                <h4 className="mt-3"> </h4>
              </div>
            ) : (
              <>
                <div className="tabelaContainer mb-1">
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th className="selectInfracoes">
                          <input
                            type="checkbox"
                            className="checkboxInfracoes"
                            checked={isSelectAllInfracoes}
                            onChange={handleSelectAllInfracoes}
                          />
                        </th>

                        <th className="headerTabela">
                          <span>N° da infração</span>
                          {isIconOrdenaNumeroInfracao ? (
                            <RiArrowDownSLine
                              size={20}
                              className="iconOrdenacao"
                              onClick={() => [
                                filterInfracoes.sort((a, b) => {
                                  if (a.numeroAuto < b.numeroAuto) return -1
                                  if (a.numeroAuto > b.numeroAuto) return 1
                                  return 0
                                }),
                                setIsIconOrdenaNumeroInfracao(false)
                              ]}
                            />
                          ) : (
                            <RiArrowUpSLine
                              size={20}
                              className="iconOrdenacao"
                              onClick={() => [
                                filterInfracoes.sort((a, b) => {
                                  if (a.numeroAuto < b.numeroAuto) return 1
                                  if (a.numeroAuto > b.numeroAuto) return -1
                                  return 0
                                }),
                                setIsIconOrdenaNumeroInfracao(true)
                              ]}
                            />
                          )}
                        </th>

                        <th className="headerTabela">
                          <span>Tipo de serviço</span>
                        </th>

                        <th className="headerTabelaData">
                          <span>Data da infração</span>
                          {isIconOrdenaData ? (
                            <RiArrowDownSLine
                              size={20}
                              className="iconOrdenacao"
                              onClick={() => [
                                filterInfracoes.sort((a, b) => {
                                  if (a.dataInfracao < b.dataInfracao) return -1
                                  if (a.dataInfracao > b.dataInfracao) return 1
                                  return 0
                                }),
                                setIsIconOrdenaData(false)
                              ]}
                            />
                          ) : (
                            <RiArrowUpSLine
                              size={20}
                              className="iconOrdenacao"
                              onClick={() => [
                                filterInfracoes.sort((a, b) => {
                                  if (a.dataInfracao < b.dataInfracao) return 1
                                  if (a.dataInfracao > b.dataInfracao) return -1
                                  return 0
                                }),
                                setIsIconOrdenaData(true)
                              ]}
                            />
                          )}
                        </th>

                        <th className="headerTabela">
                          <div className="lastHeaderTabela">
                            <span>
                              Valor
                              {isIconOrdenaNumero ? (
                                <RiArrowDownSLine
                                  size={20}
                                  className="iconOrdenacao"
                                  onClick={() => [
                                    filterInfracoes.sort((a, b) => {
                                      if (a.valorMulta < b.valorMulta) return -1
                                      if (a.valorMulta > b.valorMulta) return 1
                                      return 0
                                    }),
                                    setIsIconOrdenaNumero(false)
                                  ]}
                                />
                              ) : (
                                <RiArrowUpSLine
                                  size={20}
                                  className="iconOrdenacao"
                                  onClick={() => [
                                    filterInfracoes.sort((a, b) => {
                                      if (a.valorMulta < b.valorMulta) return 1
                                      if (a.valorMulta > b.valorMulta) return -1
                                      return 0
                                    }),
                                    setIsIconOrdenaNumero(true)
                                  ]}
                                />
                              )}
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentInfracoes.map((dado) => {
                        const anoData = dado.dataInfracao.slice(0, 4)
                        const mesData = dado.dataInfracao.slice(5, 7)
                        const diaData = dado.dataInfracao.slice(8, 10)

                        const data = `${diaData}/${mesData}/${anoData}`

                        const valorMulta =
                          dado.valorMulta !== 0
                            ? parseFloat(dado.valorMulta).toFixed(2).replace('.', ',')
                            : parseFloat(dado.valorMulta)

                        return (
                          <tr className="linhaContent" key={dado.id}>
                            <td className="checkInfracoes">
                              <input
                                type="checkbox"
                                value={dado.id}
                                id={dado.id}
                                checked={checkInfracoesSelecionadas.includes(dado.id)}
                                className="checkboxInfracoes"
                                onChange={(e) => handleCheckInfracoes(e, dado.valorMulta)}
                              />
                            </td>

                            <td className="dadoTabela">
                              <span>{dado.numeroAuto}</span>
                            </td>

                            <td className="dadoTabela">
                              <span>{dado.tipoServico.descricao}</span>
                            </td>

                            <td className="dadoTabelaData">
                              <span>{data}</span>
                            </td>

                            <td>
                              <div className="colunaExpandeInfracao">
                                <div>{`R$ ${valorMulta}`}</div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {checkInfracoesSelecionadas.length > 0 ? (
                      <tfoot>
                        <tr>
                          <td colSpan={3}>
                            <span>Infrações selecionadas: {checkInfracoesSelecionadas.length}</span>
                          </td>
                          <td>
                            <span>Valor total:</span>
                          </td>
                          <td>
                            <span className="valorTotalLote">
                              {`R$: ${valorTotalLote.toFixed(2).replace('.', ',')}`}
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                    ) : null}
                  </Table>
                </div>

                <div className="envioLote container mb-4 mt-3 col-12">
                  <div className="dataLimiteLote col-5 col-md-3">
                    <div>
                      <span className="textLabel">Data limite:</span>
                    </div>
                    <div>
                      <Space direction="vertical" className="col-12">
                        <div className="mt-4">
                          <DatePicker
                            locale={locale}
                            size="large"
                            className="col-12"
                            value={dataLimite !== '' && dayjs(dataLimite, 'YYYY-MM-DD')}
                            disabledDate={disabledDate}
                            placeholder="Data Limite"
                            onChange={onChangeDataLimite}
                            format="DD/MM/YYYY"
                          />

                          {isMessageDataLimite && (
                            <div>
                              <p className="messageError mt-1">A data limite é obrigatória!</p>
                            </div>
                          )}
                        </div>
                      </Space>
                    </div>
                  </div>

                  <div className="tipoArquivo col-5 col-md-3">
                    <div>
                      <span className="textLabel">Tipo de arquivo:</span>
                    </div>
                    <select
                      className="col-12 mt-4"
                      value={valueTipoRelatorio}
                      onChange={(e) => setValueTipoRelatorio(Number(e.target.value))}
                    >
                      <option disabled>Tipo de arquivo</option>
                      {tiposRelatorio.map((dado) => (
                        <option key={dado.id} value={dado.id}>
                          {dado.descricao.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="botaoGerarLote col-12 col-md-4">
                    <Tooltip title="Clique para baixar o lote" arrow placement="bottom">
                      <div
                        className="labelGerarLote col-7 col-md-10 mt-4"
                        onClick={
                          checkInfracoesSelecionadas.length === 0
                            ? () => setIsMessageError(true)
                            : valueTipoNotificacao === 0
                            ? () => setIsRequiredTipoNotificacao(true)
                            : dataLimite === ''
                            ? () => setIsMessageDataLimite(true)
                            : () => geraLote()
                        }
                      >
                        <span className="textGerarLote">Gerar e baixar lote</span>
                        {valueTipoRelatorio === 0 ? (
                          <FaFileCsv size={18} />
                        ) : valueTipoRelatorio === 1 ? (
                          <FaFilePdf size={18} />
                        ) : valueTipoRelatorio === 2 ? (
                          <FaFileExcel size={18} />
                        ) : (
                          <FaFileArchive size={18} />
                        )}
                      </div>
                    </Tooltip>

                    {isMessageError && (
                      <div>
                        <p className="messageError mt-1">Selecine pelo menos uma infração</p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {isSuccessLoteGerado ? (
              <ModalMessage
                title={['Lote gerado com sucesso!']}
                className="modalSuccess"
                show={isModalSuccessLoteGerado}
                onHide={() => setIsModalSuccessLoteGerado(false)}
                textbutton="OK"
                textbutton2="Ir para o ínicio"
              />
            ) : null}

            <ModalMessage
              title={messageFalhaLoteGerado}
              className="modalFalha"
              show={isFalhaLoteGerado}
              onHide={() => setIsFalhaLoteGerado(false)}
              textbutton="OK"
              textbutton2="Ir para o ínicio"
            />

            {loadNotificacoes ? (
              <LoadComponent />
            ) : (
              <Pagination
                currentPage={currentPage}
                registersPerPage={linhasPerPage}
                onPageChange={setCurrentPage}
                totalCountOfRegisters={filterInfracoes.length}
                textNoContent="Nenhuma infração encontrada"
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default GerarLote
