import axios from 'axios'
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import api from 'services/api'

import { Infracao, LotesGerados, TipoNotificacao, TiposRelatorios } from '../services/notificacaoService'
import { AuthContext } from './AuthContext'

interface NotificacaoContextProps {
  diasJarit: number
  diasVencimento: number
  diasDefesa: number
  valueTipoNotificacao: number
  infracoesRegistradas: any[]
  feriadosMunicipais: any[]
  feriadosNacionais: any[]
  tiposRelatorio: any[]
  lotesGerados: any[]
  atualizaLotesGerados: () => void
  setValueTipoNotificacao: React.Dispatch<React.SetStateAction<number>>
  setInfracoesRegistradas: React.Dispatch<React.SetStateAction<any[]>>
  loadNotificacoes: boolean
  setLoadNotificacoes: React.Dispatch<React.SetStateAction<boolean>>
}

interface NotificacaoContextProviderProps {
  children: ReactNode
}

export const NotificacaoContext = createContext({} as NotificacaoContextProps)

export function NotificacaoContextProvider({ children }: NotificacaoContextProviderProps) {
  const { clienteCode } = useContext(AuthContext)
  const [infracoesRegistradas, setInfracoesRegistradas] = useState([])
  const [valueTipoNotificacao, setValueTipoNotificacao] = useState(1)
  const [lotesGerados, setLotesGerados] = useState([])
  const [feriadosMunicipais, setFeriadosMunicipais] = useState([])
  const [feriadosNacionais, setFeriadosNacionais] = useState([])
  const [feriados, setFeriados] = useState([])
  const [tiposRelatorio, setTiposRelatorio] = useState([])
  const [diasDefesa, setDiasDefesa] = useState(0)
  const [diasVencimento, setDiasVencimento] = useState(0)
  const [diasJarit, setDiasJarit] = useState(0)

  const [loadNotificacoes, setLoadNotificacoes] = useState(true)

  useEffect(() => {
    api.get(`/Cliente/get-configuracoes?clienteId=${clienteCode}`).then((response) => {
      setDiasDefesa(response.data.data.diasDefesa)
      setDiasVencimento(response.data.data.diasVencimento)
      setDiasJarit(response.data.data.diasJARIT)
    })
  }, [clienteCode])

  useEffect(() => {
    const anoAtual = new Date()

    const apiFeriados = axios.create({
      baseURL: 'https://api.invertexto.com/v1'
    })

    api.get(`/Feriado/feriados-nacionais?ano=${anoAtual.getFullYear()}`).then((response) => {
      if (`${response.data.data}`.length === 0) {
        apiFeriados
          .get('holidays/2023?token=2156|0qwbrCuvbM9AXiyb7vjnMNwRQrl9bwFy&state=SP')
          .then((responseFeriados) => {
            setFeriados(responseFeriados.data)
          })
      }
    })
  }, [])

  useEffect(() => {
    const anoAtual = new Date()
    api.get(`/Feriado/feriados-nacionais?ano=${anoAtual.getFullYear()}`).then((response) => {
      if (`${response.data.data}`.length === 0) {
        const arrayFeriados = feriados

        const feriadosCadastrados = arrayFeriados.map((feriado) => [
          {
            data: feriado.date,
            nome: feriado.name
          }
        ])

        const feriadosFinais = feriadosCadastrados.map((datas) => ({ ...datas[0] }))

        if (feriados.length > 0) {
          api.post('Feriado', feriadosFinais).then(() => {
            setFeriadosNacionais(feriadosFinais.map((dataFeriado) => dataFeriado.data.slice(0, 10)))
          })
        }
      } else {
        setFeriadosNacionais(response.data.data.map((feriado) => feriado.data.slice(0, 10)))
      }
    })
  }, [feriados])

  useEffect(() => {
    TiposRelatorios().then((response) => {
      setTiposRelatorio(response.data.data)
    })
  }, [])

  useEffect(() => {
    api.get(`/Feriado/feriados-municipais?clienteId=${clienteCode}`).then((response) => {
      setFeriadosMunicipais(response.data.data.map((feriado) => feriado.data.slice(0, 10)))
    })
  }, [clienteCode])

  function atualizaLotesGerados() {
    LotesGerados().then((response) => {
      setLotesGerados(response.data.data)
    })
  }

  useEffect(() => {
    LotesGerados().then((response) => {
      setLotesGerados(response.data.data)
    })
  }, [])

  useEffect(() => {
    if (valueTipoNotificacao === 0) {
      Infracao().then((response) => {
        setInfracoesRegistradas(response.data.data)
        setLoadNotificacoes(false)
      })
    } else {
      TipoNotificacao(valueTipoNotificacao).then((response) => {
        setInfracoesRegistradas(response.data.data)
        setLoadNotificacoes(false)
      })
    }
  }, [valueTipoNotificacao])

  const contextValues = useMemo(
    () => ({
      infracoesRegistradas,
      valueTipoNotificacao,
      setValueTipoNotificacao,
      atualizaLotesGerados,
      lotesGerados,
      feriadosMunicipais,
      feriadosNacionais,
      tiposRelatorio,
      diasDefesa,
      diasVencimento,
      diasJarit,
      setInfracoesRegistradas,
      loadNotificacoes,
      setLoadNotificacoes
    }),
    [
      diasDefesa,
      diasJarit,
      diasVencimento,
      feriadosMunicipais,
      feriadosNacionais,
      infracoesRegistradas,
      lotesGerados,
      tiposRelatorio,
      valueTipoNotificacao,
      loadNotificacoes
    ]
  )
  return <NotificacaoContext.Provider value={contextValues}>{children}</NotificacaoContext.Provider>
}
