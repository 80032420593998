import Tooltip from '@mui/material/Tooltip'
import './styles.css'
import React, { MouseEventHandler } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { MdPerson, MdPersonOff } from 'react-icons/md'

interface IconsModifyProps {
  isInativo: boolean
  onClickEdit?: MouseEventHandler<any>
  onClickInative?: MouseEventHandler<any>
  onClickReative?: MouseEventHandler<any>
  dataFinal?: string
}

function IconsModify({ isInativo, onClickEdit, onClickInative, onClickReative, dataFinal }: IconsModifyProps) {
  return !isInativo ? (
    <div className="iconsModificacao">
      {onClickEdit ? (
        <Tooltip title="Editar" placement="top" arrow>
          <div>
            <FaRegEdit size={30} className="iconEdit col-12" onClick={onClickEdit} />
          </div>
        </Tooltip>
      ) : null}

      {onClickInative ? (
        <Tooltip title="Inativar" placement="top" arrow>
          <div>
            <MdPersonOff size={32} className="iconInative" onClick={onClickInative} />
          </div>
        </Tooltip>
      ) : null}
    </div>
  ) : (
    <div className="divOptionReativacao">
      {dataFinal ? <span className="messageInativo">Inativado em: {dataFinal}</span> : null}
      <Tooltip title="Reativar" placement="top" arrow>
        <div>
          <MdPerson size={32} className="iconReativar" onClick={onClickReative} />
        </div>
      </Tooltip>
    </div>
  )
}

export default IconsModify
