import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import './OverlayLoading.css'

function OverlayLoading() {
  return (
    <div className="loadingLote">
      <div className="elementsLoading">
        <div className="iconeLoading">
          <Spinner animation="border" />
        </div>
        <span>Processando... Por favor, aguarde</span>
      </div>
    </div>
  )
}

export default OverlayLoading
