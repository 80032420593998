import api from 'services/api'

export const EnquadramentoCliente = async (clienteCode: number) => api.get(`Enquadramento?clienteId=${clienteCode}`)

export const EnquadramentoServico = async (serviceValue: number, clienteCode: number) =>
  api.get('Enquadramento/enquadramentos-servico', {
    params: {
      tipoServico: serviceValue,
      clienteId: clienteCode
    }
  })
