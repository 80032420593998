import './styles.css'
import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@mui/material/Tooltip'
import { ErrorForm } from 'components/FormTalao/ErrorForm'
import IconsModify from 'components/IconsModify'
import LoadComponent from 'components/loadComponent'
import ModalEdicaoProprietario from 'components/Modais/ModaisProprietarios/ModalEdicaoProprietario'
import ModalMessage from 'components/Modais/ModalMessage'
import { Pagination } from 'components/Pages/Pagination'
import { SearchFilter } from 'components/SearchFilter'
import { SearchForm } from 'components/SearchForm'
import { ListsContext } from 'contexts/ListsContext'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { novoProprietario } from 'services/proprietarioService'
import * as yup from 'yup'

import ButtonSubmit from '../../components/ButtonSubmit'
import SideBar from '../../components/SideBarLateral'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'

export default function ConfiguracoesProprietarios() {
  const { classSideNav } = useContext(CondicionaisFormContext)
  const { Proprietarios } = useContext(DadosContext)

  const [cadastraProprietario, setCadastraProprietario] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const [tabelaProprietarios, setTabelaProprietarios] = useState(true)

  const [searchProprietario, setSearchProprietario] = useState('')
  const [filterSearchProprietario, setFilterSearchProprietario] = useState([])

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentProprietarios = filterSearchProprietario.slice(startIndex, endIndex)

  function ativaCadastroProprietario() {
    setCadastraProprietario(true)
    setTabelaProprietarios(false)
    setSearchActive(false)
  }

  function ativaPesquisa() {
    setSearchActive(!searchActive)
    setTabelaProprietarios(true)
    setCadastraProprietario(false)
  }

  const onChangeBuscaProprietario = (e) => {
    e.preventDefault()
    setSearchProprietario(e.target.value)

    if (!e.target.value.trim()) {
      // Retorna a lista completa de proprietários se a pesquisa estiver vazia
      setFilterSearchProprietario(Proprietarios)
      return
    }

    const Filtro = Proprietarios.filter((value) =>
      Object.values(value).some(() => {
        const searchValue = e.target.value.toLowerCase()
        const cpfCnpj = value.cpF_CNPJ?.replace(/\D/g, '')
        const email = value.email?.toLowerCase()
        const nomeProprietario = value.nome?.toLowerCase()
        const celular = value.celular?.replace(/\D/g, '')
        return (
          nomeProprietario?.includes(searchValue) ||
          cpfCnpj?.includes(searchValue.replace(/[.-]/g, '')) ||
          email?.includes(searchValue) ||
          celular?.includes(searchValue.replace(/[( )-]/g, '').trim())
        )
      })
    )

    setFilterSearchProprietario(Filtro)
  }

  useEffect(() => {
    setFilterSearchProprietario(Proprietarios)
  }, [Proprietarios])

  useEffect(() => {
    if (searchProprietario.length > 0) {
      ativaPesquisa()
    }
    // eslint-disable-next-line
  }, [searchProprietario])

  useEffect(() => {
    setCurrentPage(1)
  }, [linhasPerPage])

  return (
    <div className="content">
      <div className="sideNavLateral">
        <SideBar />
      </div>

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2>Configurações de Proprietários</h2>
          <SearchForm
            value={searchProprietario}
            onChangeBusca={onChangeBuscaProprietario}
            defaultValueRegisters={linhasPerPage}
            onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
            placeholder="Pesquisar por Nome, CPF/CNPJ, E-mail ou Celular"
            name="buscaProprietário"
          >
            <div className="itemMenuNavegacao">
              {tabelaProprietarios ? (
                <Tooltip title="Adicionar proprietário" placement="bottom" arrow>
                  <div>
                    <BsFillPersonPlusFill size={25} onClick={ativaCadastroProprietario} className="iconNavegacao" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                  <div>
                    <BiArrowBack
                      size={25}
                      onClick={() => [setTabelaProprietarios(true), setCadastraProprietario(false)]}
                      className="iconNavegacao"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </SearchForm>

          {tabelaProprietarios && (
            <ListagemProprietarios
              filtro={filterSearchProprietario}
              setFiltro={setFilterSearchProprietario}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              linhasPerPage={linhasPerPage}
              proprietariosAtuais={currentProprietarios}
            />
          )}

          {cadastraProprietario && <FormNovoProprietario arrayProprietarios={Proprietarios} />}
        </div>
      </div>
    </div>
  )
}

interface FormNovoProprietarioProps {
  readonly arrayProprietarios: any[]
}

function FormNovoProprietario({ arrayProprietarios }: FormNovoProprietarioProps) {
  const {
    atualizaProprietarios,
    cpfNovoProprietarioValue,
    inputCpfNovoProprietarioMask,
    cepNovoProprietarioValue,
    telefoneNovoProprietarioValue,
    inputCepNovoProprietarioMask,
    inputTelefoneNovoProprietarioMask,
    setCpfNovoProprietarioValue,
    setCepNovoProprietarioValue,
    setTelefoneNovoProprietarioValue,
    textMunicipio,
    setTextMunicipio,
    onChangeMunicipio,
    filterMunicipio,
    selecionaMunicipio,
    idMunicipio,
    uf
  } = useContext(DadosContext)

  const [successEditProprietario, setSuccessEditProprietario] = useState(false)
  const [falhaEditProprietario, setFalhaEditProprietario] = useState(false)
  const [messageModalEditProprietario, setMessageModalEditProprietario] = useState([])

  function submitCadastroProprietario() {
    setSuccessEditProprietario(true)
    setTextMunicipio('')
    atualizaProprietarios()
  }

  const validaCriacaoProprietario = yup.object({
    nome: yup.string().required(),
    cpF_CNPJ: yup.string().required(),
    endereco: yup.string().required(),
    municipio: yup.string().required(),
    cep: yup.string().required(),
    bairro: yup.string().required()
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaCriacaoProprietario)
  })

  const formCriaProprietario = (data) => {
    // Verifica se o CPF/CNPJ já existe no objeto Proprietarios
    const proprietarioExistente = arrayProprietarios.find(
      (p) => p.cpF_CNPJ.replace(/\D/g, '') === data.cpF_CNPJ.replace(/[.-]/g, '').trim()
    )

    if (proprietarioExistente) {
      // Se o CPF/CNPJ já existir, retorna um erro
      setMessageModalEditProprietario(['O CPF/CNPJ informado já foi cadastrado anteriormente.'])
      setFalhaEditProprietario(true)
    } else {
      novoProprietario(data, uf, idMunicipio)
        .then((response) => {
          if (response.status === 200) {
            setMessageModalEditProprietario([response.data.message])
            submitCadastroProprietario()
          }
        })
        .catch((error) => {
          if (error.response.status === 400 || error.response.status === 500) {
            if (error.response.data.message) {
              setMessageModalEditProprietario([error.response.data.message])
              setFalhaEditProprietario(true)
            } else {
              const mensagensErro = Object.values(error.response.data.errors)
              setMessageModalEditProprietario(mensagensErro.map((mensagens) => mensagens.toString()))
              setFalhaEditProprietario(true)
            }
          }
        })
    }
  }

  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4" onSubmit={handleSubmit(formCriaProprietario)} autoComplete="false">
        <div className="subtitulo mb-2">
          <h6 className="mt-1">Cadastrar novo proprietário</h6>
        </div>

        <div className="formCriaRegistro">
          <div className="labelForm col-5">
            <input
              type="text"
              className="form-control"
              name="nomeNovoProprietario"
              id="nomeNovoProprietario"
              placeholder="Nome"
              {...register('nome')}
            />
            <label htmlFor="nomeNovoProprietario">Nome</label>
            {errors?.nome?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="cpfNovoProprietario"
              id="cpfNovoProprietario"
              placeholder="CPF/CNPJ"
              {...register('cpF_CNPJ')}
              value={cpfNovoProprietarioValue}
              onChange={inputCpfNovoProprietarioMask}
            />
            <label htmlFor="cpfNovoProprietario">CPF/CNPJ</label>
            {errors?.cpF_CNPJ?.type && cpfNovoProprietarioValue.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="cepNovoProprietario"
              id="cepNovoProprietario"
              placeholder="CEP"
              {...register('cep')}
              value={cepNovoProprietarioValue}
              onChange={inputCepNovoProprietarioMask}
            />
            <label htmlFor="cepNovoProprietario">CEP</label>
            {errors?.cep?.type && cepNovoProprietarioValue.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-8">
            <input
              type="text"
              className="form-control"
              name="enderecoNovoProprietario"
              id="enderecoNovoProprietario"
              placeholder="Endereço"
              {...register('endereco')}
            />
            <label htmlFor="enderecoNovoProprietario">Endereço</label>
            {errors?.endereco?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="numeroEnderecoNovoProprietario"
              id="numeroEnderecoNovoProprietario"
              placeholder="N°"
              {...register('numeroEndereco')}
            />
            <label htmlFor="numeroEnderecoNovoProprietario">N°</label>
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="complementoEnderecoNovoProprietario"
              id="complementoEnderecoNovoProprietario"
              placeholder="Complemento"
              {...register('complemento')}
            />
            <label htmlFor="complementoEnderecoNovoProprietario">Complemento</label>
          </div>

          <div className="labelForm col-5">
            <input
              type="text"
              className="municipios form-control"
              name="municipioNovoProprietario"
              id="municipioNovoProprietario"
              placeholder="Município"
              {...register('municipio')}
              value={textMunicipio}
              onChange={onChangeMunicipio}
            />
            <label htmlFor="municipioNovoProprietario"> Município </label>

            {filterMunicipio.length > 0 && (
              <div className="optionsMunicipios col-12">
                {filterMunicipio.map((dado) => (
                  <div className="listaMunicipios" key={dado.id} onClick={() => selecionaMunicipio(dado)}>
                    {dado.nome}
                  </div>
                ))}
              </div>
            )}

            {errors?.municipio?.type && textMunicipio.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-2">
            <input
              type="text"
              className="form-control"
              name="estadoNovoProprietario"
              id="estadoNovoProprietario"
              placeholder="Estado"
              {...register('estadoNovoProprietario')}
              value={uf}
              readOnly
              disabled
            />
            <label htmlFor="estadoNovoProprietario">Estado</label>
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="bairroNovoProprietario"
              id="bairroNovoProprietario"
              placeholder="Bairro"
              {...register('bairro')}
            />
            <label htmlFor="bairroNovoProprietario">Bairro</label>
            {errors?.bairro?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="emailNovoProprietario"
              id="emailNovoProprietario"
              placeholder="E-Mail"
              {...register('email')}
            />
            <label htmlFor="emailNovoProprietario">E-Mail</label>
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="celularNovoProprietario"
              id="celularNovoProprietario"
              placeholder="Nome"
              {...register('celular')}
              value={telefoneNovoProprietarioValue}
              onChange={inputTelefoneNovoProprietarioMask}
            />
            <label htmlFor="celularNovoProprietario">Celular</label>
          </div>

          <div className="buttonCriaRegistro col-12">
            <ButtonSubmit type="submit" text="Cadastrar" />
          </div>

          <ModalMessage
            title={messageModalEditProprietario}
            className="modalSuccess"
            show={successEditProprietario}
            onHide={() => [
              setSuccessEditProprietario(false),
              reset(),
              setCpfNovoProprietarioValue(''),
              setCepNovoProprietarioValue(''),
              setTelefoneNovoProprietarioValue('')
            ]}
            textbutton="Voltar ao registro de proprietário"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalEditProprietario}
            className="modalFalha"
            show={falhaEditProprietario}
            onHide={() => setFalhaEditProprietario(false)}
            textbutton="Tentar novamente"
            textbutton2="Ir para o ínicio"
          />
        </div>
      </form>
    </div>
  )
}

interface ListagemProprietariosProps {
  readonly filtro: any[]
  readonly setFiltro: React.Dispatch<React.SetStateAction<any[]>>
  readonly currentPage: number
  readonly setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  readonly linhasPerPage: number
  readonly proprietariosAtuais: any[]
}

function ListagemProprietarios({
  filtro,
  setFiltro,
  currentPage,
  setCurrentPage,
  linhasPerPage,
  proprietariosAtuais
}: ListagemProprietariosProps) {
  const { showIcon, setShowIcon, showModalEdit, setShowModalEdit, mostraIcon, mostraEdicao } = useContext(ListsContext)

  interface TableState {
    data: any[]
    sortedData: any[]
    sortDirection: 'asc' | 'desc' | null
    sortColumn: string | null
  }

  const [tableData, setTableData] = useState<TableState>({
    data: [],
    sortedData: [],
    sortDirection: null,
    sortColumn: null
  })

  const sortData = (column) => {
    // Determina a direção da ordenação com base no estado atual da tabela
    const direction = tableData.sortColumn === column && tableData.sortDirection === 'asc' ? 'desc' : 'asc'
    // Faz uma cópia dos dados da tabela filtrados para ordenar sem afetar o estado original
    let sortedData = filtro.slice()

    // Ordena os dados de acordo com os valores da coluna especificada
    sortedData = sortedData.sort((a, b) => {
      // Obtém os valores da coluna para comparação
      let valA = a[column]
      let valB = b[column]

      // Se o valor for nulo ou vazio, substitui por um caractere que não pertence ao alfabeto
      if (valA === null || valA === '') {
        valA = direction === 'asc' ? '\uffff' : ''
      } else {
        // Converte o valor para minúsculo antes da comparação
        valA = valA.toLowerCase()
      }

      if (valB === null || valB === '') {
        valB = direction === 'asc' ? '\uffff' : ''
      } else {
        valB = valB.toLowerCase()
      }

      // Compara os valores para determinar a ordem de ordenação
      if (valA < valB) {
        return direction === 'asc' ? -1 : 1
      }
      if (valA > valB) {
        return direction === 'asc' ? 1 : -1
      }
      // Se os valores forem iguais, não há alteração na ordem de ordenação
      return 0
    })

    // Atualiza o estado da tabela com a coluna e a direção de ordenação
    setTableData({
      ...tableData,
      sortColumn: column,
      sortDirection: direction
    })

    // Atualiza os dados filtrados da tabela com a nova ordem
    setFiltro(sortedData)
  }

  const { loadProprietarios } = useContext(DadosContext)
  return (
    <>
      <div className="tableDefault container" id="tabelaProprietarios">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela" onClick={() => sortData('nome')}>
                Nome
                {tableData.sortDirection === 'desc' && tableData.sortColumn === 'nome' ? (
                  <SearchFilter ordenaDescrescente={false} />
                ) : (
                  <SearchFilter ordenaDescrescente />
                )}
              </th>

              <th className="headerTabela" onClick={() => sortData('cpF_CNPJ')}>
                CPF/CNPJ
                {tableData.sortDirection === 'desc' && tableData.sortColumn === 'cpF_CNPJ' ? (
                  <SearchFilter ordenaDescrescente={false} />
                ) : (
                  <SearchFilter ordenaDescrescente />
                )}
              </th>

              <th className="headerTabela" onClick={() => sortData('endereco')}>
                Endereço
                {tableData.sortDirection === 'desc' && tableData.sortColumn === 'endereco' ? (
                  <SearchFilter ordenaDescrescente={false} />
                ) : (
                  <SearchFilter ordenaDescrescente />
                )}
              </th>

              <th className="headerTabela" onClick={() => sortData('email')}>
                E-mail
                {tableData.sortDirection === 'desc' && tableData.sortColumn === 'email' ? (
                  <SearchFilter ordenaDescrescente={false} />
                ) : (
                  <SearchFilter ordenaDescrescente />
                )}
              </th>

              <th className="headerTabela" onClick={() => sortData('celular')}>
                Celular
                {tableData.sortDirection === 'desc' && tableData.sortColumn === 'celular' ? (
                  <SearchFilter ordenaDescrescente={false} />
                ) : (
                  <SearchFilter ordenaDescrescente />
                )}
              </th>
              <th className="headerTabela">Editar</th>
            </tr>
          </thead>
          <tbody>
            {proprietariosAtuais.map((dado, valor) => (
              <tr className="linhaDefault" key={dado.id}>
                <td className="dadoTabela">
                  <span className="textTableDefault">{dado.nome}</span>
                </td>
                <td className="dadoTabela">
                  <span className="textTableDefault">{dado.cpF_CNPJ}</span>
                </td>
                <td className="dadoTabela">
                  <span className="textTableDefault">{dado.endereco}</span>
                </td>
                <td className="dadoTabela">
                  <span className="textTableDefault">{dado.email || ''}</span>
                </td>
                <td className="dadoTabela">
                  <span id="telefoneProprietario" className="textTableDefault">
                    {dado.celular || ''}
                  </span>
                </td>
                <td style={{ width: '5%', textAlign: 'center' }}>
                  <IconsModify isInativo={false} onClickEdit={mostraEdicao(valor)} />
                </td>

                {showModalEdit[valor] && (
                  <td className="modalEdit">
                    <ModalEdicaoProprietario
                      show={!!showModalEdit}
                      onHide={() => [setShowModalEdit({}), setShowIcon({})]}
                      onClick={() => [setShowModalEdit({}), setShowIcon({})]}
                      textbutton="Salvar"
                      textbutton2="Cancelar"
                      nome={dado.nome}
                      cpf={dado.cpF_CNPJ}
                      id={dado.id}
                      municipio={dado.municipio.nome || ''}
                      municipioId={dado.municipioId || 0}
                      endereco={dado.endereco || ''}
                      numeroEndereco={dado.numeroEndereco || ''}
                      complemento={dado.complemento || ''}
                      cep={dado.cep || ''}
                      bairro={dado.bairro || ''}
                      estado={dado.municipio.uf || ''}
                      email={dado.email || ''}
                      celular={dado.celular || ''}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {loadProprietarios ? (
        <LoadComponent />
      ) : (
        <Pagination
          currentPage={currentPage}
          registersPerPage={linhasPerPage}
          textNoContent="Nenhum proprietário encontrado"
          onPageChange={setCurrentPage}
          totalCountOfRegisters={filtro.length}
        />
      )}
    </>
  )
}
