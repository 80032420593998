/* eslint-disable import-helpers/order-imports */
import './styles.css'
import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import { DadosContext } from 'contexts/DadosContext'
import Table from 'react-bootstrap/Table'
import ModalMessage from 'components/Modais/ModalMessage'
import { useForm } from 'react-hook-form'
import { ErrorForm } from 'components/FormTalao/ErrorForm'
import { PermissionariosContext } from 'contexts/PermissionariosContext'
import { edicaoCondutor } from 'services/condutorService'

function FormTabelaEdicaoCondutor() {
  const {
    Condutores,
    condutoresSelecionados,
    atualizaCondutores,
    telefoneEdicaoCondutorValue,
    setTelefoneEdicaoCondutorValue,
    inputTelefoneEdicaoCondutorMask,
    cepEdicaoCondutorValue,
    setCepEdicaoCondutorValue,
    inputCepEdicaoCondutorMask,
    textMunicipio,
    setTextMunicipio,
    onChangeMunicipio,
    filterMunicipio,
    selecionaMunicipio,
    idMunicipio,
    uf,
    setUf
  } = useContext(DadosContext)

  const { condutoresInativos, setCondutoresInativos } = useContext(PermissionariosContext)

  const [idCondutorSelecionado, setIdCondutorSelecionado] = useState(null)
  const [detailsCondutor, setDetailsCondutor] = useState({})
  const [nomeCondutor, setNomeCondutor] = useState('')
  const [codigoCondutor, setCodigoCondutor] = useState(0)
  const [cpfCondutor, setCpfCondutor] = useState('')
  const [cnhCondutor, setCnhCondutor] = useState('')
  const [categoriaCnhCondutor, setCategoriaCnhCondutor] = useState('')
  const [emailCondutor, setEmailCondutor] = useState('')
  const [enderecoCondutor, setEnderecoCondutor] = useState('')
  const [numeroEnderecoCondutor, setNumeroEnderecoCondutor] = useState('')
  const [complementoCondutor, setComplementoCondutor] = useState('')
  const [bairroCondutor, setBairroCondutor] = useState('')

  const [successEdicaoCondutor, setSuccessEdicaoCondutor] = useState(false)
  const [falhaEdicaoCondutor, setFalhaEdicaoCondutor] = useState(false)
  const [messageModalEdicaoCondutor, setMessageModalEdicaoCondutor] = useState([])

  const mostraDetalhesCondutor = (dado, valor) => () => {
    setDetailsCondutor((state) => ({
      [valor]: !state[valor]
    }))

    setIdCondutorSelecionado(dado.id)
    setNomeCondutor(dado.nome)
    setCodigoCondutor(dado.codigo)
    setCpfCondutor(dado.cpF_CNPJ)
    setCnhCondutor(dado.cnh)
    setCategoriaCnhCondutor(dado.categoriaCNH)
    setEmailCondutor(dado.email)
    setTelefoneEdicaoCondutorValue(dado.celular)
    setCepEdicaoCondutorValue(dado.cep)
    setEnderecoCondutor(dado.endereco)
    setBairroCondutor(dado.bairro)
    setNumeroEnderecoCondutor(dado.numeroEndereco)
    setComplementoCondutor(dado.complemento)
    setUf(dado.municipio.uf)
    setTextMunicipio(dado.municipio.nome)
  }

  const onChangeCondutoresInativos = (e) => {
    const id = e

    if (condutoresInativos.length === 0) {
      setCondutoresInativos([id])
    } else if (condutoresInativos.includes(id)) {
      setCondutoresInativos(condutoresInativos.filter((item) => item !== id))
    } else {
      setCondutoresInativos([...condutoresInativos, id])
    }
  }

  function clearInputControlados() {
    setUf('')
    setTelefoneEdicaoCondutorValue('')
    setCepEdicaoCondutorValue('')
  }

  const {
    handleSubmit,
    formState: { errors }
  } = useForm({})

  function atualizaCondutor() {
    edicaoCondutor(
      idCondutorSelecionado,
      nomeCondutor,
      cnhCondutor,
      categoriaCnhCondutor,
      enderecoCondutor,
      numeroEnderecoCondutor,
      complementoCondutor,
      cepEdicaoCondutorValue,
      bairroCondutor,
      uf,
      idMunicipio,
      emailCondutor,
      telefoneEdicaoCondutorValue
    )
      .then((response) => {
        if (response.status === 200) {
          setSuccessEdicaoCondutor(true)
          setMessageModalEdicaoCondutor([response.data.message])
          atualizaCondutores()
          clearInputControlados()
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalEdicaoCondutor([error.response.data.message])
            setFalhaEdicaoCondutor(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalEdicaoCondutor(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaEdicaoCondutor(true)
          }
        }
      })
  }

  return (
    <div className="tableDefault container" id="tabelaEdicaoCondutor">
      <Table striped bordered>
        <thead>
          <tr>
            <th className="headerTabela">Código</th>

            <th className="headerTabela">Nome</th>

            <th className="headerTabela">CPF/CNPJ</th>

            <th className="headerTabela">Endereço</th>

            <th className="headerTabela">E-mail</th>

            <th className="headerTabela">Celular</th>

            <th className="headerTabela"> </th>
          </tr>
        </thead>
        {Condutores.map(
          (dado, valor) =>
            condutoresSelecionados.includes(dado.id) && (
              <tbody key={dado.id}>
                <tr className="linhaDefault">
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>
                      {dado.codigo || ''}
                    </span>
                  </td>
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>{dado.nome}</span>
                  </td>
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>
                      {dado.cpF_CNPJ}
                    </span>
                  </td>
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>
                      {dado.endereco}
                    </span>
                  </td>
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>{dado.email}</span>
                  </td>
                  <td className="dadoTabela">
                    <span className={condutoresInativos.includes(dado.id) ? 'textTableInativo' : ''}>
                      {dado.celular}
                    </span>
                  </td>
                  <td className="dadoTabela">
                    <span className="detailsProprietario" onClick={mostraDetalhesCondutor(dado, valor)}>
                      Detalhes
                    </span>
                  </td>
                </tr>

                {detailsCondutor[valor] && (
                  <tr>
                    <td colSpan={7}>
                      <form className="form-talao" onSubmit={handleSubmit(atualizaCondutor)}>
                        <div className="labelForm col-5 col-md-9">
                          <input
                            type="text"
                            name="nomeEdicaoCondutor"
                            id="nomeEdicaoCondutor"
                            className="form-control"
                            value={nomeCondutor}
                            onChange={(e) => setNomeCondutor(e.target.value)}
                            required
                          />
                          <label htmlFor="nomeEdicaoCondutor"> Nome </label>
                          {errors?.nomeEdicaoCondutor?.type && nomeCondutor.length === 0 && <ErrorForm />}
                        </div>

                        <div className="labelForm col-5 col-md-3">
                          <input
                            type="text"
                            name="codigoCondutor"
                            id="codigoCondutor"
                            className="form-control"
                            placeholder="Código"
                            value={codigoCondutor || ''}
                            onChange={(e) => setCodigoCondutor(Number(e.target.value))}
                            readOnly
                            disabled
                          />
                          <label htmlFor="codigoCondutor"> Código </label>
                        </div>

                        <div className="labelForm col-5 col-md-4">
                          <input
                            type="text"
                            name="cpfCondutor"
                            id="cpfCondutor"
                            className="form-control"
                            value={cpfCondutor || ''}
                            disabled
                          />
                          <label htmlFor="cpfCondutor"> CPF/CNPJ </label>
                        </div>

                        <div className="labelForm col-5 col-md-4">
                          <input
                            type="text"
                            name="cnhCondutor"
                            id="cnhCondutor"
                            className="form-control"
                            value={cnhCondutor}
                            disabled
                          />
                          <label htmlFor="cnhCondutor"> CNH </label>
                        </div>

                        <div className="labelForm col-5 col-md-4">
                          <input
                            type="text"
                            name="categoriaCnhCondutor"
                            id="categoriaCnhCondutor"
                            className="form-control"
                            value={
                              categoriaCnhCondutor === '1'
                                ? 'A'
                                : categoriaCnhCondutor === '2'
                                ? 'B'
                                : categoriaCnhCondutor === '3'
                                ? 'C'
                                : categoriaCnhCondutor === '4'
                                ? 'D'
                                : categoriaCnhCondutor === '5'
                                ? 'E'
                                : categoriaCnhCondutor
                            }
                            disabled
                          />
                          <label htmlFor="categoriaCnhCondutor"> Categoria/CNH </label>
                        </div>

                        <div className="labelForm col-5 col-md-6">
                          <input
                            type="text"
                            name="emailEdicaoCondutor"
                            id="emailEdicaoCondutor"
                            className="form-control"
                            value={emailCondutor || ''}
                            onChange={(e) => setEmailCondutor(e.target.value)}
                          />
                          <label htmlFor="emailEdicaoCondutor"> E-mail </label>
                        </div>

                        <div className="labelForm col-5 col-md-6">
                          <input
                            type="text"
                            name="telefoneEdicaoCondutor"
                            id="telefoneEdicaoCondutor"
                            className="form-control"
                            value={telefoneEdicaoCondutorValue || ''}
                            onChange={inputTelefoneEdicaoCondutorMask}
                          />
                          <label htmlFor="telefoneEdicaoCondutor"> Celular </label>
                        </div>

                        <div className="labelForm col-5 col-md-4">
                          <input
                            type="text"
                            name="cepEdicaoCondutor"
                            id="cepEdicaoCondutor"
                            className="form-control"
                            value={cepEdicaoCondutorValue || ''}
                            onChange={inputCepEdicaoCondutorMask}
                            required
                          />
                          <label htmlFor="cepEdicaoCondutor"> CEP </label>
                          {errors?.cepEdicaoCondutor?.type && cepEdicaoCondutorValue.length === 0 && <ErrorForm />}
                        </div>

                        <div className="labelForm col-5 col-md-8">
                          <input
                            type="text"
                            name="enderecoEdicaoCondutor"
                            id="enderecoEdicaoCondutor"
                            className="form-control"
                            value={enderecoCondutor || ''}
                            onChange={(e) => setEnderecoCondutor(e.target.value)}
                            required
                          />
                          <label htmlFor="enderecoEdicaoCondutor"> Endereço </label>
                          {errors?.enderecoEdicaoCondutor?.type && enderecoCondutor.length === 0 && <ErrorForm />}
                        </div>

                        <div className="labelForm col-5 col-md-2">
                          <input
                            type="text"
                            name="numeroEnderecoEdicaoCondutor"
                            id="numeroEnderecoEdicaoCondutor"
                            className="form-control"
                            value={numeroEnderecoCondutor || ''}
                            onChange={(e) => setNumeroEnderecoCondutor(e.target.value)}
                          />
                          <label htmlFor="numeroEnderecoEdicaoCondutor"> Número </label>
                        </div>

                        <div className="labelForm col-5 col-md-5">
                          <input
                            type="text"
                            name="complementoEdicaoCondutor"
                            id="complementoEdicaoCondutor"
                            className="form-control"
                            value={complementoCondutor || ''}
                            onChange={(e) => setComplementoCondutor(e.target.value)}
                          />
                          <label htmlFor="complementoEdicaoCondutor"> Complemento </label>
                        </div>

                        <div className="labelForm col-5 col-md-5">
                          <input
                            type="text"
                            name="bairroEdicaoCondutor"
                            id="bairroEdicaoCondutor"
                            className="form-control"
                            defaultValue={bairroCondutor || ''}
                            onChange={(e) => setBairroCondutor(e.target.value)}
                            required
                          />
                          <label htmlFor="bairroEdicaoCondutor"> Bairro </label>
                          {errors?.bairroEdicaoCondutor?.type && bairroCondutor.length === 0 && <ErrorForm />}
                        </div>

                        <div className="labelForm col-9">
                          <input
                            type="text"
                            className="municipios form-control"
                            name="municipioEdicaoCondutor"
                            id="municipioEdicaoCondutor"
                            placeholder="Município"
                            value={textMunicipio || ''}
                            onChange={onChangeMunicipio}
                            required
                          />
                          <label htmlFor="municipioEdicaoCondutor"> Município </label>

                          {filterMunicipio.length > 0 && (
                            <div className="optionsMunicipios col-12">
                              {filterMunicipio.map((municipio) => (
                                <div
                                  className="listaMunicipios"
                                  key={municipio.id}
                                  onClick={() => selecionaMunicipio(municipio)}
                                >
                                  {municipio.nome}
                                </div>
                              ))}
                            </div>
                          )}

                          {errors?.municipioEdicaoCondutor?.type && textMunicipio.length === 0 && <ErrorForm />}
                        </div>

                        <div className="labelForm col-3">
                          <input
                            type="text"
                            className="form-control"
                            name="estadoEnderecoEdicaoCondutor"
                            id="estadoEnderecoEdicaoCondutor"
                            placeholder="Estado"
                            value={uf}
                            readOnly
                            disabled
                          />
                          <label htmlFor="estadoEnderecoEdicaoCondutor">Estado</label>
                        </div>

                        <div className="botaoFormDetails">
                          {condutoresInativos.includes(dado.id) ? (
                            <Button
                              onClick={() => [onChangeCondutoresInativos(dado.id), setDetailsCondutor(false)]}
                              sx={{ mr: 1 }}
                              className="buttonBack"
                            >
                              Reativar condutor
                            </Button>
                          ) : (
                            <Button
                              onClick={() => [onChangeCondutoresInativos(dado.id), setDetailsCondutor(false)]}
                              sx={{ mr: 1 }}
                              className="buttonBack"
                            >
                              Desativar condutor
                            </Button>
                          )}

                          <Button type="submit" sx={{ mr: 1 }} className="buttonSaveDetails">
                            Salvar
                          </Button>
                        </div>
                      </form>
                    </td>
                  </tr>
                )}
              </tbody>
            )
        )}
      </Table>

      <ModalMessage
        title={messageModalEdicaoCondutor}
        className="modalSuccess"
        show={successEdicaoCondutor}
        onHide={() => [setSuccessEdicaoCondutor(false), setDetailsCondutor(false)]}
        textbutton="Ok"
        textbutton2="Ir para o ínicio"
      />

      <ModalMessage
        title={messageModalEdicaoCondutor}
        className="modalFalha"
        show={falhaEdicaoCondutor}
        onHide={() => [setFalhaEdicaoCondutor(false), setDetailsCondutor(false)]}
        textbutton="Ok"
        textbutton2="Ir para o ínicio"
      />
    </div>
  )
}

export default FormTabelaEdicaoCondutor
