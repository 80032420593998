import { StepLabel } from '@mui/material'
import Step from '@mui/material/Step'
import Stepper from '@mui/material/Stepper'
import { PermissionariosContext } from 'contexts/PermissionariosContext'
import React, { useContext } from 'react'
import { FaCar, FaRegIdCard, FaUserAlt } from 'react-icons/fa'

interface TimelineProps {
  readonly onClickStepOne: React.MouseEventHandler<SVGElement>
  readonly onClickStepTwo: React.MouseEventHandler<SVGElement>
  readonly onClickStepThree: React.MouseEventHandler<SVGElement>
  readonly className: string
  readonly classNameLastStep: string
}

export default function Timeline({
  className,
  classNameLastStep,
  onClickStepOne,
  onClickStepTwo,
  onClickStepThree
}: TimelineProps) {
  const { steps, activeStep, completed } = useContext(PermissionariosContext)
  return (
    <Stepper nonLinear activeStep={activeStep} className={className} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label} completed={completed[index]}>
          <StepLabel
            icon={
              index === 0 ? (
                <FaCar size={30} color={activeStep === 0 ? '#508276' : 'black'} onClick={onClickStepOne} />
              ) : index === 1 ? (
                <FaUserAlt size={30} color={activeStep === 1 ? '#508276' : 'black'} onClick={onClickStepTwo} />
              ) : index === 2 ? (
                <FaRegIdCard
                  size={30}
                  color={activeStep === 2 ? '#508276' : 'black'}
                  onClick={onClickStepThree}
                  className={classNameLastStep}
                />
              ) : null
            }
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
