import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './Login.css'
import ButtonSubmit from '../../components/ButtonSubmit'
import { ErrorMessage } from '../../components/ErrorMessage'
import { Input } from '../../components/Input'
import ModalMessage from '../../components/Modais/ModalMessage'
import { PasswordInput } from '../../components/PasswordInput'
import { AuthContext } from '../../contexts/AuthContext'

function Login() {
  const navigate = useNavigate()

  const { loginUsuario, errorLoad, modalShow, setModalShow } = useContext(AuthContext)

  const [userName, setUserName] = useState('')
  const [senha, setSenha] = useState('')
  const [errorLogin, setErrorLogin] = useState('')

  function validaDados() {
    if (!userName && !senha) {
      setErrorLogin('Preencha todos os campos')
      return false
    } else if (!userName) {
      setErrorLogin('Usuário é obrigatório')
      return false
    } else if (!senha) {
      setErrorLogin('A senha é obrigatória')
      return false
    } else {
      return true
    }
  }

  async function loginForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!validaDados()) return

    const response = await loginUsuario(userName, senha)
    if (response === 'sucesso') {
      navigate('/digitalizacao')
    } else {
      setErrorLogin(response)
    }
  }

  return (
    <div className="login">
      <div className="headerLogin">
        <h4>MONITRAN - Sistema de Permissionários</h4>
      </div>

      <div className="loginUser">
        {errorLoad ? (
          <ModalMessage
            title={['Erro na conexão com o servidor.']}
            className="modalFalha"
            text="Houve um erro na conexão com o servidor. Por favor, tente novamente mais tarde."
            textbutton="Fechar"
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : null}

        <h3>Login</h3>

        <form onSubmit={loginForm} className="formLogin col-11 mt-3">
          <div className="labelLogin">
            <label htmlFor="usuário" className="inputfield">
              Usuário
            </label>
            <Input value={userName} onChange={(e) => setUserName(e.target.value)} />
          </div>
          <div className="labelLogin">
            <label htmlFor="senha" className="inputfield">
              Senha
            </label>
            <PasswordInput value={senha} onChange={(e) => setSenha(e.target.value)} />
          </div>

          <ErrorMessage text={errorLogin} />

          <div className="col-12">
            <ButtonSubmit type="submit" text="Entrar" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
