import ModalMessage from 'components/Modais/ModalMessage'
import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { InativarAgente } from 'services/agenteService'

interface ModalInativacaoAgenteProps {
  readonly nome: string
  readonly show: boolean
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalInativacaoAgente({
  nome,
  id,
  show,
  textbutton,
  textbutton2,
  onHide,
  onClick,
  ...rest
}: ModalInativacaoAgenteProps) {
  const { atualizaAgentes } = useContext(DadosContext)

  const [successInativaAgente, setSuccessInativaAgente] = useState(false)
  const [falhaInativaAgente, setFalhaInativaAgente] = useState(false)
  const [messageModalInativaAgente, setMessageModalInativaAgente] = useState([])

  async function inativaAgente() {
    InativarAgente(id)
      .then((response) => {
        setMessageModalInativaAgente([response.data.message])
        setSuccessInativaAgente(true)
      })
      .catch((error) => {
        setMessageModalInativaAgente([error.response.data.message])
        setFalhaInativaAgente(true)
      })
  }

  return (
    <>
      {!successInativaAgente && !falhaInativaAgente && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja inativar o agente {nome}?</h4>
            </div>

            <div className="buttonsModalInative">
              <Button onClick={inativaAgente} variant="none" className="buttonInative">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalInativaAgente}
        className="modalSuccess"
        show={successInativaAgente}
        onHide={() => [setSuccessInativaAgente(false), onClick(), atualizaAgentes()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalInativaAgente}
        className="modalFalha"
        show={falhaInativaAgente}
        onHide={() => [setFalhaInativaAgente(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalInativacaoAgente
