/* eslint-disable camelcase */
import api from './api'

// #region Novo proprietário
export const novoProprietario = async (
  { nome, cpF_CNPJ, endereco, numeroEndereco, complemento, cep, bairro, email, celular },
  estado,
  municipioId
) =>
  api.post('Proprietario/cadastrar-proprietario', {
    nome: nome.trim(),
    cpF_CNPJ: cpF_CNPJ.trim(),
    endereco: endereco.trim(),
    numeroEndereco: numeroEndereco.length > 0 ? numeroEndereco.trim() : null,
    complemento: complemento.length > 0 ? complemento.trim() : null,
    cep: cep.trim(),
    bairro: bairro.trim(),
    estado,
    municipioId,
    email: email.length > 0 ? email.trim() : null,
    celular: celular.length > 0 ? celular.trim() : null
  })
// #endregion

// #region Edita proprietário
export const edicaoProprietario = async (
  id: number,
  nome: string,
  endereco: string,
  numeroEndereco: string,
  complemento: string,
  cep: string,
  bairro: string,
  estado: string,
  municipioId: number,
  email: string,
  celular: string
) =>
  api.put('Proprietario/atualizar-proprietario', {
    id,
    nome: nome.trim(),
    endereco: endereco.trim(),
    numeroEndereco: numeroEndereco.length > 0 ? numeroEndereco.trim() : null,
    complemento: complemento.length > 0 ? complemento.trim() : null,
    cep: cep.trim(),
    bairro: bairro.trim(),
    estado,
    municipioId,
    email: email.length > 0 ? email.trim() : null,
    celular: celular.length > 0 ? celular.trim() : null
  })
// #endregion
