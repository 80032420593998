/* eslint-disable camelcase */
import api from './api'

// #region Novo condutor
export const novoCondutor = async (
  {
    nome,
    cnh,
    cpF_CNPJ,
    categoriaCnhNovoCondutor,
    endereco,
    numeroEndereco,
    complemento,
    cep,
    bairro,
    email,
    celular,
    codigo
  },
  estado,
  municipioId
) => {
  const categoriaCnh =
    categoriaCnhNovoCondutor === 1
      ? 'A'
      : categoriaCnhNovoCondutor === 2
      ? 'B'
      : categoriaCnhNovoCondutor === 3
      ? 'C'
      : categoriaCnhNovoCondutor === 4
      ? 'D'
      : categoriaCnhNovoCondutor === 5
      ? 'E'
      : null

  return api.post('Condutor/cadastrar-condutor', {
    nome: nome.trim(),
    cnh: cnh.trim(),
    cpF_CNPJ: cpF_CNPJ.trim(),
    categoriaCnh,
    endereco: endereco.trim(),
    numeroEndereco: numeroEndereco.length > 0 ? numeroEndereco.trim() : null,
    complemento: complemento.length > 0 ? complemento.trim() : null,
    cep: cep.trim(),
    bairro: bairro.trim(),
    estado,
    municipioId,
    email: email.length > 0 ? email.trim() : null,
    celular: celular.length > 0 ? celular.trim() : null,
    codigo: codigo.trim()
  })
}
// #endregion

// #region Edita condutor
export const edicaoCondutor = async (
  id: number,
  nome: string,
  cnh: string,
  categoriaCNH: string,
  endereco: string,
  numeroEndereco: string,
  complemento: string,
  cep: string,
  bairro: string,
  estado: string,
  municipioId: number,
  email: string,
  celular: string
) =>
  api.put('Condutor/atualizar-condutor', {
    id,
    nome: nome.trim(),
    cnh: cnh.trim(),
    categoriaCNH,
    endereco: endereco.trim(),
    numeroEndereco: numeroEndereco === null ? null : numeroEndereco.length > 0 ? numeroEndereco.trim() : null,
    complemento: complemento === null ? null : complemento.length > 0 ? complemento.trim() : null,
    cep: cep.trim(),
    bairro: bairro.trim(),
    estado,
    municipioId,
    email: email === null ? null : email.length > 0 ? email.trim() : null,
    celular: celular === null ? null : celular.length > 0 ? celular.trim() : null
  })

export const InativarCondutor = async (id: number) => api.put(`Condutor/inativa-condutor?condutorId=${id}`)

export const ReativarCondutor = async (id: number) => api.put(`Condutor/reativa-condutor?condutorId=${id}`)

// #endregion
