import { yupResolver } from '@hookform/resolvers/yup'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { scroller } from 'react-scroll'
import api from 'services/api'
import { PesquisaPorPlaca } from 'services/digitacaoService'
import * as yup from 'yup'

import { AuthContext } from './AuthContext'
import { CondicionaisFormContext } from './CondicionaisFormContext'
import { DadosContext } from './DadosContext'
import { EnquadramentoContext } from './EnquadramentoContext'

interface DigitacaoContextProps {
  sendValuesOptionsInfracao: (dado: any) => void
  selectedOptionsInfracao: any[]
  setSelectedOptionsInfracao: any
  idProprietario: number
  agenteInfrator: string
  proprietarioAutuado: string
  enderecoProprietarioAutuado: string
  verificaTipoImagens: () => void
  messageRequiredTipoImagens: boolean
  success: boolean
  submitForm: () => void
  modalSuccessShow: boolean
  messageMedidasAdmin: string
  medidasAdministrativas: any[]
  idInfracao: number
  filterAgente: any[]
  filterProprietario: any[]
  showSelectLocal: boolean
  typeInput: string
  cpfCondutorAuto: string
  cnhCondutorAuto: string
  categoriaCnhCondutorAuto: string
  filterCondutorAuto: any[]
  register: any
  enviaForm: any
  handleSubmit: any
  getVeiculos: any
  errors: any
  setModalSuccessShow: any
  reset: any
  onChangeAgente: any
  selecionaAgente: any
  onChangeProprietario: any
  selecionaProprietario: any
  nomeCondutorAuto: any
  onChangeNomeCondutorAuto: any
  selecionaCondutor: any
}

interface DigitacaoContextProviderProps {
  children: ReactNode
}

export const DigitacaoContext = createContext({} as DigitacaoContextProps)

export function DigitacaoContextProvider({ children }: DigitacaoContextProviderProps) {
  const { serviceValue } = useContext(AuthContext)
  const {
    setCnhProprietarioValue,
    setTextMunicipio,
    setUf,
    setPlaca,
    setChassi,
    idVeiculo,
    idCondutorSelecionado,
    setCondutoresSelecionados,
    Placa,
    idMarcaModelo,
    setIdMarcaModelo,
    chassi,
    idCor,
    idMunicipio,
    dataInfracao,
    hora,
    uf,
    setModalMedidasAdministrativas,
    setMessageSuccess,
    setMessageFalha,
    setFalha,
    setModalFalhaShow,
    setTextMarcaModelo,
    setTextCor,
    setEnderecoProprietario,
    setCpfProprietarioValue,
    setNomeProprietario,
    setIdVeiculo,
    condutoresSelecionados,
    setIdCondutorSelecionado,
    setErroVeiculo,
    Agentes,
    Proprietarios,
    setCpfCondutorValue,
    setCnhCondutorValue,
    setHora,
    prefixoVeiculo,
    setPrefixoVeiculo,
    atualizaPermissionarios
  } = useContext(DadosContext)

  const {
    arrayImagens,
    selectedImages,
    srcSecondImages,
    tipoImagemImageDefault,
    setSrcImages,
    setSrcSecondImages,
    setSelectedImages,
    setMostraOpcao,
    hideOption
  } = useContext(CondicionaisFormContext)

  const {
    idEnquadramento,
    descricaoEnquadramento,
    setnumeroEnquadramento,
    setDescricaoEnquadramento,
    setIsEnquadramentoRequired
  } = useContext(EnquadramentoContext)

  const [typeInput, setTypeInput] = useState('ReadOnly')
  const [nomeCondutorAuto, setNomeCondutorAuto] = useState('')
  const [cpfCondutorAuto, setCpfCondutorAuto] = useState('')
  const [cnhCondutorAuto, setCnhCondutorAuto] = useState('')
  const [categoriaCnhCondutorAuto, setCategoriaCnhCondutorAuto] = useState('')
  const [agenteInfrator, setAgenteInfrator] = useState('')
  const [proprietarioAutuado, setProprietarioAutuado] = useState('')
  const [enderecoProprietarioAutuado, setEnderecoProprietarioAutuado] = useState('')
  const [messageMedidasAdmin, setMessageMedidasAdmin] = useState('')
  const [idProprietario, setIdProprietario] = useState(0)
  const [idAgente, setIdAgente] = useState(0)
  const [idInfracao, setIdInfracao] = useState(0)
  const [success, setSuccess] = useState(false)
  const [messageRequiredTipoImagens, setMessageRequiredTipoImagens] = useState(false)
  const [modalSuccessShow, setModalSuccessShow] = useState(false)
  const [showSelectLocal, setShowSelectLocal] = useState(false)
  const [medidasAdministrativas, setMedidasAdministrativas] = useState([])
  const [selectedOptionsInfracao, setSelectedOptionsInfracao] = useState([])
  const [filterAgente, setFilterAgente] = useState([])
  const [filterProprietario, setFilterProprietario] = useState([])
  const [valuesOptionsInfracao, setValuesOptionsInfracao] = useState([])
  const [filterCondutorAuto, setFilterCondutorAuto] = useState([])

  const onChangeNomeCondutorAuto = useCallback(
    (e) => {
      e.preventDefault()
      setNomeCondutorAuto(e.target.value)

      if (idCondutorSelecionado !== null) {
        const Filtro = condutoresSelecionados.filter(
          (value) =>
            value.nome.toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.codigo?.toString().includes(e.target.value)
        )

        setFilterCondutorAuto(Filtro)
      }
    },
    [condutoresSelecionados, idCondutorSelecionado]
  )

  const selecionaCondutor = useCallback(
    (dado) => {
      if (dado !== null && dado !== 0) {
        setNomeCondutorAuto(dado.nome)
        setIdCondutorSelecionado(dado.id)
        setCpfCondutorAuto(dado.cpF_CNPJ)
        setCnhCondutorAuto(dado.cnh)
        setCategoriaCnhCondutorAuto(dado.categoriaCNH)
        setFilterCondutorAuto([])
      } else {
        setIdCondutorSelecionado(dado)
        setNomeCondutorAuto('')
      }
    },
    [setIdCondutorSelecionado]
  )

  useEffect(() => {
    if (!nomeCondutorAuto) {
      setFilterCondutorAuto([])
      setCpfCondutorAuto('')
      setCnhCondutorAuto('')
      setCategoriaCnhCondutorAuto('')
    }
  }, [nomeCondutorAuto])

  const sendValuesOptionsInfracao = useCallback(
    (dado) => {
      if (dado.value === 20 || dado.value === 40) {
        setValuesOptionsInfracao(selectedOptionsInfracao.filter((value) => value.value !== dado.value))
      }
    },
    [selectedOptionsInfracao]
  )

  const validaForm = yup.object({
    servicoId: yup.number().required('O ID do serviço é obrigatório'),
    municipio: yup.string(),
    placa: yup.string(),
    endereco: yup.string().required('O endereço é obrigatório'),
    agenteInfrator: yup.string().required('O agente infrator é obrigatório'),
    proprietarioAutuado: yup.string()
  })

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaForm)
  })

  const submitForm = useCallback(() => {
    setSuccess(true)
    setModalSuccessShow(true)
    atualizaPermissionarios()
    scrollInicio()
    setFocus('numeroAuto')
    setTextMunicipio('')
    setUf('')
    setPlaca('')
    setChassi('')
    setPrefixoVeiculo('')
    setIdVeiculo(0)
    setIdCondutorSelecionado(0)
    setProprietarioAutuado('')
    setIdProprietario(0)
    setEnderecoProprietarioAutuado('')
    setTextMarcaModelo('')
    setTextCor('')
    setPrefixoVeiculo('')
    setNomeProprietario('')
    setHora('')
    setnumeroEnquadramento('')
    setNomeCondutorAuto('')
    setCpfCondutorAuto('')
    setCnhCondutorAuto('')
    setCategoriaCnhCondutorAuto('')
    setDescricaoEnquadramento('')
    setCpfProprietarioValue('')
    setCnhProprietarioValue('')
    setCnhCondutorValue('')
    setCpfCondutorValue('')
    setEnderecoProprietario('')
    setAgenteInfrator('')
    setCondutoresSelecionados([])
    setSelectedOptionsInfracao([])
    setValuesOptionsInfracao([])

    if (selectedImages) {
      hideOption()
      setMostraOpcao(true)
      setSelectedImages(null)
      setSrcImages([])
      setSrcSecondImages([])
    }
  }, [
    hideOption,
    selectedImages,
    setChassi,
    setCnhCondutorValue,
    setCnhProprietarioValue,
    setCondutoresSelecionados,
    setCpfCondutorValue,
    setCpfProprietarioValue,
    setDescricaoEnquadramento,
    setEnderecoProprietario,
    setFocus,
    setHora,
    setIdCondutorSelecionado,
    setIdVeiculo,
    setMostraOpcao,
    setNomeProprietario,
    setPlaca,
    setPrefixoVeiculo,
    setSelectedImages,
    setSrcImages,
    setSrcSecondImages,
    setTextCor,
    setTextMarcaModelo,
    setTextMunicipio,
    setUf,
    setnumeroEnquadramento,
    atualizaPermissionarios
  ])

  const verificaTipoImagens = useCallback(() => {
    if (selectedImages) {
      if (srcSecondImages.length > 0) {
        srcSecondImages.map((image) =>
          image.tipoImagemId ? setMessageRequiredTipoImagens(false) : setMessageRequiredTipoImagens(true)
        )
      } else if (tipoImagemImageDefault === 0) {
        setMessageRequiredTipoImagens(true)
      } else {
        setMessageRequiredTipoImagens(false)
      }
    }
  }, [selectedImages, srcSecondImages, tipoImagemImageDefault])

  useEffect(() => {
    if (!selectedImages) {
      setMessageRequiredTipoImagens(false)
    } else {
      verificaTipoImagens()
    }
  }, [messageRequiredTipoImagens, selectedImages, verificaTipoImagens])

  useEffect(() => {
    if (!agenteInfrator) {
      setFilterAgente([])
      setIdAgente(0)
    }
  }, [agenteInfrator])

  useEffect(() => {
    if (!proprietarioAutuado) {
      setFilterProprietario([])
      setIdProprietario(0)
    }
  }, [proprietarioAutuado])

  function selecionaAgente(dado) {
    setAgenteInfrator(`${dado.numero} - ${dado.nome}`)
    setIdAgente(dado.id)
    setFilterAgente([])
  }

  function selecionaProprietario(dado) {
    setProprietarioAutuado(`${dado.nome} - ${dado.cpF_CNPJ}`)
    setIdProprietario(dado.id)
    setEnderecoProprietarioAutuado(dado.endereco)
    setFilterProprietario([])
  }

  useEffect(() => {
    if (!Placa) {
      setErroVeiculo('')
    }
  }, [Placa])

  const getVeiculos = useCallback(async () => {
    if (Placa.length === 0) {
      setChassi('')
      setTextMarcaModelo('')
      setTextCor('')
      setNomeProprietario('')
      setEnderecoProprietario('')
      setIdVeiculo(0)
      setPrefixoVeiculo('')
      setNomeCondutorAuto('')
      setCpfCondutorAuto('')
      setCnhCondutorAuto('')
      setCategoriaCnhCondutorAuto('')
      setCondutoresSelecionados([])
    } else {
      try {
        const response = await PesquisaPorPlaca(Placa)
        const veiculos = response.data.data

        setIdVeiculo(veiculos.id)
        setTextCor(veiculos.cor.descricao)
        setTextMarcaModelo(veiculos.marcaModelo.descricao)
        setIdMarcaModelo(veiculos.marcaModeloId)
        if (veiculos.prefixo !== null) {
          setPrefixoVeiculo(veiculos.prefixo)
        }
      } catch (error) {
        if (Placa.length > 0) {
          setErroVeiculo('Veículo não encontrado! Deseja cadastrar um novo veículo?')
          setChassi('')
          setTextMarcaModelo('')
          setTextCor('')
          setPrefixoVeiculo('')
          setNomeProprietario('')
          setCpfProprietarioValue('')
          setCnhProprietarioValue('')
          setEnderecoProprietario('')
          setCondutoresSelecionados([])
          setIdVeiculo(0)
          setIdProprietario(0)
        }
      }
    }
  }, [
    Placa,
    setChassi,
    setCnhProprietarioValue,
    setCondutoresSelecionados,
    setCpfProprietarioValue,
    setEnderecoProprietario,
    setErroVeiculo,
    setIdMarcaModelo,
    setIdVeiculo,
    setNomeProprietario,
    setPrefixoVeiculo,
    setTextCor,
    setTextMarcaModelo
  ])
  // Esta função gera uma observação concatenando partes específicas do objeto data em uma única string.
  const generateObservacao = (data) => {
    const parts = []

    if (data.observacao !== '') {
      parts.push(data.observacao.trim())
    }

    if (data.selectedRemovido !== '') {
      parts.push(data.selectedRemovido)
    }

    if (data.selectedVistoria !== '') {
      parts.push(data.selectedVistoria)
    }

    if (data.selectedPrazo !== '') {
      parts.push(data.selectedPrazo)
    }

    return parts.length > 0 ? parts.join(' - ') : null
  }

  const onChangeAgente = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      setAgenteInfrator(e.target.value)

      const Filtro = Agentes.filter((value) => value.numero.toString().includes(e.target.value))

      setFilterAgente(Filtro)
    },
    [Agentes]
  )

  const onChangeProprietario = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      setProprietarioAutuado(e.target.value)
      if (e.target.value.length > 2) {
        const Filtro = Proprietarios.filter(
          (value) =>
            value.nome.toString().toLowerCase().includes(e.target.value.toLowerCase()) ||
            value.cpF_CNPJ.includes(e.target.value)
        )

        setFilterProprietario(Filtro)
      } else {
        setFilterProprietario([])
      }
    },
    [Proprietarios]
  )

  const scrollInicio = () => {
    scroller.scrollTo('topo-talao', {
      duration: 400,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  useEffect(() => {
    if (serviceValue === 50) {
      setTypeInput('')
      setIsEnquadramentoRequired(false)
    } else {
      setTypeInput('ReadOnly')
      setPlaca('')
      setTextCor('')
      setTextMarcaModelo('')
      setNomeProprietario('')
      setTextMunicipio('')
      setNomeCondutorAuto('')
      setIdCondutorSelecionado(0)
      setCpfCondutorAuto('')
      setCnhCondutorAuto('')
      setUf('')
      setCnhProprietarioValue('')
      setCpfProprietarioValue('')
      setEnderecoProprietario('')
      setIsEnquadramentoRequired(true)
    }

    if (serviceValue === 10) {
      setShowSelectLocal(true)
    } else {
      setShowSelectLocal(false)
    }
  }, [
    serviceValue,
    setCnhProprietarioValue,
    setCpfProprietarioValue,
    setEnderecoProprietario,
    setIdCondutorSelecionado,
    setIsEnquadramentoRequired,
    setNomeProprietario,
    setPlaca,
    setTextCor,
    setTextMarcaModelo,
    setTextMunicipio,
    setUf
  ])

  const enviaForm = useCallback(
    (data) => {
      const dadosComImagem = {
        numeroAuto: data.numeroAuto.length > 0 ? data.numeroAuto.trim() : null,
        imagens: arrayImagens.filter((imgs) => imgs.tipoImagemId !== null),
        tiposInfracaoId: valuesOptionsInfracao,
        tipoServicoId: serviceValue,
        veiculoId: idVeiculo,
        prefixo: prefixoVeiculo.length > 0 ? prefixoVeiculo.trim() : null,
        condutorId: idCondutorSelecionado === 0 ? null : idCondutorSelecionado,
        pesquisaClandestinoId: idProprietario,
        pesquisaClandestino:
          serviceValue === 50
            ? {
                placa: Placa,
                numeroChassi: chassi,
                proprietarioId: idProprietario,
                proprietario: {
                  nome: 'Jose',
                  cpf: '098.900.470-80',
                  cnh: '5626596230',
                  endereco: 'rua clandestina',
                  numeroEndereco: '20',
                  complemento: null,
                  cep: '08887-090',
                  bairro: 'Centro',
                  estado: 'SP',
                  municipioId: 6477,
                  email: null,
                  celular: null
                },
                marcaModeloId: idMarcaModelo,
                corId: idCor,
                municipioId: idMunicipio
              }
            : null,
        dataInfracao: `${dataInfracao}T${hora}`,
        sentido: Number(data.sentido) ? Number(data.sentido) : null,
        endereco: data.endereco.trim(),
        numeroEndereco: data.numeroEndereco.trim().length > 0 ? data.numeroEndereco.trim() : 'S/N',
        enquadramentoId: idEnquadramento,
        descricao: descricaoEnquadramento.trim(),
        observacao: generateObservacao(data),
        condutor:
          idCondutorSelecionado === null && data.cpfcondutor
            ? {
                nome: data.nomeCondutor.trim(),
                cnh: data.cnhCondutor.trim(),
                cpF_CNPJ: data.cpfCondutor.trim(),
                categoriaCNH: data.categoriaCNH.toString(),
                endereco: data.enderecoCondutor.trim(),
                numeroEndereco:
                  data.numeroEnderecoCondutor.trim().length > 0 ? data.numeroEnderecoCondutor.trim() : 'S/N',
                complemento: data.complementoCondutor.length > 0 ? data.complementoCondutor : null,
                cep: data.cepNovoCondutor.trim(),
                bairro: data.bairroCondutor.trim(),
                estado: uf,
                municipioId: idMunicipio,
                email: data.emailCondutor.length > 0 ? data.emailCondutor : null,
                celular: data.celularNovoCondutor.length > 0 ? data.celularNovoCondutor : null,
                codigo: null
              }
            : null,
        agenteId: idAgente,
        proprietarioId: idProprietario
      }
      if (messageRequiredTipoImagens === false) {
        api
          .post('Infracao', dadosComImagem)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.data.temMedidaAdministrativa === true) {
                setMessageMedidasAdmin(response.data.message)
                setMedidasAdministrativas(response.data.data.medidasAdministrativas)
                setIdInfracao(response.data.data.id)
                setFocus('numeroAuto')
                scrollInicio()
                setModalMedidasAdministrativas(true)
              } else {
                setMessageSuccess(response.data.message)
                submitForm()
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 400 || error.response.status === 500) {
              if (error.response.data.errors) {
                const mensagensErro = Object.values(error.response.data.errors)
                setMessageFalha(mensagensErro.map((mensagens) => mensagens.toString()))
                setFalha(true)
                setModalFalhaShow(true)
              } else {
                setMessageFalha([error.response.data.message])
                setFalha(true)
                setModalFalhaShow(true)
              }
            }
          })
      }
    },
    [
      Placa,
      arrayImagens,
      chassi,
      dataInfracao,
      descricaoEnquadramento,
      hora,
      idAgente,
      idCondutorSelecionado,
      idCor,
      idEnquadramento,
      idMarcaModelo,
      idMunicipio,
      idProprietario,
      idVeiculo,
      messageRequiredTipoImagens,
      prefixoVeiculo,
      serviceValue,
      setFalha,
      setFocus,
      setMessageFalha,
      setMessageSuccess,
      setModalFalhaShow,
      setModalMedidasAdministrativas,
      submitForm,
      uf,
      valuesOptionsInfracao
    ]
  )

  const contextValues = useMemo(
    () => ({
      sendValuesOptionsInfracao,
      selectedOptionsInfracao,
      setSelectedOptionsInfracao,
      idProprietario,
      agenteInfrator,
      proprietarioAutuado,
      enderecoProprietarioAutuado,
      verificaTipoImagens,
      messageRequiredTipoImagens,
      success,
      submitForm,
      filterAgente,
      filterProprietario,
      showSelectLocal,
      typeInput,
      cpfCondutorAuto,
      cnhCondutorAuto,
      categoriaCnhCondutorAuto,
      filterCondutorAuto,
      messageMedidasAdmin,
      modalSuccessShow,
      medidasAdministrativas,
      idInfracao,
      register,
      enviaForm,
      handleSubmit,
      getVeiculos,
      errors,
      setModalSuccessShow,
      reset,
      onChangeAgente,
      selecionaAgente,
      onChangeProprietario,
      selecionaProprietario,
      nomeCondutorAuto,
      onChangeNomeCondutorAuto,
      selecionaCondutor
    }),
    [
      agenteInfrator,
      proprietarioAutuado,
      enderecoProprietarioAutuado,
      categoriaCnhCondutorAuto,
      cnhCondutorAuto,
      cpfCondutorAuto,
      enviaForm,
      errors,
      filterAgente,
      filterProprietario,
      filterCondutorAuto,
      getVeiculos,
      handleSubmit,
      idInfracao,
      idProprietario,
      medidasAdministrativas,
      messageMedidasAdmin,
      messageRequiredTipoImagens,
      modalSuccessShow,
      nomeCondutorAuto,
      onChangeAgente,
      onChangeProprietario,
      onChangeNomeCondutorAuto,
      register,
      reset,
      selecionaCondutor,
      selectedOptionsInfracao,
      sendValuesOptionsInfracao,
      showSelectLocal,
      submitForm,
      success,
      typeInput,
      verificaTipoImagens
    ]
  )

  return <DigitacaoContext.Provider value={contextValues}>{children}</DigitacaoContext.Provider>
}
