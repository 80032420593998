import './styles.css'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import type { DatePickerProps } from 'antd'
import { DatePicker, Space } from 'antd'
import CadastroVeiculos from 'components/CadastroVeiculo'
import FormTabelaEdicaoCondutor from 'components/FormTabelaEdicaoCondutor'
import FormTabelaEdicaoProprietarios from 'components/FormTabelaEdicaoProprietarios'
import LoadComponent from 'components/loadComponent'
import ModalAdicionaCondutor from 'components/Modais/ModaisCondutores/ModalAdicionaCondutor'
import ModalAdicionaProprietario from 'components/Modais/ModaisProprietarios/ModalAdicionaProprietario'
import ModalMessage from 'components/Modais/ModalMessage'
import NovoPermissionario from 'components/NovoPermissionario'
import { Pagination } from 'components/Pages/Pagination'
import { SearchFilter } from 'components/SearchFilter'
import { SearchForm } from 'components/SearchForm'
import Timeline from 'components/Timeline'
import { DadosContext } from 'contexts/DadosContext'
import { LoadDataContext } from 'contexts/LoadDataContext'
import { PermissionariosContext } from 'contexts/PermissionariosContext'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { FaCar } from 'react-icons/fa'
import { CadastroPermissionario, EdicaoPermissionario, EdicaoVeiculo } from 'services/permissionariosService'

import SideBar from '../../components/SideBarLateral'
import { AuthContext } from '../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'

export default function ControlePermissionarios() {
  const { Permissoes } = useContext(AuthContext)
  const { allRegistersPermissionarios, loadAllPermissionarios } = useContext(LoadDataContext)
  const { classSideNav, tabelaVeiculos, telaConfiguracoes, cadastraVeiculos, cadastroPermissionario } =
    useContext(CondicionaisFormContext)

  const { Permissionarios, setPermissionarios } = useContext(DadosContext)

  const { setIdVeiculoSelecionado } = useContext(PermissionariosContext)

  const [filterVeiculos, setFilterVeiculos] = useState([])

  const [placaVeiculoSelecionado, setPlacaVeiculoSelecionado] = useState('')
  const [anoLicenciamentoVeiculoSelecionado, setAnoLicenciamentoVeiculoSelecionado] = useState(0)
  const [idCorVeiculoSelecionado, setIdCorVeiculoSelecionado] = useState(0)

  useEffect(() => {
    if (loadAllPermissionarios) {
      setFilterVeiculos(Permissionarios)
    }
  }, [Permissionarios, loadAllPermissionarios])

  useEffect(() => {
    setFilterVeiculos(allRegistersPermissionarios)
  }, [allRegistersPermissionarios])

  return (
    <div className="content">
      {Permissoes.toString() === 'Administrador' && (
        <div className="sideNavLateral">
          <SideBar />
        </div>
      )}

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2 className="textTitle">Controle de Veículos</h2>
          {tabelaVeiculos && (
            <ListagemVeiculos
              filtro={filterVeiculos}
              Permissionarios={Permissionarios}
              loadAllPermissionarios={loadAllPermissionarios}
              setId={setIdVeiculoSelecionado}
              setAnoLicenciamento={setAnoLicenciamentoVeiculoSelecionado}
              setIdCor={setIdCorVeiculoSelecionado}
              setPlaca={setPlacaVeiculoSelecionado}
              setFiltro={setFilterVeiculos}
            />
          )}
          {telaConfiguracoes && (
            <ConfiguracoesVeiculos
              placaVeiculo={placaVeiculoSelecionado}
              anoLicenciamento={anoLicenciamentoVeiculoSelecionado}
              idCor={idCorVeiculoSelecionado}
              setIdCor={setIdCorVeiculoSelecionado}
              filtro={filterVeiculos}
              setPlacaVeiculo={setPlacaVeiculoSelecionado}
              setAnoLicenciamento={setAnoLicenciamentoVeiculoSelecionado}
            />
          )}
          {cadastraVeiculos && <CadastroVeiculos />}
          {cadastroPermissionario && <NovoPermissionario />}
        </div>
      </div>
    </div>
  )
}

interface ListagemVeiculosProps {
  readonly setId: React.Dispatch<React.SetStateAction<number>>
  readonly setIdCor: React.Dispatch<React.SetStateAction<number>>
  readonly setAnoLicenciamento: React.Dispatch<React.SetStateAction<number>>
  readonly setPlaca: React.Dispatch<React.SetStateAction<string>>
  readonly filtro: any[]
  readonly setFiltro: React.Dispatch<React.SetStateAction<any[]>>
  readonly Permissionarios: any[]
  readonly loadAllPermissionarios: boolean
}

function ListagemVeiculos({
  setId,
  setIdCor,
  setPlaca,
  setAnoLicenciamento,
  filtro,
  setFiltro,
  Permissionarios,
  loadAllPermissionarios
}: ListagemVeiculosProps) {
  const { tabelaVeiculos, ativaConfiguracoesVeiculo, ativaCadastroVeiculo, ativaCadastroPermissionario } =
    useContext(CondicionaisFormContext)

  const { totalPermissionarios } = useContext(DadosContext)

  const [loadData, setLoadData] = useState(false)

  const { loadPermissionarios } = useContext(DadosContext)

  const [searchVeiculo, setSearchVeiculo] = useState('')

  const [iconOrdenaPlaca, setIconOrdenaPlaca] = useState(true)
  const [iconOrdenaTipoServico, setIconOrdenaTipoServico] = useState(true)
  const [iconOrdenaMarca, setIconOrdenaMarca] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)

  const [linhasPerPage, setLinhasPerPage] = useState(25)

  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentVeiculos = filtro.slice(startIndex, endIndex)

  const onChangeBuscaVeiculo = (e: { preventDefault: () => void; target: { value: string } }) => {
    e.preventDefault()
    setSearchVeiculo(e.target.value)

    const Filtro = Permissionarios.filter(
      (value) =>
        value.veiculo.placa?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value.veiculo.prefixo?.toLowerCase().includes(e.target.value.toLowerCase())
    )

    setFiltro(Filtro)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [linhasPerPage, searchVeiculo])

  useEffect(() => {
    if (loadAllPermissionarios) {
      setLoadData(true)
    } else {
      setLoadData(false)
    }
  }, [loadAllPermissionarios])

  function trocaTela(dado, id) {
    setId(id)
    setPlaca(dado.veiculo.placa)
    setAnoLicenciamento(dado.veiculo.anoLicenciamento)
    setIdCor(dado.veiculo.corId)
    ativaConfiguracoesVeiculo()
  }

  return (
    <>
      <SearchForm
        value={searchVeiculo}
        onChangeBusca={onChangeBuscaVeiculo}
        defaultValueRegisters={linhasPerPage}
        onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
        placeholder="Placa ou prefixo do veículo"
        name="buscaVeiculo"
      >
        <div className="itemMenuNavegacao">
          {tabelaVeiculos ? (
            <Tooltip title="Cadastrar novo veículo" placement="bottom" arrow>
              <div>
                <FaCar size={25} onClick={ativaCadastroVeiculo} className="iconNavegacao" />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title="Voltar a consulta" placement="bottom" arrow>
              <div>
                <BiArrowBack size={25} className="iconNavegacao" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className="itemMenuNavegacao">
          {tabelaVeiculos && (
            <Tooltip title="Adicionar permissionário" placement="bottom" arrow>
              <div>
                <BsFillPersonPlusFill size={25} onClick={ativaCadastroPermissionario} className="iconNavegacao" />
              </div>
            </Tooltip>
          )}
        </div>
      </SearchForm>

      <div className="tableDefault container">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela">
                <span>
                  Placa
                  {iconOrdenaPlaca ? (
                    <SearchFilter
                      ordenaDescrescente
                      onClickDecrescente={() => [
                        filtro.sort((a, b) => (a.veiculo.placa.toUpperCase() > b.veiculo.placa.toUpperCase() ? 1 : -1)),
                        setIconOrdenaPlaca(false)
                      ]}
                    />
                  ) : (
                    <SearchFilter
                      ordenaDescrescente={false}
                      onClickCrescente={() => [
                        filtro.sort((a, b) => (a.veiculo.placa.toUpperCase() < b.veiculo.placa.toUpperCase() ? 1 : -1)),
                        setIconOrdenaPlaca(true)
                      ]}
                    />
                  )}
                </span>
              </th>
              <th className="headerTabela">
                <span>Prefixo</span>
              </th>
              <th className="headerTabela">
                <span>
                  Tipo de serviço
                  {iconOrdenaTipoServico ? (
                    <SearchFilter
                      ordenaDescrescente
                      onClickDecrescente={() => [
                        filtro.sort((a, b) =>
                          a.veiculo.tipoServico.descricao > b.veiculo.tipoServico.descricao ? 1 : -1
                        ),
                        setIconOrdenaTipoServico(false)
                      ]}
                    />
                  ) : (
                    <SearchFilter
                      ordenaDescrescente={false}
                      onClickCrescente={() => [
                        filtro.sort((a, b) =>
                          a.veiculo.tipoServico.descricao < b.veiculo.tipoServico.descricao ? 1 : -1
                        ),
                        setIconOrdenaTipoServico(true)
                      ]}
                    />
                  )}
                </span>
              </th>
              <th className="headerTabela">
                <span>
                  Marca/Modelo
                  {iconOrdenaMarca ? (
                    <SearchFilter
                      ordenaDescrescente
                      onClickDecrescente={() => [
                        filtro.sort((a, b) =>
                          a.veiculo.marcaModelo.descricao > b.veiculo.marcaModelo.descricao ? 1 : -1
                        ),
                        setIconOrdenaMarca(false)
                      ]}
                    />
                  ) : (
                    <SearchFilter
                      ordenaDescrescente={false}
                      onClickCrescente={() => [
                        filtro.sort((a, b) =>
                          a.veiculo.marcaModelo.descricao < b.veiculo.marcaModelo.descricao ? 1 : -1
                        ),
                        setIconOrdenaMarca(true)
                      ]}
                    />
                  )}
                </span>
              </th>
              <th className="headerTabela">
                <span>Nome do proprietário</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {currentVeiculos.map((dado) => (
              <tr key={dado.veiculoId} className="linhaDefaultClicavel" onClick={() => trocaTela(dado, dado.veiculoId)}>
                <td className="dadoTabela">{dado.veiculo.placa}</td>
                <td className="dadoTabela">{dado.veiculo.prefixo || ''}</td>
                <td className="dadoTabela">{dado.veiculo.tipoServico.descricao}</td>
                <td className="dadoTabela">{dado.veiculo.marcaModelo.descricao}</td>
                <td className="dadoTabela">
                  {dado.proprietarios.map(
                    (proprietario) =>
                      proprietario.id === dado.proprietarioAtualId && (
                        <span key={proprietario.id}>{proprietario.nome}</span>
                      )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {loadData ? (
        <LoadComponent />
      ) : (
        <Pagination
          currentPage={currentPage}
          registersPerPage={linhasPerPage}
          textNoContent="Nenhum veículo encontrado"
          onPageChange={setCurrentPage}
          totalCountOfRegisters={loadAllPermissionarios ? totalPermissionarios : filtro.length}
        />
      )}
    </>
  )
}

interface ConfiguracoesVeiculosProps {
  readonly placaVeiculo: string
  readonly anoLicenciamento: number
  readonly idCor: number
  readonly filtro: any[]
  readonly setPlacaVeiculo: React.Dispatch<React.SetStateAction<string>>
  readonly setIdCor: React.Dispatch<React.SetStateAction<number>>
  readonly setAnoLicenciamento: React.Dispatch<React.SetStateAction<number>>
}

function ConfiguracoesVeiculos({
  placaVeiculo,
  anoLicenciamento,
  idCor,
  filtro,
  setPlacaVeiculo,
  setIdCor,
  setAnoLicenciamento
}: ConfiguracoesVeiculosProps) {
  const { cor, idProprietarioAtual, proprietariosSelecionados, condutoresSelecionados, atualizaPermissionarios } =
    useContext(DadosContext)

  const { ehAtualizacao, idVeiculoSelecionado, condutoresInativos } = useContext(PermissionariosContext)

  const { voltaTabela } = useContext(CondicionaisFormContext)

  const { steps, activeStep, setActiveStep, handleComplete } = useContext(PermissionariosContext)

  const [successAtualizacaoVeiculo, setSuccessAtualizacaoVeiculo] = useState(false)
  const [falhaAtualizacaoVeiculo, setFalhaAtualizacaoVeiculo] = useState(false)
  const [messageModalAtualizacaoVeiculo, setMessageModalAtualizacaoVeiculo] = useState([])

  const [successCadastroPermissionario, setSuccessCadastroPermissionario] = useState(false)
  const [falhaCadastroPermissionario, setFalhaCadastroPermissionario] = useState(false)
  const [messageModalCadastroPermissionario, setMessageModalCadastroPermissionario] = useState([])

  const [successAtualizaPermissionario, setSuccessAtualizaPermissionario] = useState(false)
  const [falhaAtualizaPermissionario, setFalhaAtualizaPermissionario] = useState(false)
  const [messageModalAtualizaPermissionario, setMessageModalAtualizaPermissionario] = useState([])

  const [modalAdicionaProprietario, setModalAdicionaProprietario] = useState(false)
  const [modalAdicionaCondutor, setModalAdicionaCondutor] = useState(false)

  const changeAnoLicenciamento: DatePickerProps['onChange'] = (date) => {
    if (date !== null) {
      setAnoLicenciamento(date.year())
    } else {
      setAnoLicenciamento(null)
    }
  }

  function atualizaVeiculo() {
    EdicaoVeiculo(idVeiculoSelecionado, placaVeiculo, anoLicenciamento, idCor)
      .then((response) => {
        if (response.status === 200) {
          setSuccessAtualizacaoVeiculo(true)
          setMessageModalAtualizacaoVeiculo([response.data.message])
          atualizaPermissionarios()
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalAtualizacaoVeiculo([error.response.data.message])
            setFalhaAtualizacaoVeiculo(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalAtualizacaoVeiculo(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaAtualizacaoVeiculo(true)
          }
        }
      })
  }

  function enviaCadastroPermissionario() {
    CadastroPermissionario(idVeiculoSelecionado, condutoresSelecionados, Number(proprietariosSelecionados))
      .then((response) => {
        if (response.status === 200) {
          setSuccessCadastroPermissionario(true)
          setMessageModalCadastroPermissionario([response.data.message])
          atualizaPermissionarios()
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          setFalhaCadastroPermissionario(true)
          setMessageModalCadastroPermissionario([error.response.data.message])
        } else {
          const mensagensErro = Object.values(error.response.data.errors)
          setMessageModalCadastroPermissionario(mensagensErro.map((mensagens) => mensagens.toString()))
          setFalhaCadastroPermissionario(true)
        }
      })
  }

  function atualizaCadastroPermissionario() {
    EdicaoPermissionario(
      idVeiculoSelecionado,
      condutoresSelecionados,
      proprietariosSelecionados,
      idProprietarioAtual,
      condutoresInativos.length > 0 ? condutoresInativos : []
    )
      .then((response) => {
        if (response.status === 200) {
          setSuccessAtualizaPermissionario(true)
          setMessageModalAtualizaPermissionario([response.data.message])
          atualizaPermissionarios()
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          setFalhaAtualizaPermissionario(true)
          setMessageModalAtualizaPermissionario([error.response.data.message])
        } else {
          const mensagensErro = Object.values(error.response.data.errors)
          setMessageModalAtualizaPermissionario(mensagensErro.map((mensagens) => mensagens.toString()))
          setFalhaAtualizaPermissionario(true)
        }
      })
  }

  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto'
      }}
    >
      <Timeline
        className="timeLine mt-5"
        onClickStepOne={() => handleComplete(0)}
        onClickStepTwo={() => handleComplete(1)}
        onClickStepThree={proprietariosSelecionados.length > 0 ? () => handleComplete(2) : null}
        classNameLastStep={proprietariosSelecionados.length > 0 ? '' : 'etapaNaoClicavel'}
      />

      <hr className="divisor" />

      <div className="configuraVeiculo">
        <Box sx={{ mt: 2, mb: 1, py: 1, width: `${activeStep === 0 ? '100%' : '85%'}`, margin: '0 auto' }}>
          {activeStep === 0 &&
            filtro.map(
              (dado) =>
                dado.veiculoId === idVeiculoSelecionado && (
                  <div className="form-talao" key={dado.veiculoId}>
                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="placa"
                        id="placa"
                        className="form-control"
                        placeholder="Placa"
                        maxLength={7}
                        value={placaVeiculo}
                        onChange={(e) => setPlacaVeiculo(e.target.value)}
                      />
                      <label htmlFor="placa"> Placa </label>
                    </div>

                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="prefixo"
                        id="prefixo"
                        className="form-control"
                        placeholder="Prefixo"
                        value={dado.veiculo.prefixo || ''}
                        disabled
                        readOnly
                      />
                      <label htmlFor="prefixo"> Prefixo </label>
                    </div>

                    <div className="labelForm col-12 col-md-4">
                      <input
                        type="text"
                        name="renavam"
                        id="renavam"
                        className="form-control"
                        placeholder="Renavam"
                        value={dado.veiculo.renavam}
                        readOnly
                        disabled
                      />
                      <label htmlFor="renavam"> Renavam </label>
                    </div>

                    <Space direction="vertical" className="selectYears col-6 col-md-4">
                      <DatePicker
                        onChange={changeAnoLicenciamento}
                        picker="year"
                        size="large"
                        className="datePicker col-12"
                        placeholder={anoLicenciamento !== null ? anoLicenciamento.toString() : ''}
                      />
                      <label id="labelSelectAno" className="">
                        Ano de licenciamento
                      </label>
                    </Space>

                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="anoModelo"
                        id="anoModelo"
                        className="form-control"
                        placeholder="Ano de modelo"
                        value={dado.veiculo.anoModelo || ''}
                        readOnly
                        disabled
                      />
                      <label htmlFor="anoModelo"> Ano de modelo </label>
                    </div>

                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="marcaModelo"
                        id="marcaModelo"
                        className="form-control"
                        placeholder="Marca/Modelo"
                        value={dado.veiculo.marcaModelo.descricao || ''}
                        readOnly
                        disabled
                      />
                      <label htmlFor="anoModelo"> Marca/Modelo </label>
                    </div>

                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="tipoServico"
                        id="tipoServico"
                        className="form-control"
                        placeholder="Tipo de serviço"
                        value={dado.veiculo.tipoServico.descricao || ''}
                        readOnly
                        disabled
                      />
                      <label htmlFor="tipoServico"> Tipo de serviço </label>
                    </div>

                    <div className="labelForm col-6 col-md-4">
                      <input
                        type="text"
                        name="chassi"
                        id="chassi"
                        className="form-control"
                        placeholder="Chassi"
                        value={dado.veiculo.numeroChassi || ''}
                        readOnly
                        disabled
                      />
                      <label htmlFor="chassi"> Chassi </label>
                    </div>

                    <div className="labelForm col-6 col-md-4">
                      <select
                        name="corNovoVeiculo"
                        className="col-12"
                        value={idCor}
                        onChange={(e) => setIdCor(Number(e.target.value))}
                      >
                        <option disabled>Cor</option>
                        {cor.map((Cor) => (
                          <option key={Cor.id} value={Cor.id}>
                            {Cor.descricao}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )
            )}

          {activeStep === 1 && proprietariosSelecionados.length > 0 && <FormTabelaEdicaoProprietarios />}

          {activeStep === 2 && condutoresSelecionados.length > 0 && <FormTabelaEdicaoCondutor />}

          <ModalAdicionaProprietario
            show={modalAdicionaProprietario}
            textbutton="Adicionar"
            textbutton2="Cancelar"
            onHide={() => setModalAdicionaProprietario(false)}
          />

          <ModalAdicionaCondutor
            show={modalAdicionaCondutor}
            textbutton="Adicionar"
            textbutton2="Cancelar"
            onHide={() => setModalAdicionaCondutor(false)}
          />

          <ModalMessage
            title={messageModalAtualizacaoVeiculo}
            className="modalSuccess"
            show={successAtualizacaoVeiculo}
            onHide={() => setSuccessAtualizacaoVeiculo(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAtualizacaoVeiculo}
            className="modalFalha"
            show={falhaAtualizacaoVeiculo}
            onHide={() => setFalhaAtualizacaoVeiculo(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalCadastroPermissionario}
            className="modalSuccess"
            show={successCadastroPermissionario}
            onHide={() => [setSuccessCadastroPermissionario(false), voltaTabela(), setActiveStep(0)]}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalCadastroPermissionario}
            className="modalFalha"
            show={falhaCadastroPermissionario}
            onHide={() => setFalhaCadastroPermissionario(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAtualizaPermissionario}
            className="modalSuccess"
            show={successAtualizaPermissionario}
            onHide={() => [setSuccessAtualizaPermissionario(false), voltaTabela(), setActiveStep(0)]}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalAtualizaPermissionario}
            className="modalFalha"
            show={falhaAtualizaPermissionario}
            onHide={() => setFalhaAtualizaPermissionario(false)}
            textbutton="Ok"
            textbutton2="Ir para o ínicio"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            pt: 2,
            width: '77%',
            margin: '0 auto',
            alignItems: 'center'
          }}
        >
          {activeStep !== steps.length && (
            <div className="iconVoltar configuracoes mb-5">
              <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                <div>
                  <BiArrowBack size={25} onClick={() => [voltaTabela(), setActiveStep(0)]} className="iconNavegacao" />
                </div>
              </Tooltip>
            </div>
          )}

          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep === 0 && (
            <Button onClick={atualizaVeiculo} sx={{ mr: 1 }} className="buttonChangeProprietario">
              Atualizar veículo
            </Button>
          )}

          {activeStep === 1 && proprietariosSelecionados.length === 0 ? (
            <Button onClick={() => setModalAdicionaProprietario(true)} className="buttonChangeProprietario">
              Adicionar proprietário
            </Button>
          ) : activeStep === 1 && ehAtualizacao ? (
            <>
              <Button onClick={() => setModalAdicionaProprietario(true)} className="buttonChangeProprietario">
                Adicionar proprietário
              </Button>
              <Button onClick={() => handleComplete(activeStep + 1)} className="buttonChangeProprietario">
                Próximo
              </Button>
            </>
          ) : activeStep === 2 ? (
            <Button onClick={() => setModalAdicionaCondutor(true)} className="buttonChangeProprietario">
              Adicionar condutor
            </Button>
          ) : activeStep !== 2 ? (
            <Button
              onClick={() => handleComplete(activeStep + 1)}
              className="buttonChangeProprietario"
              disabled={idVeiculoSelecionado === 0}
            >
              Próximo
            </Button>
          ) : null}
          {activeStep === 2 && (
            <Button
              onClick={ehAtualizacao ? atualizaCadastroPermissionario : enviaCadastroPermissionario}
              className="buttonChangeProprietario"
            >
              {ehAtualizacao ? 'Atualizar' : 'Salvar'}
            </Button>
          )}
        </Box>
      </div>
    </Box>
  )
}
