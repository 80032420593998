import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import { atualizaUsuario } from 'services/accountService'

import ModalMessage from '../../ModalMessage'

interface ModalEdicaoUsuarioProps {
  readonly primeironome: string
  readonly ultimonome: string
  readonly username: string
  readonly show: boolean
  readonly email: string
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalEdicaoUsuario({
  primeironome,
  ultimonome,
  show,
  username,
  email,
  id,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalEdicaoUsuarioProps) {
  const [primeiroNomeEditadoUsuario, setPrimeiroNomeEditadoUsuario] = useState(primeironome)
  const [ultimoNomeEditadoUsuario, setUltimoNomeEditadoUsuario] = useState(ultimonome)
  const [userNameEditadoUsuario, setUserNameEditadoUsuario] = useState(username)
  const [emailEditadoUsuario, setEmailEditadoUsuario] = useState(email)

  const [successEditUsuario, setSuccessEditUsuario] = useState(false)
  const [falhaEditUsuario, setFalhaEditUsuario] = useState(false)
  const [messageModalEditUsuario, setMessageModalEditUsuario] = useState([])

  const { atualizaUsuarios } = useContext(DadosContext)

  const { handleSubmit } = useForm()

  const formAtualizaUsuario = () => {
    atualizaUsuario(
      id,
      primeiroNomeEditadoUsuario,
      ultimoNomeEditadoUsuario,
      emailEditadoUsuario,
      userNameEditadoUsuario
    )
      .then((response) => {
        if (response.status === 200) {
          setMessageModalEditUsuario([response.data.message])
          setSuccessEditUsuario(true)
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalEditUsuario([error.response.data.message])
            setFalhaEditUsuario(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalEditUsuario(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaEditUsuario(true)
          }
        }
      })
  }

  return (
    <>
      {!successEditUsuario && !falhaEditUsuario && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="headerModalEdit">
              <h4>Editar usuário</h4>
            </div>

            <div className="bodyModalEdit">
              <form className="formEdicao" onSubmit={handleSubmit(formAtualizaUsuario)}>
                <div className="editaUsuario">
                  <div className="labelForm col-5">
                    <input
                      type="text"
                      name="primeiroNomeEdicaoUsuario"
                      placeholder="Primeiro nome"
                      className="form-control"
                      id="primeiroNomeEdicaoUsuario"
                      value={primeiroNomeEditadoUsuario}
                      onChange={(e) => setPrimeiroNomeEditadoUsuario(e.target.value)}
                    />
                    <label htmlFor="primeiroNomeEdicaoUsuario">Primeiro nome</label>
                  </div>

                  <div className="labelForm col-6">
                    <input
                      type="text"
                      name="ultimoNomeEdicaoUsuario"
                      placeholder="Último nome"
                      className="form-control"
                      id="ultimoNomeEdicaoUsuario"
                      value={ultimoNomeEditadoUsuario}
                      onChange={(e) => setUltimoNomeEditadoUsuario(e.target.value)}
                    />
                    <label htmlFor="ultimoNomeEdicaoUsuario">Último nome</label>
                  </div>

                  <div className="labelForm col-11 col-md-5">
                    <input
                      type="text"
                      name="userNameEdicaoUsuario"
                      placeholder="Nome de usuário"
                      className="form-control"
                      id="userNameEdicaoUsuario"
                      value={userNameEditadoUsuario}
                      onChange={(e) => setUserNameEditadoUsuario(e.target.value)}
                    />
                    <label htmlFor="userNameEdicaoUsuario">Nome de usuário</label>
                  </div>

                  <div className="labelForm col-11 col-md-6">
                    <input
                      type="text"
                      name="emailEdicaoUsuario"
                      placeholder="E-mail"
                      className="form-control"
                      id="emailEdicaoUsuario"
                      value={emailEditadoUsuario}
                      onChange={(e) => setEmailEditadoUsuario(e.target.value)}
                    />
                    <label htmlFor="emailEdicaoUsuario">E-mail</label>
                  </div>
                </div>

                <div className="buttonsModalEdit">
                  <Button
                    type="submit"
                    onClick={handleSubmit(formAtualizaUsuario)}
                    variant="none"
                    className="buttonSave"
                  >
                    {textbutton}
                  </Button>
                  <Button onClick={onHide} variant="none" className="buttonCancel">
                    {textbutton2}
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={[messageModalEditUsuario]}
        className="modalSuccess"
        show={successEditUsuario}
        onHide={() => [setSuccessEditUsuario(false), onClick(), atualizaUsuarios()]}
        textbutton="OK"
      />

      <ModalMessage
        title={[messageModalEditUsuario]}
        className="modalFalha"
        show={falhaEditUsuario}
        onHide={() => [setFalhaEditUsuario(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalEdicaoUsuario
