/* eslint-disable no-unused-expressions */
import { yupResolver } from '@hookform/resolvers/yup'
import { createContext, useState, useEffect, useContext, useMemo, useCallback, ReactNode } from 'react'
import { mask, unMask } from 'remask'
import { GetAllPermissionarios, GetPermissionarios } from 'services/permissionariosService'

import api from '../services/api'
import { AuthContext } from './AuthContext'
import { CondicionaisFormContext } from './CondicionaisFormContext'
import { LoadDataContext } from './LoadDataContext'
import { NotificacaoContext } from './NotificacaoContext'

interface OptionsTipoInfracaoProps {
  value: any
  label: any
}

interface DadosContextProps {
  TiposInfracao: any[]
  ModalidadeServico: any[]
  Sentidos: any[]
  Documentos: any[]
  TiposImagem: any[]
  Permissionarios: any[]
  Proprietarios: any[]
  proprietariosSelecionados: any[]
  condutoresSelecionados: any[]
  Condutores: any[]
  cor: any[]
  marcaModelo: any[]
  setProprietariosSelecionados: any
  setCondutoresSelecionados: any
  Locais: any[]
  erroForm: () => void
  onChangeMunicipio: (e: any) => void
  filterMunicipio: any[]
  selecionaMunicipio: (dado: any) => void
  onChangePlaca: (e: any) => void
  setMarcaModelo: any
  setCor: any
  filterCor: any[]
  onChangeCor: (e: any) => void
  selecionaCor: (dado: any) => void
  onChangeMarcaModelo: (e: any) => void
  filterMarcaModelo: any[]
  inputCpfCondutorMask: (e: any) => void
  inputCnhProprietarioMask: (e: any) => void
  inputCpfProprietarioMask: (e: any) => void
  inputCpfNovoProprietarioMask: (e: any) => void
  inputCpfNovoCondutorMask: (e: any) => void
  inputCepNovoProprietarioMask: (e: any) => void
  inputCepNovoCondutorMask: (e: any) => void
  inputCepEdicaoProprietarioMask: (e: any) => void
  inputCepEdicaoCondutorMask: (e: any) => void
  inputTelefoneNovoProprietarioMask: (e: any) => void
  inputTelefoneNovoCondutorMask: (e: any) => void
  inputTelefoneEdicaoProprietarioMask: (e: any) => void
  inputTelefoneEdicaoCondutorMask: (e: any) => void
  onChangeEnderecoProprietario: (e: any) => void
  inputCpfAgenteMask: (e: any) => void
  inputCnhCondutorMask: (e: any) => void
  Agentes: any[]
  Corporacao: any[]
  TiposUsuario: any[]
  Usuarios: any[]
  selecionaMarcaModelo: (dado: any) => void
  textMunicipio: string
  uf: string
  Placa: string
  prefixoVeiculo: string
  erroVeiculo: string
  chassi: string
  textMarcaModelo: string
  textCor: string
  dataInfracao: string
  nomeProprietario: string
  hora: string
  cpfCondutorValue: string
  cpfAgenteValue: string
  cnhCondutorValue: string
  cnhProprietarioValue: string
  cpfProprietarioValue: string
  cpfNovoProprietarioValue: string
  cpfNovoCondutorValue: string
  cepNovoProprietarioValue: string
  cepNovoCondutorValue: string
  cepEdicaoProprietarioValue: string
  cepEdicaoCondutorValue: string
  telefoneNovoProprietarioValue: string
  telefoneNovoCondutorValue: string
  telefoneEdicaoProprietarioValue: string
  telefoneEdicaoCondutorValue: string
  enderecoProprietario: string
  messageSuccess: string
  messageFalha: any[]
  idMunicipio: number
  idProprietarioSelecionado: number
  idProprietarioAtual: number
  idCondutorSelecionado: number
  idMarcaModelo: number
  idVeiculo: number
  errorLoad: boolean
  modalShow: boolean
  modalFalhaShow: boolean
  falha: boolean
  modalMedidasAdministrativas: boolean
  optionsTipoInfracao: OptionsTipoInfracaoProps[]
  setPermissionarios: React.Dispatch<React.SetStateAction<any[]>>
  setTextMunicipio: React.Dispatch<React.SetStateAction<string>>
  setUf: React.Dispatch<React.SetStateAction<string>>
  setPlaca: React.Dispatch<React.SetStateAction<string>>
  setPrefixoVeiculo: React.Dispatch<React.SetStateAction<string>>
  setChassi: React.Dispatch<React.SetStateAction<string>>
  setNomeProprietario: React.Dispatch<React.SetStateAction<string>>
  setDataInfracao: React.Dispatch<React.SetStateAction<string>>
  setHora: React.Dispatch<React.SetStateAction<string>>
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>
  setModalFalhaShow: React.Dispatch<React.SetStateAction<boolean>>
  setIdProprietarioSelecionado: React.Dispatch<React.SetStateAction<number>>
  setIdProprietarioAtual: React.Dispatch<React.SetStateAction<number>>
  setIdCondutorSelecionado: React.Dispatch<React.SetStateAction<number>>
  setIdVeiculo: React.Dispatch<React.SetStateAction<number>>
  setIdMunicipio: React.Dispatch<React.SetStateAction<number>>
  setModalMedidasAdministrativas: React.Dispatch<React.SetStateAction<boolean>>
  setMessageSuccess: React.Dispatch<React.SetStateAction<string>>
  setMessageFalha: React.Dispatch<React.SetStateAction<any[]>>
  setFalha: React.Dispatch<React.SetStateAction<boolean>>
  setCpfAgenteValue: React.Dispatch<React.SetStateAction<string>>
  setCpfNovoProprietarioValue: React.Dispatch<React.SetStateAction<string>>
  setCpfNovoCondutorValue: React.Dispatch<React.SetStateAction<string>>
  setCepNovoProprietarioValue: React.Dispatch<React.SetStateAction<string>>
  setCepNovoCondutorValue: React.Dispatch<React.SetStateAction<string>>
  setCepEdicaoProprietarioValue: React.Dispatch<React.SetStateAction<string>>
  setCepEdicaoCondutorValue: React.Dispatch<React.SetStateAction<string>>
  setTelefoneNovoProprietarioValue: React.Dispatch<React.SetStateAction<string>>
  setTelefoneNovoCondutorValue: React.Dispatch<React.SetStateAction<string>>
  setTelefoneEdicaoProprietarioValue: React.Dispatch<React.SetStateAction<string>>
  setTelefoneEdicaoCondutorValue: React.Dispatch<React.SetStateAction<string>>
  setTextMarcaModelo: React.Dispatch<React.SetStateAction<string>>
  setIdMarcaModelo: React.Dispatch<React.SetStateAction<number>>
  atualizaAgentes: () => void
  atualizaInfracoes: () => void
  atualizaCorporacao: () => void
  atualizaUsuarios: () => void
  atualizaProprietarios: () => void
  atualizaCondutores: () => void
  setCnhProprietarioValue: any
  setTextCor: any
  setEnderecoProprietario: any
  setCpfProprietarioValue: any
  setErroVeiculo: any
  setCpfCondutorValue: any
  setCnhCondutorValue: any
  idCor: number
  totalPermissionarios: number
  setTotalPermissionarios: React.Dispatch<React.SetStateAction<number>>
  loadAgentes: boolean
  loadUsuarios: boolean
  loadCorporacoes: boolean
  loadCondutores: boolean
  loadProprietarios: boolean
  loadPermissionarios: boolean
  setLoadAgentes: React.Dispatch<React.SetStateAction<boolean>>
  setLoadUsuarios: React.Dispatch<React.SetStateAction<boolean>>
  setLoadCorporacoes: React.Dispatch<React.SetStateAction<boolean>>
  setLoadCondutores: React.Dispatch<React.SetStateAction<boolean>>
  setLoadProprietarios: React.Dispatch<React.SetStateAction<boolean>>
  setLoadPermissionarios: React.Dispatch<React.SetStateAction<boolean>>
  atualizaPermissionarios: () => void
}

interface DadosContextProviderProps {
  children: ReactNode
}

export const DadosContext = createContext({} as DadosContextProps)

export function DadosContextProvider({ children }: DadosContextProviderProps) {
  const { clienteCode, serviceValue } = useContext(AuthContext)

  const { setLoadAllPermissionarios, setAllRegistersPermissionarios } = useContext(LoadDataContext)

  const {
    setSrcImages,
    srcSecondImages,
    tipoImagemImageDefault,
    selectedImages,
    setSelectedImages,
    hideOption,
    setMostraOpcao,
    arrayImagens
  } = useContext(CondicionaisFormContext)

  const { valueTipoNotificacao, setInfracoesRegistradas } = useContext(NotificacaoContext)

  const [TiposInfracao, setTiposInfracao] = useState([])
  const [ModalidadeServico, setModalidadeServico] = useState([])
  const [Sentidos, setSentidos] = useState([])
  const [Documentos, setDocumentos] = useState([])
  const [Locais, setLocais] = useState([])
  const [Municipio, setMunicipio] = useState([])
  const [Agentes, setAgentes] = useState([])
  const [Corporacao, setCorporacao] = useState([])
  const [TiposUsuario, setTiposUsuario] = useState([])
  const [TiposImagem, setTiposImagem] = useState([])
  const [Usuarios, setUsuarios] = useState([])
  const [errorLoad, setErrorLoad] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [falha, setFalha] = useState(false)
  const [modalFalhaShow, setModalFalhaShow] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState('')
  const [messageFalha, setMessageFalha] = useState([])
  const [Permissionarios, setPermissionarios] = useState([])
  const [Proprietarios, setProprietarios] = useState([])
  const [Condutores, setCondutores] = useState([])

  const [loadAgentes, setLoadAgentes] = useState(true)
  const [loadUsuarios, setLoadUsuarios] = useState(true)
  const [loadCorporacoes, setLoadCorporacoes] = useState(true)
  const [loadCondutores, setLoadCondutores] = useState(true)
  const [loadProprietarios, setLoadProprietarios] = useState(true)
  const [loadPermissionarios, setLoadPermissionarios] = useState(true)

  useEffect(() => {
    api
      .get('/Enum/TiposServicos')
      .then((response) => {
        setModalidadeServico(response.data.data)
      })
      .catch((error) => {
        setErrorLoad(true)
        setModalShow(true)
      })
  }, [])

  useEffect(() => {
    api
      .get('/Enum/Sentidos')
      .then((response) => {
        setSentidos(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    api
      .get('/Enum/Documentos')
      .then((response) => {
        setDocumentos(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    api
      .get('/Enum/Locais')
      .then((response) => {
        setLocais(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    api
      .get('Municipio')
      .then((response) => {
        setMunicipio(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    api
      .get('Agente')
      .then((response) => {
        setAgentes(response.data.data)
        setLoadAgentes(false)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  function atualizaAgentes() {
    api
      .get('Agente')
      .then((response) => {
        setAgentes(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }

  useEffect(() => {
    api
      .get('Corporacao')
      .then((response) => {
        setCorporacao(response.data.data)
        setLoadCorporacoes(false)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  function atualizaCorporacao() {
    api
      .get('Corporacao')
      .then((response) => {
        setCorporacao(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }

  useEffect(() => {
    api
      .get('/Enum/TiposUsuario')
      .then((response) => {
        setTiposUsuario(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  useEffect(() => {
    api
      .get('/Enum/TiposImagem')
      .then((response) => {
        setTiposImagem(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (serviceValue === 0) {
      api
        .get('/Enum/TiposInfracao')
        .then((response) => {
          setTiposInfracao(response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      api
        .get(`Infracao/tipo-infracao?tipoServico=${serviceValue}`)
        .then((response) => {
          setTiposInfracao(response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [serviceValue])

  useEffect(() => {
    api
      .get('Account/Usuarios')
      .then((response) => {
        setUsuarios(response.data.data)
        setLoadUsuarios(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  function atualizaUsuarios() {
    api
      .get('Account/Usuarios')
      .then((response) => {
        setUsuarios(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [totalPermissionarios, setTotalPermissionarios] = useState(0)

  useEffect(() => {
    GetPermissionarios(0, 200)
      .then((response) => {
        setPermissionarios(response.data.data.permissionarios)
        setTotalPermissionarios(response.data.data.totalRegistros)
        setLoadPermissionarios(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const atualizaPermissionarios = useCallback(() => {
    setLoadAllPermissionarios(true)
    GetAllPermissionarios().then((response) => {
      setAllRegistersPermissionarios(response.data.data.permissionarios)
      setLoadAllPermissionarios(false)
    })
  }, [setPermissionarios])

  const [idProprietarioSelecionado, setIdProprietarioSelecionado] = useState(0)
  const [idProprietarioAtual, setIdProprietarioAtual] = useState(0)
  const [proprietariosSelecionados, setProprietariosSelecionados] = useState([])

  useEffect(() => {
    api
      .get('Proprietario')
      .then((response) => {
        setProprietarios(
          response.data.data.sort((a, b) => {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })
        )
        setLoadProprietarios(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (proprietariosSelecionados.length > 1) {
      setProprietarios(
        Proprietarios.sort((a) => {
          if (a.id === idProprietarioAtual) return -1
          if (a.id !== idProprietarioAtual) return 1
          return 0
        })
      )
    }
  }, [proprietariosSelecionados, idProprietarioAtual, Proprietarios])

  function atualizaProprietarios() {
    api
      .get('Proprietario')
      .then((response) => {
        setProprietarios(
          response.data.data.sort((a, b) => {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          })
        )
      })
      .catch((error) => {
        console.log('error')
      })
  }

  const [idCondutorSelecionado, setIdCondutorSelecionado] = useState(0)
  const [condutoresSelecionados, setCondutoresSelecionados] = useState([])

  useEffect(() => {
    api
      .get('Condutor')
      .then((response) => {
        setCondutores(response.data.data)
        setLoadCondutores(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  function atualizaCondutores() {
    api
      .get('Condutor')
      .then((response) => {
        setCondutores(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (proprietariosSelecionados.length === 1) {
      setIdProprietarioAtual(Number(proprietariosSelecionados))
    }
  }, [proprietariosSelecionados])

  const optionsTipoInfracao = TiposInfracao.map((dado) => ({ value: dado.id, label: dado.descricao }))

  const [textMunicipio, setTextMunicipio] = useState('')
  const [filterMunicipio, setFilterMunicipio] = useState([])
  const [idMunicipio, setIdMunicipio] = useState(0)
  const [uf, setUf] = useState('')

  const onChangeMunicipio = useCallback(
    (e) => {
      e.preventDefault()
      setTextMunicipio(e.target.value)

      const Filtro = Municipio.filter((value) => value.nome.toLowerCase().includes(e.target.value.toLowerCase()))

      setFilterMunicipio(Filtro)
    },
    [Municipio]
  )

  useEffect(() => {
    if (!textMunicipio) {
      setFilterMunicipio([])
      setIdMunicipio(0)
      setUf('')
    }
  }, [textMunicipio])

  function selecionaMunicipio(dado) {
    setTextMunicipio(dado.nome)
    setIdMunicipio(dado.id)
    setUf(dado.uf)
    setFilterMunicipio([])
  }

  const [Placa, setPlaca] = useState('')

  const onChangePlaca = (e) => {
    e.preventDefault()
    setPlaca(e.target.value)
  }

  const [erroVeiculo, setErroVeiculo] = useState('')
  const [chassi, setChassi] = useState('')
  const [marcaModelo, setMarcaModelo] = useState([])
  const [cor, setCor] = useState([])
  const [nomeProprietario, setNomeProprietario] = useState('')
  const [enderecoProprietario, setEnderecoProprietario] = useState('')
  const [idVeiculo, setIdVeiculo] = useState(0)
  const [prefixoVeiculo, setPrefixoVeiculo] = useState('')

  function onChangeEnderecoProprietario(e) {
    e.preventDefault()
    setEnderecoProprietario(e.target.value)
  }

  useEffect(() => {
    api
      .get(`Permissionario/pesquisa-veiculo?veiculoId=${idVeiculo}`)
      .then((veiculosCadastrados) => {
        if (veiculosCadastrados.data.data !== null) {
          const infosVeiculo = veiculosCadastrados.data.data

          setErroVeiculo('')
          setCondutoresSelecionados(infosVeiculo.condutores !== null ? infosVeiculo.condutores : null)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [idVeiculo])

  const [filterCor, setFilterCor] = useState([])
  const [idCor, setIdCor] = useState(0)
  const [textCor, setTextCor] = useState('')

  useEffect(() => {
    api
      .get('/Veiculo/cores')
      .then((response) => {
        setCor(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  const onChangeCor = useCallback(
    (e) => {
      e.preventDefault()
      setTextCor(e.target.value)

      const Filtro = cor.filter((value) => value.descricao.toLowerCase().includes(e.target.value.toLowerCase()))

      setFilterCor(Filtro)
    },
    [cor]
  )

  useEffect(() => {
    if (!textCor) {
      setFilterCor([])
      setIdCor(0)
    }
  }, [textCor])

  function selecionaCor(dado) {
    setTextCor(dado.descricao)
    setIdCor(dado.id)
    setFilterCor([])
  }

  const [filterMarcaModelo, setFilterMarcaModelo] = useState([])
  const [idMarcaModelo, setIdMarcaModelo] = useState(0)
  const [textMarcaModelo, setTextMarcaModelo] = useState('')

  useEffect(() => {
    api
      .get('/Veiculo/marcas-modelos')
      .then((response) => {
        setMarcaModelo(response.data.data)
      })
      .catch((error) => {
        console.log('error')
      })
  }, [])

  const onChangeMarcaModelo = useCallback(
    (e) => {
      e.preventDefault()
      setTextMarcaModelo(e.target.value)

      if (e.target.value.length > 2) {
        const Filtro = marcaModelo.filter((value) =>
          value.descricao.toLowerCase().includes(e.target.value.toLowerCase())
        )

        setFilterMarcaModelo(Filtro)
      }
    },
    [marcaModelo]
  )

  useEffect(() => {
    if (!textMarcaModelo) {
      setFilterMarcaModelo([])
      setIdMarcaModelo(0)
    }
  }, [textMarcaModelo])

  function selecionaMarcaModelo(dado) {
    setTextMarcaModelo(dado.descricao)
    setIdMarcaModelo(dado.id)
    setFilterMarcaModelo([])
  }

  const [cpfProprietarioValue, setCpfProprietarioValue] = useState('')
  const [cnhProprietarioValue, setCnhProprietarioValue] = useState('')
  const [cpfNovoProprietarioValue, setCpfNovoProprietarioValue] = useState('')
  const [cpfNovoCondutorValue, setCpfNovoCondutorValue] = useState('')
  const [telefoneNovoProprietarioValue, setTelefoneNovoProprietarioValue] = useState('')
  const [telefoneNovoCondutorValue, setTelefoneNovoCondutorValue] = useState('')
  const [telefoneEdicaoProprietarioValue, setTelefoneEdicaoProprietarioValue] = useState('')
  const [telefoneEdicaoCondutorValue, setTelefoneEdicaoCondutorValue] = useState('')
  const [cepNovoProprietarioValue, setCepNovoProprietarioValue] = useState('')
  const [cepNovoCondutorValue, setCepNovoCondutorValue] = useState('')
  const [cepEdicaoProprietarioValue, setCepEdicaoProprietarioValue] = useState('')
  const [cepEdicaoCondutorValue, setCepEdicaoCondutorValue] = useState('')
  const [cpfCondutorValue, setCpfCondutorValue] = useState('')
  const [cnhCondutorValue, setCnhCondutorValue] = useState('')
  const [cpfAgenteValue, setCpfAgenteValue] = useState('')

  const inputCpfProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
    setCpfProprietarioValue(maskedValue)
  }

  const inputCpfNovoProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
    setCpfNovoProprietarioValue(maskedValue)
  }

  const inputCnhProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999999999'])
    setCnhProprietarioValue(maskedValue)
  }

  const inputCpfCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
    setCpfCondutorValue(maskedValue)
  }

  const inputCpfNovoCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99'])
    setCpfNovoCondutorValue(maskedValue)
  }

  const inputCnhCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999999999'])
    setCnhCondutorValue(maskedValue)
  }

  const inputCpfAgenteMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['999.999.999-99'])
    setCpfAgenteValue(maskedValue)
  }

  const inputTelefoneNovoProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['(99) 99999-9999'])
    setTelefoneNovoProprietarioValue(maskedValue)
  }

  const inputTelefoneNovoCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['(99) 99999-9999'])
    setTelefoneNovoCondutorValue(maskedValue)
  }

  const inputTelefoneEdicaoProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['(99) 99999-9999'])
    setTelefoneEdicaoProprietarioValue(maskedValue)
  }

  const inputTelefoneEdicaoCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['(99) 99999-9999'])
    setTelefoneEdicaoCondutorValue(maskedValue)
  }

  const inputCepNovoProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999-999'])
    setCepNovoProprietarioValue(maskedValue)
  }

  const inputCepNovoCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999-999'])
    setCepNovoCondutorValue(maskedValue)
  }

  const inputCepEdicaoProprietarioMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999-999'])
    setCepEdicaoProprietarioValue(maskedValue)
  }

  const inputCepEdicaoCondutorMask = (e) => {
    const originalValue = unMask(e.target.value)
    const maskedValue = mask(originalValue, ['99999-999'])
    setCepEdicaoCondutorValue(maskedValue)
  }

  const current = new Date()
  const date = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current
    .getDate()
    .toString()
    .padStart(2, '0')}`

  const [dataInfracao, setDataInfracao] = useState(date)
  const [hora, setHora] = useState('')

  function erroForm() {
    return console.log('error')
  }

  const atualizaInfracoes = useCallback(() => {
    if (valueTipoNotificacao === 0) {
      api
        .get(`/Notificacao/get-infracoes`)
        .then((response) => {
          setInfracoesRegistradas(response.data.data)
        })
        .catch((error) => {
          console.log('error')
        })
    } else {
      api
        .get(`/Notificacao/get-infracoes?tipoNotificacao=${1}`)
        .then((response) => {
          setInfracoesRegistradas(response.data.data)
        })
        .catch((error) => {
          console.log('error')
        })
    }
  }, [setInfracoesRegistradas, valueTipoNotificacao])

  const [modalMedidasAdministrativas, setModalMedidasAdministrativas] = useState(false)

  const contextValues = useMemo(
    () => ({
      setCnhProprietarioValue,
      setTextCor,
      setEnderecoProprietario,
      setCpfProprietarioValue,
      setErroVeiculo,
      setCpfCondutorValue,
      setCnhCondutorValue,
      idCor,
      atualizaInfracoes,
      TiposInfracao,
      ModalidadeServico,
      Sentidos,
      Documentos,
      TiposImagem,
      optionsTipoInfracao,
      Locais,
      erroForm,
      textMunicipio,
      setTextMunicipio,
      setUf,
      onChangeMunicipio,
      filterMunicipio,
      selecionaMunicipio,
      uf,
      idMunicipio,
      setIdMunicipio,
      onChangePlaca,
      Placa,
      setPlaca,
      setPrefixoVeiculo,
      erroVeiculo,
      prefixoVeiculo,
      chassi,
      setChassi,
      idMarcaModelo,
      textMarcaModelo,
      setMarcaModelo,
      textCor,
      setCor,
      filterCor,
      onChangeCor,
      selecionaCor,
      onChangeMarcaModelo,
      filterMarcaModelo,
      selecionaMarcaModelo,
      nomeProprietario,
      setNomeProprietario,
      dataInfracao,
      setDataInfracao,
      hora,
      setHora,
      errorLoad,
      modalShow,
      setModalShow,
      cpfCondutorValue,
      inputCpfCondutorMask,
      cnhProprietarioValue,
      inputCnhProprietarioMask,
      cpfProprietarioValue,
      cpfNovoProprietarioValue,
      cpfNovoCondutorValue,
      cepNovoProprietarioValue,
      cepNovoCondutorValue,
      cepEdicaoProprietarioValue,
      cepEdicaoCondutorValue,
      telefoneNovoProprietarioValue,
      telefoneNovoCondutorValue,
      telefoneEdicaoProprietarioValue,
      telefoneEdicaoCondutorValue,
      inputCpfProprietarioMask,
      inputCpfNovoProprietarioMask,
      inputCpfNovoCondutorMask,
      inputCepNovoProprietarioMask,
      inputCepNovoCondutorMask,
      inputCepEdicaoProprietarioMask,
      inputCepEdicaoCondutorMask,
      inputTelefoneNovoProprietarioMask,
      inputTelefoneNovoCondutorMask,
      inputTelefoneEdicaoProprietarioMask,
      inputTelefoneEdicaoCondutorMask,
      enderecoProprietario,
      onChangeEnderecoProprietario,
      cpfAgenteValue,
      setCpfAgenteValue,
      inputCpfAgenteMask,
      cnhCondutorValue,
      inputCnhCondutorMask,
      falha,
      modalFalhaShow,
      setModalFalhaShow,
      messageSuccess,
      messageFalha,
      Agentes,
      Corporacao,
      atualizaCorporacao,
      TiposUsuario,
      idVeiculo,
      setIdVeiculo,
      idProprietarioSelecionado,
      idProprietarioAtual,
      setIdProprietarioSelecionado,
      setIdProprietarioAtual,
      idCondutorSelecionado,
      setIdCondutorSelecionado,
      atualizaAgentes,
      modalMedidasAdministrativas,
      setModalMedidasAdministrativas,
      setMessageSuccess,
      setMessageFalha,
      setFalha,
      setCpfNovoProprietarioValue,
      setCpfNovoCondutorValue,
      setCepNovoProprietarioValue,
      setCepNovoCondutorValue,
      setCepEdicaoProprietarioValue,
      setCepEdicaoCondutorValue,
      setTelefoneNovoProprietarioValue,
      setTelefoneNovoCondutorValue,
      setTelefoneEdicaoProprietarioValue,
      setTelefoneEdicaoCondutorValue,
      setTextMarcaModelo,
      setIdMarcaModelo,
      Usuarios,
      atualizaUsuarios,
      Permissionarios,
      setPermissionarios,
      Proprietarios,
      proprietariosSelecionados,
      atualizaProprietarios,
      setProprietariosSelecionados,
      Condutores,
      condutoresSelecionados,
      atualizaCondutores,
      setCondutoresSelecionados,
      cor,
      marcaModelo,
      totalPermissionarios,
      setTotalPermissionarios,
      loadAgentes,
      loadUsuarios,
      loadCorporacoes,
      loadCondutores,
      loadProprietarios,
      loadPermissionarios,
      setLoadAgentes,
      setLoadUsuarios,
      setLoadCorporacoes,
      setLoadCondutores,
      setLoadProprietarios,
      setLoadPermissionarios,
      atualizaPermissionarios
    }),
    [
      idCor,
      atualizaInfracoes,
      TiposInfracao,
      ModalidadeServico,
      Sentidos,
      Documentos,
      TiposImagem,
      optionsTipoInfracao,
      Locais,
      textMunicipio,
      onChangeMunicipio,
      filterMunicipio,
      uf,
      idMunicipio,
      Placa,
      erroVeiculo,
      prefixoVeiculo,
      chassi,
      idMarcaModelo,
      textMarcaModelo,
      textCor,
      filterCor,
      onChangeCor,
      onChangeMarcaModelo,
      filterMarcaModelo,
      nomeProprietario,
      dataInfracao,
      hora,
      errorLoad,
      modalShow,
      cpfCondutorValue,
      cnhProprietarioValue,
      cpfProprietarioValue,
      cpfNovoProprietarioValue,
      cpfNovoCondutorValue,
      cepNovoProprietarioValue,
      cepNovoCondutorValue,
      cepEdicaoProprietarioValue,
      cepEdicaoCondutorValue,
      telefoneNovoProprietarioValue,
      telefoneNovoCondutorValue,
      telefoneEdicaoProprietarioValue,
      telefoneEdicaoCondutorValue,
      enderecoProprietario,
      cpfAgenteValue,
      cnhCondutorValue,
      falha,
      modalFalhaShow,
      messageSuccess,
      messageFalha,
      Agentes,
      Corporacao,
      TiposUsuario,
      idVeiculo,
      idProprietarioSelecionado,
      idProprietarioAtual,
      idCondutorSelecionado,
      modalMedidasAdministrativas,
      Usuarios,
      Permissionarios,
      Proprietarios,
      proprietariosSelecionados,
      Condutores,
      condutoresSelecionados,
      cor,
      marcaModelo,
      totalPermissionarios,
      loadAgentes,
      loadUsuarios,
      loadCorporacoes,
      loadCondutores,
      loadProprietarios,
      loadPermissionarios,
      atualizaPermissionarios
    ]
  )

  return <DadosContext.Provider value={contextValues}>{children}</DadosContext.Provider>
}
