import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { InativarUsuario } from 'services/accountService'

import ModalMessage from '../../ModalMessage'

interface ModalInativacaoUsuarioProps {
  readonly nome?: string
  readonly show: boolean
  readonly username: string
  readonly id: number
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalInativacaoUsuario({
  nome,
  username,
  show,
  id,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalInativacaoUsuarioProps) {
  const { atualizaUsuarios } = useContext(DadosContext)

  const [successInativaUsuario, setSuccessInativaUsuario] = useState(false)
  const [falhaInativaUsuario, setFalhaInativaUsuario] = useState(false)
  const [messageModalInativaUsuario, setMessageModalInativaUsuario] = useState([])

  async function inativaUsuario() {
    InativarUsuario(id)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalInativaUsuario([response.data.message])
          setSuccessInativaUsuario(true)
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalInativaUsuario([error.response.data.message])
            setFalhaInativaUsuario(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalInativaUsuario(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaInativaUsuario(true)
          }
        }
      })
  }

  return (
    <>
      {!successInativaUsuario && !falhaInativaUsuario && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja inativar o usuário {username}?</h4>
            </div>

            <div className="buttonsModalInative">
              <Button onClick={inativaUsuario} variant="none" className="buttonInative">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={[messageModalInativaUsuario]}
        className="modalSuccess"
        show={successInativaUsuario}
        onHide={() => [setSuccessInativaUsuario(false), onClick(), atualizaUsuarios()]}
        textbutton="OK"
      />

      <ModalMessage
        title={[messageModalInativaUsuario]}
        className="modalFalha"
        show={falhaInativaUsuario}
        onHide={() => [setFalhaInativaUsuario(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalInativacaoUsuario
