import ModalMessage from 'components/Modais/ModalMessage'
import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { ReativarCondutor } from 'services/condutorService'

interface ModalReativacaoCondutorProps {
  readonly nome: string
  readonly id: number
  readonly show: boolean
  readonly className?: string
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalReativacaoCondutor({
  nome,
  id,
  className,
  show,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalReativacaoCondutorProps) {
  const { atualizaCondutores } = useContext(DadosContext)

  const [successReativaCondutor, setSuccessReativaCondutor] = useState(false)
  const [falhaReativaCondutor, setFalhaReativaCondutor] = useState(false)
  const [messageModalReativaCondutor, setMessageModalReativaCondutor] = useState([])

  async function reativaCondutor() {
    ReativarCondutor(id)
      .then((response) => {
        setMessageModalReativaCondutor([response.data.message])
        setSuccessReativaCondutor(true)
      })
      .catch((error) => {
        setMessageModalReativaCondutor([error.response.data.message])
        setFalhaReativaCondutor(true)
      })
  }
  return (
    <>
      {!successReativaCondutor && !falhaReativaCondutor && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modalBodyInativacao">
              <h4>Deseja reativar o Condutor {nome}?</h4>
            </div>

            <div className="buttonsModalEdit">
              <Button onClick={reativaCondutor} variant="none" className="buttonSave">
                {textbutton}
              </Button>

              <Button onClick={onHide} variant="none" className="buttonBackAndCancel">
                {textbutton2}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalReativaCondutor}
        className="modalSuccess"
        show={successReativaCondutor}
        onHide={() => [setSuccessReativaCondutor(false), onClick(), atualizaCondutores()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalReativaCondutor}
        className="modalFalha"
        show={falhaReativaCondutor}
        onHide={() => [setFalhaReativaCondutor(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalReativacaoCondutor
