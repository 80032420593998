import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@mui/material/Tooltip'
import './styles.css'
import '../../styles/Tables.css'
import '../../styles/Forms.css'
import IconsModify from 'components/IconsModify'
import LoadComponent from 'components/loadComponent'
import { Pagination } from 'components/Pages/Pagination'
import { PasswordInput } from 'components/PasswordInput'
import { SearchForm } from 'components/SearchForm'
import { ListsContext } from 'contexts/ListsContext'
import React, { useState, useContext, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { CreateUser } from 'services/accountService'
import * as yup from 'yup'

import ButtonSubmit from '../../components/ButtonSubmit'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ErrorForm } from '../../components/FormTalao/ErrorForm'
import ModalEdicaoUsuario from '../../components/Modais/ModaisUsuario/ModalEdicaoUsuario'
import ModalInativacaoUsuario from '../../components/Modais/ModaisUsuario/ModalInativaUsuario'
import ModalReativacaoUsuario from '../../components/Modais/ModaisUsuario/ModalReativaUsuario'
import ModalMessage from '../../components/Modais/ModalMessage'
import SideBar from '../../components/SideBarLateral'
import { AuthContext } from '../../contexts/AuthContext'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'

export default function ConfiguraUsuario() {
  const { Permissoes } = useContext(AuthContext)

  const { classSideNav } = useContext(CondicionaisFormContext)
  const { Usuarios, atualizaUsuarios } = useContext(DadosContext)

  const [tabelaUsuarios, setTabelaUsuarios] = useState(true)
  const [cadastraUsuarios, setCadastraUsuarios] = useState(false)

  const [filterGeral, setFilterGeral] = useState('Todos')
  const [searchUsuario, setSearchUsuario] = useState('')
  const [filterSearchUsuario, setFilterSearchUsuario] = useState([])
  const [filterInativos, setFilterInativos] = useState([])
  const [filterAtivos, setFilterAtivos] = useState([])

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const currentUsuarios = filterSearchUsuario.slice(startIndex, endIndex)

  function ativaCadastroUsuarios() {
    setCadastraUsuarios(true)
    setTabelaUsuarios(false)
  }

  function onChangeBuscaUsuario(e): void {
    e.preventDefault()
    setSearchUsuario(e.target.value)

    if (filterGeral === 'Todos') {
      const Filtro = Usuarios.filter((value) => value.userName?.toLowerCase().includes(e.target.value.toLowerCase()))

      setFilterSearchUsuario(Filtro)
    } else if (filterGeral === 'Inativos') {
      const Filtro = filterInativos.filter((value) =>
        value.userName?.toLowerCase().includes(e.target.value.toLowerCase())
      )

      setFilterSearchUsuario(Filtro)
    } else if (filterGeral === 'Ativos') {
      const Filtro = filterAtivos.filter((value) =>
        value.userName?.toLowerCase().includes(e.target.value.toLowerCase())
      )

      setFilterSearchUsuario(Filtro)
    }
  }

  useEffect(() => {
    setFilterSearchUsuario(Usuarios)
  }, [Usuarios])

  useEffect(() => {
    if (filterGeral === 'Todos') {
      const FiltroGeral = Usuarios
      setFilterSearchUsuario(FiltroGeral)
    } else if (filterGeral === 'Ativos') {
      const FiltroGeral = Usuarios.filter((value) => !value.dataInativo)
      setFilterAtivos(FiltroGeral)
      setFilterSearchUsuario(FiltroGeral)
    } else if (filterGeral === 'Inativos') {
      const FiltroGeral = Usuarios.filter((value) => value.dataInativo)
      setFilterInativos(FiltroGeral)
      setFilterSearchUsuario(FiltroGeral)
    }
  }, [filterGeral, Usuarios])

  useEffect(() => {
    setCurrentPage(1)
  }, [linhasPerPage, filterGeral])

  return (
    <div className="content">
      {Permissoes.toString() === 'Administrador' && (
        <div className="sideNavLateral">
          <SideBar />
        </div>
      )}

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2>Configurações de usuário</h2>

          <SearchForm
            defaultValue={filterGeral}
            value={searchUsuario}
            onChangeFilterGeral={(e) => setFilterGeral(e.target.value)}
            onChangeBusca={onChangeBuscaUsuario}
            defaultValueRegisters={linhasPerPage}
            onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
            placeholder="Pesquisar usuário"
            name="buscaUsuario"
          >
            <div className="itemMenuNavegacao">
              {tabelaUsuarios ? (
                <Tooltip title="Novo usuário" placement="bottom" arrow>
                  <div>
                    <BsFillPersonPlusFill size={25} onClick={ativaCadastroUsuarios} className="iconNavegacao" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                  <div>
                    <BiArrowBack
                      size={25}
                      onClick={() => [setTabelaUsuarios(true), setCadastraUsuarios(false), atualizaUsuarios()]}
                      className="iconNavegacao"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </SearchForm>

          {tabelaUsuarios && (
            <ListagemUsuarios
              filtro={filterSearchUsuario}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              linhasPerPage={linhasPerPage}
              usuariosAtuais={currentUsuarios}
            />
          )}

          {cadastraUsuarios && <FormNovoUsuario />}
        </div>
      </div>
    </div>
  )
}

function FormNovoUsuario() {
  const { TiposUsuario } = useContext(DadosContext)

  const [senha, setSenha] = useState('')
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('')

  const [senhaVazia, setSenhaVazia] = useState(false)
  const [confirmacaoSenhaVazia, setConfirmacaoSenhaVazia] = useState(false)

  const [errorSignup, setErrorSignup] = useState('')
  const [userCreated, setUserCreated] = useState(false)
  const [userCreatedFailed, setUserCreatedFailed] = useState(false)
  const [messageModalSignup, setMessageModalSignup] = useState([])

  const criaUsuario = async ({ primeiroNome, ultimoNome, email, userName, tipoUsuario }) => {
    if (senha !== confirmacaoSenha) {
      setErrorSignup('As senhas não conferem')
    } else {
      try {
        const response = await CreateUser(
          primeiroNome,
          ultimoNome,
          email,
          userName,
          senha,
          confirmacaoSenha,
          tipoUsuario
        )
        if (response.data.success === true) {
          setMessageModalSignup([response.data.message])
          setUserCreated(true)
          setErrorSignup('')
          setSenha('')
          setConfirmacaoSenha('')
        }
      } catch (error: any) {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalSignup([error.response.data.message])
            setUserCreatedFailed(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalSignup(mensagensErro.map((mensagens) => mensagens.toString()))
            setUserCreatedFailed(true)
          }
        } else if (error.response.status === 403) {
          setMessageModalSignup(['Você não tem permissão para criar um novo usuário'])
          setUserCreatedFailed(true)
        }
      }
    }
  }

  const validaCriacaoUsuario = yup.object({
    primeiroNome: yup.string().required(),
    ultimoNome: yup.string().required(),
    email: yup.string().required(),
    userName: yup.string().required(),
    tipoUsuario: yup.number().required()
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaCriacaoUsuario)
  })

  const handleSignup = (data) => {
    if (senha.length > 0 && confirmacaoSenha.length > 0) {
      criaUsuario(data)
    }
  }

  useEffect(() => {
    if (senha.length > 0) {
      setSenhaVazia(false)
    }
    if (confirmacaoSenha.length > 0) {
      setConfirmacaoSenhaVazia(false)
    }
  }, [senha, confirmacaoSenha])

  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4 mt-3" onSubmit={handleSubmit(handleSignup)} autoComplete="off">
        <div className="formCriaRegistro">
          <div className="labelForm col-12 col-md-5">
            <input
              type="text"
              className="form-control"
              name="primeiroNome"
              id="primeiroNome"
              placeholder="Primeiro Nome"
              {...register('primeiroNome')}
            />
            <label htmlFor="primeiroNome">Primeiro nome</label>
            {errors?.primeiroNome?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-6">
            <input
              type="text"
              className="form-control"
              name="ultimoNome"
              placeholder="Último nome"
              id="ultimoNome"
              {...register('ultimoNome')}
            />
            <label htmlFor="ultimoNome">Último nome</label>
            {errors?.ultimoNome?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-5">
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              placeholder="E-mail"
              {...register('email')}
            />
            <label htmlFor="email">E-mail</label>
            {errors?.email?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-12 col-md-6">
            <input
              type="text"
              className="form-control"
              name="login"
              id="login"
              placeholder="Usuário"
              {...register('userName')}
            />
            <label htmlFor="login">Usuário</label>
            {errors?.userName?.type && <ErrorForm />}
          </div>

          <PasswordInput
            className="labelForm col-12 col-md-5"
            name="senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            label="Senha"
            sizeIcon={15}
          >
            {senhaVazia && <ErrorForm />}
          </PasswordInput>

          <PasswordInput
            className="labelForm col-12 col-md-6"
            value={confirmacaoSenha}
            onChange={(e) => setConfirmacaoSenha(e.target.value)}
            label="Confirmação de senha"
            sizeIcon={15}
          >
            {confirmacaoSenhaVazia && <ErrorForm />}
          </PasswordInput>

          <div className="labelForm mt-2 mb-3 col-12 col-md-11">
            <select
              name="tipoUsuario"
              id="tipoUsuario"
              className="col-12"
              defaultValue="SELECIONE O TIPO DE USUÁRIO"
              {...register('tipoUsuario')}
            >
              <option disabled>SELECIONE O TIPO DE USUÁRIO</option>
              {TiposUsuario?.map((dado, valor) => (
                <option key={dado.id} value={valor + 1}>
                  {dado.descricao}
                </option>
              ))}
            </select>{' '}
            <br />
            {errors?.tipoUsuario?.type && <ErrorForm />}
          </div>

          <ErrorMessage text={errorSignup} />

          <div className="buttonCriaRegistro col-12">
            <ButtonSubmit
              text="Cadastrar"
              onClick={() =>
                senha.length === 0 && confirmacaoSenha.length === 0
                  ? [setSenhaVazia(true), setConfirmacaoSenhaVazia(true)]
                  : senha.length === 0
                  ? setSenhaVazia(true)
                  : confirmacaoSenha.length === 0
                  ? setConfirmacaoSenhaVazia(true)
                  : null
              }
            />
          </div>
        </div>
      </form>

      <ModalMessage
        title={messageModalSignup}
        className="modalSuccess"
        textbutton="Cadastrar novo usuário"
        textbutton2="Ir para o ínicio"
        show={userCreated}
        onHide={() => [setUserCreated(false), reset()]}
      />

      <ModalMessage
        title={messageModalSignup}
        className="modalFalha"
        textbutton="Tentar novamente"
        textbutton2="Ir para o ínicio"
        show={userCreatedFailed}
        onHide={() => setUserCreatedFailed(false)}
      />
    </div>
  )
}

interface ListagemUsuariosProps {
  readonly filtro: any[]
  readonly currentPage: number
  readonly setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  readonly linhasPerPage: number
  readonly usuariosAtuais: any[]
}

function ListagemUsuarios({
  filtro,
  currentPage,
  setCurrentPage,
  linhasPerPage,
  usuariosAtuais
}: ListagemUsuariosProps) {
  const {
    showIcon,
    setShowIcon,
    messageDataInativo,
    showModalEdit,
    setShowModalEdit,
    showModalInvalid,
    setShowModalInvalid,
    showModalReative,
    setShowModalReative,
    mostraIcon,
    mostraEdicao,
    mostraInativacao,
    mostraReativacao,
    ativaMessageDataInativo
  } = useContext(ListsContext)

  const { loadUsuarios } = useContext(DadosContext)

  return (
    <>
      <div className="tableDefault container">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela">Usuário</th>

              <th className="headerTabela">Primeiro nome</th>

              <th className="headerTabela">Último nome</th>

              <th className="headerTabelaEmail">
                <span>E-mail</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {usuariosAtuais.map((dado, valor) => {
              const anoData = dado.dataInativo?.slice(0, 4)
              const mesData = dado.dataInativo?.slice(5, 7)
              const diaData = dado.dataInativo?.slice(8, 10)

              const data = `${diaData}/${mesData}/${anoData}`
              const dataFinal = data === 'undefined/undefined/undefined' ? null : data

              return (
                <tr key={dado.id} className="linhaDefault">
                  <td className="dadoTabela">
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.userName}
                    </div>
                  </td>
                  <td className="dadoTabela">
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.primeiroNome}
                    </div>
                  </td>
                  <td className="dadoTabela">
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.ultimoNome}
                    </div>
                  </td>
                  <td
                    className="dadoTabelaEmail"
                    onMouseEnter={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onMouseLeave={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                    onClick={dataFinal === null ? mostraIcon(valor) : ativaMessageDataInativo(valor)}
                  >
                    <div className={`${dataFinal === null ? 'textTableDefault' : 'textTableInativo'}`}>
                      {dado.email}
                    </div>

                    {showIcon[valor] && (
                      <IconsModify
                        isInativo={false}
                        onClickEdit={mostraEdicao(valor)}
                        onClickInative={
                          usuariosAtuais.some((usuario) => usuario.id === valor) ? mostraInativacao(valor) : null
                        }
                      />
                    )}

                    {messageDataInativo[valor] && (
                      <IconsModify isInativo onClickReative={mostraReativacao(valor)} dataFinal={dataFinal} />
                    )}
                  </td>

                  {showModalEdit[valor] && (
                    <td className="modalEdit">
                      <ModalEdicaoUsuario
                        show={!!showModalEdit}
                        onHide={() => [setShowModalEdit({}), setShowIcon({})]}
                        onClick={() => setShowModalEdit({})}
                        textbutton="Salvar"
                        textbutton2="Cancelar"
                        primeironome={dado.primeiroNome}
                        ultimonome={dado.ultimoNome}
                        username={dado.userName}
                        email={dado.email}
                        id={dado.id}
                      />
                    </td>
                  )}

                  {showModalInvalid[valor] && (
                    <td className="modalInvalid">
                      <ModalInativacaoUsuario
                        show={!!showModalInvalid}
                        onHide={() => [setShowModalInvalid({}), setShowIcon({})]}
                        onClick={() => setShowModalInvalid({})}
                        textbutton="Inativar"
                        textbutton2="Cancelar"
                        username={dado.userName}
                        id={dado.id}
                      />
                    </td>
                  )}

                  {showModalReative[valor] && (
                    <td className="modalInvalid">
                      <ModalReativacaoUsuario
                        show={!!showModalReative}
                        onHide={() => [setShowModalReative({}), setShowIcon({})]}
                        onClick={() => setShowModalReative({})}
                        textbutton="Reativar"
                        textbutton2="Cancelar"
                        username={dado.userName}
                        id={dado.id}
                      />
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>

      {loadUsuarios ? (
        <LoadComponent />
      ) : (
        <Pagination
          currentPage={currentPage}
          registersPerPage={linhasPerPage}
          textNoContent="Nenhum usuário encontrado"
          onPageChange={setCurrentPage}
          totalCountOfRegisters={filtro.length}
        />
      )}
    </>
  )
}
