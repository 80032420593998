import api from './api'

// #region Nova corporação
export const novaCorporacao = async (descricao: string) => api.post('Corporacao', { descricao })
// #endregion

// #region Edita corporação

export const InativarCorporacao = async (id: number) => api.put(`Corporacao/inativa-corporacao?corporacaoId=${id}`)

export const ReativarCorporacao = async (id: number) => api.put(`Corporacao/reativa-corporacao?corporacaoId=${id}`)

// #endregion
