import api from './api'

// #region Novo agente
export const novoAgente = async ({ corporacaoId, nome, cpf, numero }) =>
  api.post('Agente', { corporacaoId, nome, cpf, numero })
// #endregion

// #region Edita agente
export const edicaoAgente = async (id: number, nome: string) => api.put('Agente/atualiza-agente', { id, nome })

export const InativarAgente = async (id: number) => api.put(`Agente/inativa-agente?agenteId=${id}`)

export const ReativarAgente = async (id: number) => api.put(`Agente/reativa-agente?agenteId=${id}`)

// #endregion
