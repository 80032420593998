import api from './api'

// #region Perfil usuário
export const AtualizaSenha = async (userName: string, senha: string, novaSenha: string, confirmacaoSenha: string) =>
  api.put('Account/atualiza-senha', { userName, senha, novaSenha, confirmacaoSenha })

export const DetalhesUsuario = async (userName: string) => api.get(`/Account/detalhes-usuario?userName=${userName}`)
// #endregion

// #region Login
export const LoginUser = async (userName: string, senha: string) => api.post('/Account/Login', { userName, senha })
// #endregion

// #region Usuário
export const CreateUser = async (
  primeiroNome: string,
  ultimoNome: string,
  email: string,
  userName: string,
  senha: string,
  confirmacaoSenha: string,
  tipoUsuario: string
) =>
  api.post('/Account/CriarUsuario', { primeiroNome, ultimoNome, email, userName, senha, confirmacaoSenha, tipoUsuario })
// #endregion

// #region Atualizar usuário
export const atualizaUsuario = async (
  id: number,
  primeiroNome: string,
  ultimoNome: string,
  email: string,
  userName: string
) => api.put('Account/atualiza-usuario', { id, primeiroNome, ultimoNome, email, userName })

export const InativarUsuario = async (id: number) => api.put(`Account/inativa-usuario?usuarioId=${id}`)

export const ReativarUsuario = async (id: number) => api.put(`Account/reativa-usuario?usuarioId=${id}`)

// #endregion
