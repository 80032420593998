import React, { InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

export function Input({ value, onChange, label, placeholder, defaultValue, className, type, ...rest }: InputProps) {
  return (
    <>
      <input
        type={type}
        name={rest.name}
        id={rest.id}
        value={value}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <label htmlFor={rest.id}>{label}</label>
    </>
  )
}
