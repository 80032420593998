import { DigitacaoContextProvider } from 'contexts/DigitacaoContext'
import { EnquadramentoContextProvider } from 'contexts/EnquadramentoContext'
import { ListsContextProvider } from 'contexts/ListsContext'
import { LoadDataContextProvider } from 'contexts/LoadDataContext'
import { NotificacaoContextProvider } from 'contexts/NotificacaoContext'
import { PermissionariosContextProvider } from 'contexts/PermissionariosContext'
import ConfiguracoesCondutores from 'pages/ConfiguracoesCondutores'
import ConfiguracoesProprietarios from 'pages/ConfiguracoesProprietarios'
import ControlePermissionarios from 'pages/ControlePermissionarios'
import ListagemEnquadramentos from 'pages/listagemEnquadramentos'
import GerarLote from 'pages/Notificacao/GerarLote'
import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Header from './components/Header'
import NotAuhorizedUser from './components/NotAuthorizedUser'
import { AuthContext, AuthContextProvider } from './contexts/AuthContext'
import { CondicionaisFormContextProvider } from './contexts/CondicionaisFormContext'
import { DadosContextProvider } from './contexts/DadosContext'
import ConfiguraAgente from './pages/configuracoesAgente'
import ConfiguracoesCorporacao from './pages/ConfiguracoesCorporacao'
import ConfiguraUsuario from './pages/configuracoesUsuario'
import DigitacaoAuto from './pages/DigitacaoAuto'
import Home from './pages/Home'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import ControleDeTalao from './pages/Notificacao/ControleLotes'
import PerfilUsuarios from './pages/perfilUsuarios'

interface PrivateProps {
  readonly children: JSX.Element
  readonly roles?: string
}

function Private({ children, roles }: PrivateProps) {
  const { authenticated, loading, Permissoes } = useContext(AuthContext)

  if (!roles && authenticated) {
    return children
  }

  if (loading) {
    return <div className="loading">Carregando...</div>
  }

  if (!authenticated) {
    return <Navigate to="/" />
  }

  return Permissoes.includes('Administrador') ? children : <NotAuhorizedUser />
}

function Rotas() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <LoadDataContextProvider>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route
              path="/home"
              element={
                <Private>
                  <>
                    <Header />
                    <Home />
                  </>
                </Private>
              }
            />

            <Route
              path="/geracaoDeLote"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <NotificacaoContextProvider>
                      <DadosContextProvider>
                        <Header />
                        <GerarLote />
                      </DadosContextProvider>
                    </NotificacaoContextProvider>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="/controleDeLotes"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <NotificacaoContextProvider>
                      <DadosContextProvider>
                        <Header />
                        <ControleDeTalao />
                      </DadosContextProvider>
                    </NotificacaoContextProvider>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="/digitalizacao"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <EnquadramentoContextProvider>
                      <DadosContextProvider>
                        <Header />
                        <DigitacaoContextProvider>
                          <DigitacaoAuto />
                        </DigitacaoContextProvider>
                      </DadosContextProvider>
                    </EnquadramentoContextProvider>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="/configuracoesAgente"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <Header pageTitle="Configurações de Agentes" className="titleAdmin" />
                    <Private roles="Administrador">
                      <DadosContextProvider>
                        <ListsContextProvider>
                          <ConfiguraAgente />
                        </ListsContextProvider>
                      </DadosContextProvider>
                    </Private>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="/configuracoesUsuario"
              element={
                <CondicionaisFormContextProvider>
                  <Private roles="Administrador">
                    <DadosContextProvider>
                      <Header pageTitle="Configurações de Administrador" className="titleAdmin" />
                      <ListsContextProvider>
                        <ConfiguraUsuario />
                      </ListsContextProvider>
                    </DadosContextProvider>
                  </Private>
                </CondicionaisFormContextProvider>
              }
            />

            <Route
              path="/configuracoesCorporacao"
              element={
                <CondicionaisFormContextProvider>
                  <Private roles="Administrador">
                    <DadosContextProvider>
                      <Header pageTitle="Configurações de Corporação" className="titleAdmin" />
                      <ListsContextProvider>
                        <ConfiguracoesCorporacao />
                      </ListsContextProvider>
                    </DadosContextProvider>
                  </Private>
                </CondicionaisFormContextProvider>
              }
            />

            <Route
              path="/controlePermissionarios"
              element={
                <CondicionaisFormContextProvider>
                  <Private roles="Administrador">
                    <DadosContextProvider>
                      <Header />
                      <PermissionariosContextProvider>
                        <ListsContextProvider>
                          <ControlePermissionarios />
                        </ListsContextProvider>
                      </PermissionariosContextProvider>
                    </DadosContextProvider>
                  </Private>
                </CondicionaisFormContextProvider>
              }
            />

            <Route
              path="/configuracoesProprietarios"
              element={
                <CondicionaisFormContextProvider>
                  <Private roles="Administrador">
                    <DadosContextProvider>
                      <Header />
                      <ListsContextProvider>
                        <ConfiguracoesProprietarios />
                      </ListsContextProvider>
                    </DadosContextProvider>
                  </Private>
                </CondicionaisFormContextProvider>
              }
            />

            <Route
              path="/configuracoesCondutores"
              element={
                <CondicionaisFormContextProvider>
                  <Private roles="Administrador">
                    <DadosContextProvider>
                      <Header />
                      <ListsContextProvider>
                        <ConfiguracoesCondutores />
                      </ListsContextProvider>
                    </DadosContextProvider>
                  </Private>
                </CondicionaisFormContextProvider>
              }
            />

            <Route
              path="/enquadramentos"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <Private roles="Administrador">
                      <EnquadramentoContextProvider>
                        <DadosContextProvider>
                          <Header />
                          <ListagemEnquadramentos />
                        </DadosContextProvider>
                      </EnquadramentoContextProvider>
                    </Private>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="/perfil"
              element={
                <Private>
                  <CondicionaisFormContextProvider>
                    <DadosContextProvider>
                      <Header />
                      <PerfilUsuarios />
                    </DadosContextProvider>
                  </CondicionaisFormContextProvider>
                </Private>
              }
            />

            <Route
              path="*"
              element={
                <CondicionaisFormContextProvider>
                  <DadosContextProvider>
                    <Header />
                    <NotFound />
                  </DadosContextProvider>
                </CondicionaisFormContextProvider>
              }
            />
          </Routes>
        </LoadDataContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  )
}

export default Rotas
