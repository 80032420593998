import ModalMessage from 'components/Modais/ModalMessage'
import { DadosContext } from 'contexts/DadosContext'
import { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import { edicaoAgente } from 'services/agenteService'

interface ModalEdicaoAgenteProps {
  readonly numero: number
  readonly nome: string
  readonly show: boolean
  readonly cpf: string
  readonly id: number
  readonly corporacao: string
  readonly textbutton: string
  readonly textbutton2: string
  readonly onHide: () => void
  readonly onClick: () => void
}

function ModalEdicaoAgente({
  corporacao,
  cpf,
  id,
  nome,
  numero,
  show,
  textbutton,
  textbutton2,
  onClick,
  onHide,
  ...rest
}: ModalEdicaoAgenteProps) {
  const [nomeEditadoAgente, setNomeEditadoAgente] = useState(nome)

  const [successEditAgente, setSuccessEditAgente] = useState(false)
  const [falhaEditAgente, setFalhaEditAgente] = useState(false)
  const [messageModalEditAgente, setMessageModalEditAgente] = useState([])

  const { Corporacao, atualizaAgentes } = useContext(DadosContext)

  const { register, handleSubmit } = useForm()

  const formAtualizaAgente = () => {
    edicaoAgente(id, nomeEditadoAgente)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalEditAgente([response.data.message])
          setSuccessEditAgente(true)
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalEditAgente([error.response.data.message])
            setFalhaEditAgente(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalEditAgente(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaEditAgente(true)
          }
        }
      })
  }

  return (
    <>
      {!successEditAgente && !falhaEditAgente && (
        <Modal
          {...rest}
          show={show}
          dialogClassName="modalMedio"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="headerModalEdit">
              <h4>Editar agente</h4>
            </div>

            <div className="bodyModalEdit">
              <form className="formEdicaoAgente" onSubmit={handleSubmit(formAtualizaAgente)}>
                <div className="editaAgente">
                  <div className="labelForm col-3">
                    <input
                      type="text"
                      name="numeroAgenteEdicao"
                      placeholder="N°"
                      className="form-control"
                      id="buscaAgente"
                      value={numero}
                      readOnly
                      disabled
                    />
                    <label>N°</label>
                  </div>

                  <div className="labelForm col-8">
                    <input
                      type="text"
                      name="nomeEdicaoAgente"
                      placeholder="Nome"
                      className="form-control"
                      id="nomeEdicaoAgente"
                      value={nomeEditadoAgente}
                      onChange={(e) => setNomeEditadoAgente(e.target.value)}
                    />
                    <label>Nome</label>
                  </div>

                  <div className="labelForm col-11 col-md-4">
                    <input
                      type="text"
                      name="cpfEdicaoAgente"
                      placeholder="CPF"
                      className="form-control"
                      id="cpfEdicaoAgente"
                      value={cpf}
                      readOnly
                      disabled
                    />
                    <label>CPF</label>
                  </div>

                  <div className="labelForm col-11 col-md-7">
                    <select
                      name="corporacaoEdicaoAgente"
                      className="col-12"
                      disabled
                      defaultValue={corporacao}
                      {...register('corporacaoEdicaoAgente')}
                    >
                      <option disabled>SELECIONE A CORPORAÇÃO</option>
                      {Corporacao.map((dado) => (
                        <option key={dado.id} value={dado.id}>
                          {dado.descricao}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="buttonsModalEdit">
                  <Button
                    type="submit"
                    onClick={handleSubmit(formAtualizaAgente)}
                    variant="none"
                    className="buttonSave"
                  >
                    {textbutton}
                  </Button>
                  <Button onClick={onHide} variant="none" className="buttonCancel">
                    {textbutton2}
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <ModalMessage
        title={messageModalEditAgente}
        className="modalSuccess"
        show={successEditAgente}
        onHide={() => [setSuccessEditAgente(false), onClick(), atualizaAgentes()]}
        textbutton="OK"
      />

      <ModalMessage
        title={messageModalEditAgente}
        className="modalFalha"
        show={falhaEditAgente}
        onHide={() => [setFalhaEditAgente(false), onClick()]}
        textbutton="OK"
      />
    </>
  )
}

export default ModalEdicaoAgente
