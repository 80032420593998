import './styles.css'
import { yupResolver } from '@hookform/resolvers/yup'
import Tooltip from '@mui/material/Tooltip'
import { ErrorForm } from 'components/FormTalao/ErrorForm'
import IconsModify from 'components/IconsModify'
import LoadComponent from 'components/loadComponent'
import ModalEdicaoCondutor from 'components/Modais/ModaisCondutores/ModalEdicaoCondutor'
import ModalInativacaoCondutor from 'components/Modais/ModaisCondutores/ModalInativaCondutor'
import ModalReativacaoCondutor from 'components/Modais/ModaisCondutores/ModalReativaCondutor'
import ModalMessage from 'components/Modais/ModalMessage'
import { Pagination } from 'components/Pages/Pagination'
import { SearchForm } from 'components/SearchForm'
import { ListsContext } from 'contexts/ListsContext'
import React, { useContext, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillPersonPlusFill } from 'react-icons/bs'
import { novoCondutor } from 'services/condutorService'
import * as yup from 'yup'

import ButtonSubmit from '../../components/ButtonSubmit'
// import ModalReativacaoCondutor from '../../components/ModalReativaCondutor'
import SideBar from '../../components/SideBarLateral'
import { CondicionaisFormContext } from '../../contexts/CondicionaisFormContext'
import { DadosContext } from '../../contexts/DadosContext'

export default function ConfiguracoesCondutores() {
  const { classSideNav } = useContext(CondicionaisFormContext)

  const { Condutores } = useContext(DadosContext)

  const [cadastraCondutor, setCadastraCondutor] = useState(false)
  const [tabelaCondutores, setTabelaCondutores] = useState(true)

  const [searchActive, setSearchActive] = useState(false)

  const [filterGeralCondutor, setFilterGeralCondutor] = useState('Todos')
  const [searchCondutor, setSearchCondutor] = useState('')
  const [filterSearchCondutor, setFilterSearchCondutor] = useState([])
  const [filterAtivos, setFilterAtivos] = useState([])
  const [filterInativos, setFilterInativos] = useState([])

  const [linhasPerPage, setLinhasPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)

  const startIndex = (currentPage - 1) * linhasPerPage
  const endIndex = startIndex + linhasPerPage
  const [currentCondutores, setCurrentCondutores] = useState([])

  useEffect(() => {
    setFilterSearchCondutor(Condutores)
  }, [Condutores])

  useEffect(() => {
    setCurrentCondutores(filterSearchCondutor.slice(startIndex, endIndex))
  }, [startIndex, endIndex, filterSearchCondutor])

  useEffect(() => {
    if (searchCondutor.length > 0) {
      ativaPesquisa()
    }
    // eslint-disable-next-line
  }, [searchCondutor])

  useEffect(() => {
    setCurrentPage(1)
  }, [linhasPerPage, filterGeralCondutor])

  function ativaCadastroCondutor() {
    setCadastraCondutor(true)
    setTabelaCondutores(false)
    setSearchActive(false)
  }

  function ativaPesquisa() {
    setSearchActive(!searchActive)
    setTabelaCondutores(true)
    setCadastraCondutor(false)
  }

  useEffect(() => {
    if (filterGeralCondutor === 'Todos') {
      const FiltroGeral = Condutores
      setFilterSearchCondutor(FiltroGeral)
    } else if (filterGeralCondutor === 'Ativos') {
      const FiltroGeral = Condutores.filter((value) => !value.dataInativo)
      setFilterAtivos(FiltroGeral)
      setFilterSearchCondutor(FiltroGeral)
    } else if (filterGeralCondutor === 'Inativos') {
      const FiltroGeral = Condutores.filter((value) => value.dataInativo)
      setFilterInativos(FiltroGeral)
      setFilterSearchCondutor(FiltroGeral)
    }
  }, [filterGeralCondutor, Condutores])

  const onChangeBuscaCondutor = (e) => {
    e.preventDefault()
    setSearchCondutor(e.target.value)

    if (filterGeralCondutor === 'Todos') {
      const Filtro = Condutores.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.cpF_CNPJ?.toString().includes(e.target.value) ||
          value.cnh?.toString().includes(e.target.value) ||
          value.codigo?.toString().includes(e.target.value)
      )

      setFilterSearchCondutor(Filtro)
    } else if (filterGeralCondutor === 'Ativos') {
      const Filtro = filterAtivos.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.cpF_CNPJ?.toString().includes(e.target.value) ||
          value.cnh?.toString().includes(e.target.value) ||
          value.codigo?.toString().includes(e.target.value)
      )

      setFilterSearchCondutor(Filtro)
    } else if (filterGeralCondutor === 'Inativos') {
      const Filtro = filterInativos.filter(
        (value) =>
          value.nome?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.cpF_CNPJ?.toString().includes(e.target.value) ||
          value.cnh?.toString().includes(e.target.value) ||
          value.codigo?.toString().includes(e.target.value)
      )

      setFilterSearchCondutor(Filtro)
    }
  }

  return (
    <div className="content">
      <div className="sideNavLateral">
        <SideBar />
      </div>

      <div className={`${classSideNav === 'sidenav' ? 'conteudoComSidenav' : 'conteudoSemSidenav'}`}>
        <div className="container mt-3">
          <h2>Configurações de Condutores</h2>

          <SearchForm
            defaultValue={filterGeralCondutor}
            value={searchCondutor}
            onChangeFilterGeral={(e) => setFilterGeralCondutor(e.target.value)}
            onChangeBusca={onChangeBuscaCondutor}
            defaultValueRegisters={linhasPerPage}
            onChangeRegisters={(e) => setLinhasPerPage(Number(e.target.value))}
            placeholder="Pesquisar nome, CPF/CNPJ ou CNH"
            name="buscaCondutor"
          >
            <div className="itemMenuNavegacao">
              {tabelaCondutores ? (
                <Tooltip title="Adicionar condutor" placement="bottom" arrow>
                  <div>
                    <BsFillPersonPlusFill size={25} onClick={ativaCadastroCondutor} className="iconNavegacao" />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Voltar a consulta" placement="bottom" arrow>
                  <div>
                    <BiArrowBack
                      size={25}
                      onClick={() => [setTabelaCondutores(true), setCadastraCondutor(false)]}
                      className="iconNavegacao"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </SearchForm>

          {tabelaCondutores && (
            <ListagemCondutores
              filtro={filterSearchCondutor}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              linhasPerPage={linhasPerPage}
              condutoresAtuais={currentCondutores}
            />
          )}

          {cadastraCondutor && <FormNovoCondutor />}
        </div>
      </div>
    </div>
  )
}

function FormNovoCondutor() {
  const {
    atualizaCondutores,
    cpfNovoCondutorValue,
    inputCpfNovoCondutorMask,
    cepNovoCondutorValue,
    inputCepNovoCondutorMask,
    telefoneNovoCondutorValue,
    inputTelefoneNovoCondutorMask,
    setCpfNovoCondutorValue,
    setCepNovoCondutorValue,
    setTelefoneNovoCondutorValue,
    textMunicipio,
    setTextMunicipio,
    onChangeMunicipio,
    filterMunicipio,
    selecionaMunicipio,
    idMunicipio,
    uf
  } = useContext(DadosContext)

  const [successCadastroCondutor, setSuccessCadastroCondutor] = useState(false)
  const [falhaCadastroCondutor, setFalhaCadastroCondutor] = useState(false)
  const [messageModalCadastroCondutor, setMessageModalCadastroCondutor] = useState([])

  const validaCriacaoCondutor = yup.object({
    nome: yup.string().required(),
    cpF_CNPJ: yup.string().required(),
    cnh: yup.string().required(),
    categoriaCnhNovoCondutor: yup.number().required(),
    endereco: yup.string().required(),
    municipioNovoCondutor: yup.string().required(),
    cep: yup.string().required(),
    bairro: yup.string().required(),
    codigo: yup.string().required()
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validaCriacaoCondutor)
  })

  const formCriaCondutor = (data) => {
    novoCondutor(data, uf, idMunicipio)
      .then((response) => {
        if (response.status === 200) {
          setMessageModalCadastroCondutor([response.data.message])
          setSuccessCadastroCondutor(true)
          atualizaCondutores()
          setTextMunicipio('')
        }
      })
      .catch((error) => {
        if (error.response.status === 400 || error.response.status === 500) {
          if (error.response.data.message) {
            setMessageModalCadastroCondutor([error.response.data.message])
            setFalhaCadastroCondutor(true)
          } else {
            const mensagensErro = Object.values(error.response.data.errors)
            setMessageModalCadastroCondutor(mensagensErro.map((mensagens) => mensagens.toString()))
            setFalhaCadastroCondutor(true)
          }
        }
      })
  }

  return (
    <div className="cadastro novoRegistro">
      <form className="criaRegistro mb-4" onSubmit={handleSubmit(formCriaCondutor)} autoComplete="off">
        <div className="subtitulo mb-2">
          <h6 className="mt-1">Cadastrar novo condutor</h6>
        </div>

        <div className="formCriaRegistro">
          <div className="labelForm col-5">
            <input
              type="text"
              className="form-control"
              name="nomeNovoCondutor"
              id="nomeNovoCondutor"
              placeholder="Nome"
              {...register('nome')}
            />
            <label htmlFor="nomeNovoCondutor">Nome</label>
            {errors?.nome?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="codigoNovoCondutor"
              id="codigoNovoCondutor"
              placeholder="Código"
              {...register('codigo')}
            />
            <label htmlFor="codigoNovoCondutor">Código</label>
            {errors?.codigo?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="cpfNovoCondutor"
              id="cpfNovoCondutor"
              placeholder="CPF/CNPJ"
              {...register('cpF_CNPJ')}
              value={cpfNovoCondutorValue}
              onChange={inputCpfNovoCondutorMask}
            />
            <label htmlFor="cpfNovoCondutor">CPF/CNPJ</label>
            {errors?.cpF_CNPJ?.type && cpfNovoCondutorValue.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="cnhNovoCondutor"
              id="cnhNovoCondutor"
              placeholder="CNH"
              maxLength={9}
              {...register('cnh')}
            />
            <label htmlFor="cnhNovoCondutor">CNH</label>
            {errors?.cnh?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <select
              name="categoriaCnh"
              className="col-12"
              defaultValue="Categoria/CNH"
              {...register('categoriaCnhNovoCondutor')}
            >
              <option disabled>Categoria/CNH</option>
              <option value={1}> A </option>
              <option value={2}> B </option>
              <option value={3}> C </option>
              <option value={4}> D </option>
              <option value={5}> E </option>
            </select>
            {errors?.categoriaCnhNovoCondutor?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="cepNovoCondutor"
              id="cepNovoCondutor"
              placeholder="CEP"
              {...register('cep')}
              value={cepNovoCondutorValue}
              onChange={inputCepNovoCondutorMask}
            />
            <label htmlFor="cepNovoCondutor">CEP</label>
            {errors?.cep?.type && cepNovoCondutorValue.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-8">
            <input
              type="text"
              className="form-control"
              name="enderecoNovoCondutor"
              id="enderecoNovoCondutor"
              placeholder="Endereço"
              {...register('endereco')}
            />
            <label htmlFor="enderecoNovoCondutor">Endereço</label>
            {errors?.endereco?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="numeroEnderecoNovoCondutor"
              id="numeroEnderecoNovoCondutor"
              placeholder="N°"
              {...register('numeroEndereco')}
            />
            <label htmlFor="numeroEnderecoNovoCondutor">N°</label>
          </div>

          <div className="labelForm col-5">
            <input
              type="text"
              className="municipios form-control"
              name="municipioNovoCondutor"
              id="municipioNovoCondutor"
              placeholder="Município"
              {...register('municipioNovoCondutor')}
              value={textMunicipio}
              onChange={onChangeMunicipio}
            />
            <label htmlFor="municipioNovoCondutor"> Município </label>

            {filterMunicipio.length > 0 && (
              <div className="optionsMunicipios col-12">
                {filterMunicipio.map((dado) => (
                  <div className="listaMunicipios" key={dado.id} onClick={() => selecionaMunicipio(dado)}>
                    {dado.nome}
                  </div>
                ))}
              </div>
            )}

            {errors?.municipioNovoCondutor?.type && textMunicipio.length === 0 && <ErrorForm />}
          </div>

          <div className="labelForm col-2">
            <input
              type="text"
              className="form-control"
              name="estadoEnderecoNovoCondutor"
              id="estadoEnderecoNovoCondutor"
              placeholder="Estado"
              {...register('estado')}
              value={uf}
              readOnly
              disabled
            />
            <label htmlFor="estadoEnderecoNovoCondutor">Estado</label>
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="bairroNovoCondutor"
              id="bairroNovoCondutor"
              placeholder="Bairro"
              {...register('bairro')}
            />
            <label htmlFor="bairroNovoCondutor">Bairro</label>
            {errors?.bairro?.type && <ErrorForm />}
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="complementoEnderecoNovoCondutor"
              id="complementoEnderecoNovoCondutor"
              placeholder="Complemento"
              {...register('complemento')}
            />
            <label htmlFor="complemento">Complemento</label>
          </div>

          <div className="labelForm col-4">
            <input
              type="text"
              className="form-control"
              name="emailNovoCondutor"
              id="emailNovoCondutor"
              placeholder="E-Mail"
              {...register('email')}
            />
            <label htmlFor="emailNovoCondutor">E-Mail</label>
          </div>

          <div className="labelForm col-3">
            <input
              type="text"
              className="form-control"
              name="celularNovoCondutor"
              id="celularNovoCondutor"
              placeholder="Celular"
              {...register('celular')}
              value={telefoneNovoCondutorValue}
              onChange={inputTelefoneNovoCondutorMask}
            />
            <label htmlFor="celularNovoCondutor">Celular</label>
          </div>

          <div className="buttonCriaUsuario col-12">
            <ButtonSubmit type="submit" onClick={handleSubmit(formCriaCondutor)} text="Cadastrar" />
          </div>

          <ModalMessage
            title={messageModalCadastroCondutor}
            className="modalSuccess"
            show={successCadastroCondutor}
            onHide={() => [
              setSuccessCadastroCondutor(false),
              reset(),
              setCpfNovoCondutorValue(''),
              setCepNovoCondutorValue(''),
              setTelefoneNovoCondutorValue('')
            ]}
            textbutton="Voltar ao registro de condutor"
            textbutton2="Ir para o ínicio"
          />

          <ModalMessage
            title={messageModalCadastroCondutor}
            className="modalFalha"
            show={falhaCadastroCondutor}
            onHide={() => setFalhaCadastroCondutor(false)}
            textbutton="Tentar novamente"
            textbutton2="Ir para o ínicio"
          />
        </div>
      </form>
    </div>
  )
}

interface ListagemCondutoresProps {
  readonly filtro: any[]
  readonly currentPage: number
  readonly setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  readonly linhasPerPage: number
  readonly condutoresAtuais: any[]
}

function ListagemCondutores({
  filtro,
  currentPage,
  setCurrentPage,
  linhasPerPage,
  condutoresAtuais
}: ListagemCondutoresProps) {
  const {
    setShowIcon,
    showModalEdit,
    setShowModalEdit,
    showModalInvalid,
    setShowModalInvalid,
    showModalReative,
    setShowModalReative,
    mostraEdicao,
    mostraInativacao,
    mostraReativacao
  } = useContext(ListsContext)

  const { loadCondutores } = useContext(DadosContext)

  return (
    <>
      <div className="tableDefault container" id="tableCondutores">
        <Table striped bordered>
          <thead>
            <tr>
              <th className="headerTabela">Modificar</th>

              <th className="headerTabela">Código</th>

              <th className="headerTabela">Nome</th>

              <th className="headerTabela">CPF/CNPJ</th>

              <th className="headerTabela">CNH</th>

              <th className="headerTabela">Endereço</th>

              <th className="headerTabela">E-mail</th>

              <th className="headerTabela">Celular</th>
            </tr>
          </thead>
          {condutoresAtuais.map((dado, valor) => (
            <tbody key={dado.id}>
              <tr className="linhaDefault">
                <td className="dadoTabela">
                  <IconsModify
                    isInativo={dado.dataInativo !== null}
                    onClickInative={mostraInativacao(valor)}
                    onClickReative={mostraReativacao(valor)}
                  />
                </td>
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.codigo}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.nome}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.cpF_CNPJ}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.cnh}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.endereco}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.email}
                />
                <ColunaTabela
                  isInativo={dado.dataInativo !== null}
                  onClickColuna={dado.dataInativo === null ? mostraEdicao(valor) : null}
                  classNameColuna={dado.dataInativo === null ? 'textTableDefault' : 'textTableInativo'}
                  dadoColuna={dado.celular}
                />
              </tr>
              <tr>
                {showModalEdit[valor] && (
                  <td className="modalEdit">
                    <ModalEdicaoCondutor
                      show={!!showModalEdit}
                      onHide={() => [setShowModalEdit({}), setShowIcon({})]}
                      onClick={() => setShowModalEdit({})}
                      textbutton="Salvar"
                      textbutton2="Cancelar"
                      codigoCondutor={dado.codigo}
                      nome={dado.nome}
                      cpf={dado.cpF_CNPJ}
                      id={dado.id}
                      municipio={dado.municipio.nome || ''}
                      municipioId={dado.municipioId || 0}
                      cnh={dado.cnh || ''}
                      categoriaCNH={dado.categoriaCNH || ''}
                      endereco={dado.endereco || ''}
                      numeroEndereco={dado.numeroEndereco || ''}
                      complemento={dado.complemento || ''}
                      cep={dado.cep || ''}
                      bairro={dado.bairro || ''}
                      estado={dado.municipio.uf || ''}
                      email={dado.email || ''}
                      celular={dado.celular || ''}
                    />
                  </td>
                )}

                {showModalInvalid[valor] && (
                  <td className="modalInvalid">
                    <ModalInativacaoCondutor
                      show={!!showModalInvalid}
                      onHide={() => [setShowModalInvalid({}), setShowIcon({})]}
                      onClick={() => setShowModalInvalid({})}
                      textbutton="Inativar"
                      textbutton2="Cancelar"
                      nome={dado.nome}
                      id={dado.id}
                    />
                  </td>
                )}

                {showModalReative[valor] && (
                  <td className="modalInvalid">
                    <ModalReativacaoCondutor
                      show={!!showModalReative}
                      onHide={() => [setShowModalReative({}), setShowIcon({})]}
                      onClick={() => setShowModalReative({})}
                      textbutton="Reativar"
                      textbutton2="Cancelar"
                      nome={dado.nome}
                      id={dado.id}
                    />
                  </td>
                )}
              </tr>
            </tbody>
          ))}
        </Table>
      </div>

      {loadCondutores ? (
        <LoadComponent />
      ) : (
        <Pagination
          currentPage={currentPage}
          registersPerPage={linhasPerPage}
          textNoContent="Nenhum condutor encontrado"
          onPageChange={setCurrentPage}
          totalCountOfRegisters={filtro.length}
        />
      )}
    </>
  )
}

interface colunaTabelaProps {
  onClickColuna: React.MouseEventHandler<any>
  classNameColuna: string
  dadoColuna: string
  isInativo: boolean
}

function ColunaTabela({ onClickColuna, classNameColuna, dadoColuna, isInativo }: colunaTabelaProps) {
  return (
    <td className={isInativo ? '' : 'dadoTabela colunaClicavel'} onClick={onClickColuna}>
      <Tooltip title={isInativo ? 'Condutor inativo. Reative para editar' : 'Clique para editar'} followCursor>
        <div>
          <span className={classNameColuna}>{dadoColuna}</span>
        </div>
      </Tooltip>
    </td>
  )
}
